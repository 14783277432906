import api from "./api";
import { showError, showMessage, getOneDayDateRange } from "./index";
import { SAVE_EVENT_LOGS_FILTER_PARAMS, SAVE_EVENT_LOGS_AGGREGATE } from "./types";
import Util from "./../utils";


const EVENT_LOG_API = "/event-log";
const EVENT_LOG_ATTEMPTS = `${EVENT_LOG_API}/attempts`;
const EVENT_LOG_AGGREGATE = `${EVENT_LOG_API}/aggregate`;
const TRANSACTION_NOTIFICATION_API = "/transaction-notification";
const RESEND_NOTIFICATION = `${TRANSACTION_NOTIFICATION_API}/resend-notification`;
const RESEND_NOTIFICATIONS = `${TRANSACTION_NOTIFICATION_API}/resend-event-notifications`;

export const saveEventLogsFilterParams = (payload) => ({
    type: SAVE_EVENT_LOGS_FILTER_PARAMS,
    payload
})

export const saveEventLogsAggregrate = (payload) => ({
  type: SAVE_EVENT_LOGS_AGGREGATE,
  payload
})

const extractEventLogsAggregrate = (data) => {
  const failedCount = (
    data.find((item) => item.notificationStatus === "FAILED") || {
      count: 0,
    }
  ).count;
  const sentCount = (
    data.find((item) => item.notificationStatus === "SENT") || { count: 0 }
  ).count;
  const inProgressCount = (
    data.find((item) => item.notificationStatus === "IN_PROGRESS") || {
      count: 0,
    }
  ).count;
  return {failedCount, sentCount, inProgressCount}
}

export const getEventLogs = (pageRequest = {}, onFinish = () => { }) => (dispatch, getState) => {
  const isLiveMode = getState().environmentAware.isLiveMode;
  const page = pageRequest.page ? pageRequest.page - 1 : 0;
  const pageSize = pageRequest.results ? pageRequest.results : 10;

  let requestURL = `${EVENT_LOG_API}?page=${page}&size=${pageSize}`;
  if(pageRequest.searchParams){
    const {searchParams} = pageRequest
    if (searchParams.eventReference) {
      requestURL += `&eventReference=${encodeURIComponent(searchParams.eventReference)}`
    }
  
    if (searchParams.notificationStatus) {
      requestURL += `&notificationStatus=${searchParams.notificationStatus}`
    }
  
    if (searchParams.eventCategory) {
      requestURL += `&eventCategory=${searchParams.eventCategory}`
    }
    if (searchParams.from) {
      requestURL += "&from=" + searchParams.from;
    }

    if (searchParams.to) {
      requestURL += "&to=" + searchParams.to;
    }
  }else{
    let dateRange = getOneDayDateRange()
    requestURL += `&from=${dateRange.from}&to=${dateRange.to}`
  }

  let successHandler = response => {
    const pageData = Util.convertPageResponseToTableData(response);

    onFinish(pageData)
  };

  let errorHandler = error => {
    showError(error);
    onFinish()

  };

  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};


export const getEventLogAttempts = (reference, pageRequest = {}, onFinish = () => { }) => (dispatch, getState) => {
  const isLiveMode = getState().environmentAware.isLiveMode;
  const page = pageRequest.page ? pageRequest.page - 1 : 0;
  const pageSize = pageRequest.results ? pageRequest.results : 10;

  let requestURL = `${EVENT_LOG_ATTEMPTS}/${reference}?page=${page}&size=${pageSize}`;


  let successHandler = response => {
    const pageData = Util.convertPageResponseToTableData(response);

    onFinish(pageData)

  };

  let errorHandler = error => {
    showError(error);
    onFinish()
  };

  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};


export const resendNotification = (reference, onFinish = () => { }) => (dispatch, getState) => {

  let requestURL = `${RESEND_NOTIFICATION}/${reference}`;


  let successHandler = () => {
    showMessage("Successful!", "Notification resent");
    onFinish()
    dispatch(getEventLogAggregate())
  };

  let errorHandler = error => {
    showError(error);
    onFinish()

  };

  dispatch(api.post(requestURL, {}, successHandler, errorHandler));
};


export const resendNotifications = (data, onFinish = () => { }) => (dispatch, getState) => {

  let successHandler = () => {
    showMessage("Successful!", "Requests processed successfully!");
    onFinish()
  };

  let errorHandler = error => {
    showError(error);
    onFinish()

  };

  dispatch(api.post(RESEND_NOTIFICATIONS, {
    startDate: data[0].toJSON(),
    endDate: data[1].toJSON(),
  }, successHandler, errorHandler));
};


export const getEventLogAggregate = (onFinish = () => { }) => (dispatch) => {

  let successHandler = (response) => {
    const data = response.data.responseBody
    onFinish(data)
    dispatch(saveEventLogsAggregrate(extractEventLogsAggregrate(data)))

  };

  let errorHandler = error => {
    showError(error);
    onFinish([])
  };

  dispatch(api.get(EVENT_LOG_AGGREGATE, {}, successHandler, errorHandler));
};

export const saveFilterParams = (filterParams) => (dispatch) => {
  dispatch(saveEventLogsFilterParams(filterParams));
};