import { push } from "react-router-redux";
import api from "./api";
import { showError, showMessage } from "./index";
import {
  SAVE_BUSINESS_TYPE_DATA,
  SAVE_BUSINESS_TYPE_LOADING,
  SELF_SIGN_UP_SUCCESS,
  SELF_SIGN_UP_LOADING,
  KYC_VERIFY_PROFILE_LOADING,
  KYC_VERIFY_BANK_LOADING,
  VALIDATE_KYC_STATUS,
  GET_BUSINESS_CATEGORIES,
  KYC_VERIFY_PROFILE_SUCCESS,
  KYC_VERIFY_BANK_SUCCESS,
  GET_COUNTRY_STATES,
  GET_STATE_LGAS,
  GET_COUNTRIES,
  KYC_VERIFY_BUSINESS_LOADING,
  KYC_VERIFY_BUSINESS_SUCCESS,
  SET_KYC_ON_LOGIN,
  KYC_ACTIVATION_STATUS_LOADING,
  KYC_ACTIVATION_STATUS_SUCCESS,
  GET_ID_TYPES_SUCCESS,
  GET_ID_TYPES_LOADING,
  SWITCH_BUSINESS_TYPE_LOADING,
  SWITCH_BUSINESS_TYPE_SUCCESS,
  GET_COMPANY_REG_TYPES_SUCCESS,
  GET_COMPANY_REG_TYPES_LOADING,
  UPDATE_KYC_VERIFICATION_SUCCESS,
  UPDATE_BANK_VERIFICATION_SUCCESS,
  UPDATE_BANK_VERIFICATION_LOADING,
  UPDATE_BUSINESS_VERIFICATION_LOADING,
  UPDATE_BUSINESS_VERIFICATION_SUCCESS,
  VALIDATE_EMAIL_LOADING,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAILURE,
  UPDATE_VERIFY_PROFILE_FLAG,
  UPDATE_VERIFY_BANK_FLAG,
  UPDATE_VERIFY_BUSINESS_FLAG,
  KYC_VERIFY_COMPANY_LOADING,
  UPDATE_COMPANY_REGISTRATION_SUCCESS,
  GET_LGA,
  UPDATE_COMPANY_REGISTRATION_LOADING,
  COMPLETE_USER_INVITE_LOADING,
  ACTIVATE_USER_INVITATION_LOADING,
  ACTIVATE_USER_INVITATION_STATUS,
  SAVE_USER_AUTHORITIES,
  ACTIVATE_USER_INVITATION,
  GET_APPLICABLE_KYC,
  UPDATE_APPLICABLE_KYC_LOADING,
  SAVE_STATIC_KYC_INFORMATION,
  LOGIN_STATUS,
  SAVE_KYC_STATUS,
} from "./types";
import DataStore from "../dataStore";
import { DATA_STORE_KEYS } from "../dataStore/keys";
import Util, { DEFAULT_PRE_VERIFIED_HOME_ROUTE } from "../utils";
import { moveToDashboard } from "./dashboard";

const ONBOARDING_API = "/onboarding";
const MERCHANT_KYC_API = "/merchant-kyc";
const ACTIVATION_API = "/user-activation";

const PENDING_KYC_VERIFICATION = "PENDING_KYC_VERIFICATION";
const COMPLETED_KYC_VERIFICATION = "COMPLETED";

export const moveToOnboard = () => (dispatch) => {
  dispatch(push("/onboard"));
};

const saveSignUpInformation = ({
  accessToken,
  user,
  merchantKycStatus,
  merchantList,
  authorities,
  selectedMerchant,
}) => {
  DataStore.save(DATA_STORE_KEYS.ACCESS_TOKEN, accessToken);
  DataStore.save(DATA_STORE_KEYS.USER_DATA, user);
  DataStore.save(DATA_STORE_KEYS.KYC_STATUS, merchantKycStatus);
  DataStore.save(DATA_STORE_KEYS.AUTHORITIES, authorities);
  if (selectedMerchant)
    DataStore.save(DATA_STORE_KEYS.ACTIVE_BUSINESS, {
      businessName: selectedMerchant.merchantBusinessName,
      merchantCode: selectedMerchant.merchantCode,
      merchantAllowedFeatures: selectedMerchant.merchantAllowedFeatures,
      merchantEmail: selectedMerchant.merchantEmail,
    });
  else if (merchantList.length === 1)
    DataStore.save(DATA_STORE_KEYS.ACTIVE_BUSINESS, merchantList[0]);
  DataStore.save(DATA_STORE_KEYS.MERCHANT_BUSINESSES, merchantList);
};

const saveMerchantBusinessInformation = ({ businessType, kycStatus }) => {
  DataStore.save(DATA_STORE_KEYS.BUSINESS_TYPE, businessType);
  DataStore.save(DATA_STORE_KEYS.KYC_STATUS, kycStatus);
};

const saveBusinessTypeLoading = (isLoading) => {
  return {
    type: SAVE_BUSINESS_TYPE_LOADING,
    payload: isLoading,
  };
};

export const selfSignUp = (data) => (dispatch) => {
  let requestURL = `${ONBOARDING_API}/signup`;
  let successHandler = (response) => {
    showMessage("Successful!", "Account successfully created!");
    saveSignUpInformation(response.data.responseBody);
    dispatch({
      type: SELF_SIGN_UP_SUCCESS,
      payload: response.data.responseBody,
    });
    dispatch({ type: SELF_SIGN_UP_LOADING, payload: false });
    dispatch(push("/activate-business"));
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: SELF_SIGN_UP_LOADING, payload: false });
  };

  dispatch({ type: SELF_SIGN_UP_LOADING, payload: true });
  dispatch(
    api.post(requestURL, data, successHandler, errorHandler, true, {
      version: "v2",
    })
  );
};

export const saveBusinessTypeAndCategoryData = (data) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}/update-business-type-and-category`;

  let successHandler = (response) => {
    showMessage("Successful!", "Merchant KYC successfully updated!");
    saveMerchantBusinessInformation(response.data.responseBody);
    dispatch(push(DEFAULT_PRE_VERIFIED_HOME_ROUTE));

    dispatch({
      type: SAVE_KYC_STATUS,
      payload: response.data.responseBody.kycStatus,
    });
    dispatch(saveBusinessTypeLoading(false));
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch(saveBusinessTypeLoading(false));
  };

  dispatch(saveBusinessTypeLoading(true));
  dispatch(api.post(requestURL, data, successHandler, errorHandler));
};

export const validateKycStatus = () => (dispatch) => {
  let kycStatus = DataStore.get(DATA_STORE_KEYS.KYC_STATUS);
  let businessType = DataStore.get(DATA_STORE_KEYS.BUSINESS_TYPE);

  dispatch({ type: VALIDATE_KYC_STATUS, payload: { kycStatus, businessType } });
  if (kycStatus === PENDING_KYC_VERIFICATION) {
    dispatch(
      push("/activate-business", { kycStatus: kycStatus, businessType }),
    );
  } else if (kycStatus === COMPLETED_KYC_VERIFICATION) {
    dispatch(moveToDashboard());
  }
};

export const verifyProfile = (data) => (dispatch) => {
  let { idCardNumber, idType, file, image } = data;

  let imageFile = image;
  let formData = new FormData();

  let requestURL = `${MERCHANT_KYC_API}/update-identity-info?fileType=${file.extension}&idType=${idType}&idCardNumber=${idCardNumber}`;

  formData.append("file", imageFile);

  let successHandler = (response) => {
    showMessage("Successful!", "Profile info successfully saved!");
    dispatch({ type: KYC_VERIFY_PROFILE_LOADING, payload: false });
    dispatch({
      type: KYC_VERIFY_PROFILE_SUCCESS,
      payload: response.data.responseMessage,
    });
    dispatch(getKycActivationStatus());
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: KYC_VERIFY_PROFILE_LOADING, payload: false });
  };

  dispatch({ type: KYC_VERIFY_PROFILE_LOADING, payload: true });
  dispatch(api.post(requestURL, formData, successHandler, errorHandler));
};

export const verifyCompanyRegistration = (data) => (dispatch) => {
  let { companyRegistrationNumber, companyRegistrationType, file, image } =
    data;

  let requestURL = `${MERCHANT_KYC_API}/update-company-registration-details?fileType=${file.extension}&companyRegistrationType=${companyRegistrationType}&companyRegistrationNumber=${companyRegistrationNumber}`;

  let imageFile = image;
  let formData = new FormData();

  formData.append("file", imageFile);

  let successHandler = (response) => {
    showMessage("Successful!", "Company info successfully saved!");
    dispatch({ type: KYC_VERIFY_COMPANY_LOADING, payload: false });
    dispatch({
      type: KYC_VERIFY_PROFILE_SUCCESS,
      payload: response.data.responseMessage,
    });
    dispatch(getKycActivationStatus());
    dispatch(updateVerificationFlag("ID"));
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: KYC_VERIFY_COMPANY_LOADING, payload: false });
  };

  dispatch({ type: KYC_VERIFY_COMPANY_LOADING, payload: true });
  dispatch(api.post(requestURL, formData, successHandler, errorHandler));
};

export const verifyBankInfo = (data) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}/update-settlement-account`;

  let successHandler = (response) => {
    showMessage("Successful!", "Bank info successfully saved!");
    dispatch({
      type: KYC_VERIFY_BANK_SUCCESS,
      payload: response.data.responseMessage,
    });
    dispatch({ type: KYC_VERIFY_BANK_LOADING, payload: false });
    dispatch(getKycActivationStatus());
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: KYC_VERIFY_BANK_LOADING, payload: false });
  };

  dispatch({ type: KYC_VERIFY_BANK_LOADING, payload: true });
  dispatch(api.put(requestURL, data, successHandler, errorHandler));
};

export const updateSettlementAccountV2 =
  (data, onFinish = () => {}) =>
  (dispatch) => {
    let requestURL = `${MERCHANT_KYC_API}/update-settlement-account`;

    let successHandler = (response) => {
      showMessage("Successful!", "Bank information successfully saved!");
      onFinish(true);
      dispatch({ type: KYC_VERIFY_BANK_LOADING, payload: false });
    };

    let errorHandler = (error) => {
      showError(error);
      onFinish();
      dispatch({ type: KYC_VERIFY_BANK_LOADING, payload: false });
    };

    dispatch({ type: KYC_VERIFY_BANK_LOADING, payload: true });
    dispatch(
      api.put(requestURL, data, successHandler, errorHandler, false, {
        version: "v2",
      })
    );
  };

export const verifyBusiness = (requestData) => (dispatch) => {
  let {
    businessCategory,
    businessDescription,
    supportEmail,
    chargeBackEmail,
    address,
    website,
    facebookUsername,
    twitterUsername,
    linkedinUsername,
    instagramUsername,
    file,
    logo,
    lgaCode,
  } = requestData;

  let formData = new FormData();

  let requestURL = `${MERCHANT_KYC_API}/update-business-details`;

  formData.append("businessCategoryName", businessCategory);
  formData.append("businessDescription", businessDescription);
  formData.append("supportEmail", supportEmail);
  formData.append("chargeBackEmail", chargeBackEmail);
  formData.append("address", address);
  formData.append("lgaCode", lgaCode);

  if (logo) {
    formData.append("logoFileType", file.extension);
    formData.append("logo", logo);
  }
  if (!!website) {
    formData.append("website", website);
  }
  if (!!facebookUsername) {
    formData.append("facebookUsername", facebookUsername);
  }
  if (!!twitterUsername) {
    formData.append("twitterUsername", twitterUsername);
  }
  if (!!instagramUsername) {
    formData.append("instagramUsername", instagramUsername);
  }
  if (!!linkedinUsername) {
    formData.append("linkedinUsername", linkedinUsername);
  }

  let successHandler = (response) => {
    showMessage("Successful!", "Business info successfully saved!");
    dispatch({
      type: KYC_VERIFY_BUSINESS_SUCCESS,
      payload: response.data.responseMessage,
    });
    dispatch({ type: KYC_VERIFY_BUSINESS_LOADING, payload: false });
    dispatch(updateVerificationFlag("BUSINESS"));
    dispatch(getKycActivationStatus());
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: KYC_VERIFY_BUSINESS_LOADING, payload: false });
  };

  dispatch({ type: KYC_VERIFY_BUSINESS_LOADING, payload: true });
  dispatch(api.post(requestURL, formData, successHandler, errorHandler));
};

export const updateBusinessVerification = (data) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}`;

  let successHandler = (response) => {
    dispatch({
      type: UPDATE_BUSINESS_VERIFICATION_SUCCESS,
      payload: response.data.responseBody.kycDetails,
    });
    dispatch({ type: UPDATE_BUSINESS_VERIFICATION_LOADING, payload: false });
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: UPDATE_BUSINESS_VERIFICATION_LOADING, payload: false });
  };

  dispatch({ type: UPDATE_BUSINESS_VERIFICATION_LOADING, payload: true });
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const updateBusinessInformation =
  (requestData, onFinish = () => {}) =>
  (dispatch) => {
    let {
      businessCategory,
      businessDescription,
      supportEmail,
      chargeBackEmail,
      address,
      website,
      facebookUsername,
      twitterUsername,
      linkedinUsername,
      instagramUsername,
      file,
      logo,
      lgaCode,
    } = requestData;

    let formData = new FormData();

    let requestURL = `${MERCHANT_KYC_API}/update-business-details`;

    formData.append("businessCategoryName", businessCategory);
    formData.append("businessDescription", businessDescription);
    formData.append("supportEmail", supportEmail);
    formData.append("chargeBackEmail", chargeBackEmail);
    formData.append("address", address);
    formData.append("lgaCode", lgaCode);

    if (logo) {
      formData.append("logoFileType", file.extension);
      formData.append("logo", logo);
    }
    if (!!website) {
      formData.append("website", website);
    }
    if (!!facebookUsername) {
      formData.append("facebookUsername", facebookUsername);
    }
    if (!!twitterUsername) {
      formData.append("twitterUsername", twitterUsername);
    }
    if (!!instagramUsername) {
      formData.append("instagramUsername", instagramUsername);
    }
    if (!!linkedinUsername) {
      formData.append("linkedinUsername", linkedinUsername);
    }

    let successHandler = (response) => {
      showMessage("Successful!", "Business info successfully saved!");
      onFinish(true);
      dispatch({
        type: KYC_VERIFY_BUSINESS_SUCCESS,
        payload: response.data.responseMessage,
      });
      dispatch({ type: KYC_VERIFY_BUSINESS_LOADING, payload: false });
      dispatch(updateVerificationFlag("BUSINESS"));
      dispatch(getKycActivationStatus());
    };

    let errorHandler = (error) => {
      onFinish(false);
      showError(error);
      dispatch({ type: KYC_VERIFY_BUSINESS_LOADING, payload: false });
    };

    dispatch({ type: KYC_VERIFY_BUSINESS_LOADING, payload: true });
    dispatch(
      api.post(requestURL, formData, successHandler, errorHandler, false, {
        version: "v2",
      })
    );
  };

export const getBusinessCategories = (requestData) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}/get-business-categories`;
  let successHandler = (response) => {
    dispatch({
      type: GET_BUSINESS_CATEGORIES,
      payload: response.data.responseBody,
    });
  };

  let errorHandler = (error) => {
    showError(error);
  };

  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const getCountry =
  (onFinish = () => {}) =>
  (dispatch) => {
    let requestURL = `${MERCHANT_KYC_API}/get-countries`;
    let successHandler = (response) => {
      onFinish(response.data.responseBody);
      dispatch({
        type: GET_COUNTRIES,
        payload: response.data.responseBody,
      });
    };

    let errorHandler = (error) => {
      showError(error);
    };

    dispatch(api.get(requestURL, {}, successHandler, errorHandler));
  };

export const getCountryStates =
  (countryCode, onFinish = () => {}) =>
  (dispatch) => {
    let requestURL = `${MERCHANT_KYC_API}/get-states?countryCode=${countryCode}`;
    let successHandler = (response) => {
      dispatch({
        type: GET_COUNTRY_STATES,
        payload: { states: response.data.responseBody, countryCode },
      });
      onFinish({ [countryCode]: response.data.responseBody });
    };

    let errorHandler = (error) => {
      showError(error);
    };

    dispatch(api.get(requestURL, {}, successHandler, errorHandler));
  };

export const getStateLgas =
  (stateCode, onFinish = () => {}) =>
  (dispatch) => {
    let requestURL = `${MERCHANT_KYC_API}/get-lgas?stateCode=${stateCode}`;
    let successHandler = (response) => {
      dispatch({
        type: GET_STATE_LGAS,
        payload: { lgas: response.data.responseBody, stateCode },
      });
      onFinish({ [stateCode]: response.data.responseBody });
    };

    let errorHandler = (error) => {
      showError(error);
    };
    dispatch(api.get(requestURL, {}, successHandler, errorHandler));
  };

export const getLga = (data) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}/get-lgas?stateCode=${data}`;
  let successHandler = (response) => {
    dispatch({
      type: GET_LGA,
      payload: response.data.responseBody,
    });
  };

  let errorHandler = (error) => {
    showError(error);
  };
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const setKycOnLogin = (data) => (dispatch) => {
  dispatch({ type: SET_KYC_ON_LOGIN, payload: data });
};

export const getKycActivationStatus = (data) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}`;

  let successHandler = (response) => {
    dispatch({
      type: KYC_ACTIVATION_STATUS_SUCCESS,
      payload: response.data.responseBody,
    });
    dispatch({ type: KYC_ACTIVATION_STATUS_LOADING, payload: false });
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: KYC_ACTIVATION_STATUS_LOADING, payload: false });
  };

  dispatch({ type: KYC_ACTIVATION_STATUS_LOADING, payload: true });
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const getKycActivationStatusV2 = (data) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}`;

  let successHandler = (response) => {
    const data = response.data.responseBody;
    if (data.kycStatus === "COMPLETED" || data.kycStatus === "LEGACY") {
      DataStore.save(DATA_STORE_KEYS.KYC_STATUS, data.kycStatus);
      dispatch(push("/dashboard"));
    }

    dispatch({
      type: SAVE_STATIC_KYC_INFORMATION,
      payload: data,
    });

    dispatch({ type: KYC_ACTIVATION_STATUS_LOADING, payload: false });
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: KYC_ACTIVATION_STATUS_LOADING, payload: false });
  };

  dispatch({ type: KYC_ACTIVATION_STATUS_LOADING, payload: true });
  dispatch(
    api.get(requestURL, {}, successHandler, errorHandler, false, {
      version: "v2",
    })
  );
};

export const getIdTypes = () => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}/get-id-types`;

  let successHandler = (response) => {
    dispatch({
      type: GET_ID_TYPES_SUCCESS,
      payload: response.data.responseBody,
    });
    dispatch({ type: GET_ID_TYPES_LOADING, payload: false });
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: GET_ID_TYPES_LOADING, payload: false });
  };

  dispatch({ type: GET_ID_TYPES_LOADING, payload: true });
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const getCompanyRegTypes = () => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}/get-company-reg-types`;

  let successHandler = (response) => {
    dispatch({
      type: GET_COMPANY_REG_TYPES_SUCCESS,
      payload: response.data.responseBody,
    });
    dispatch({ type: GET_COMPANY_REG_TYPES_LOADING, payload: false });
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: GET_COMPANY_REG_TYPES_LOADING, payload: false });
  };

  dispatch({ type: GET_COMPANY_REG_TYPES_LOADING, payload: true });
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const switchBusinessType = (businessType) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}/change-business-type?newBusinessType=${businessType}`;

  let successHandler = (response) => {
    dispatch({
      type: SWITCH_BUSINESS_TYPE_SUCCESS,
      payload: businessType,
    });
    dispatch({ type: SWITCH_BUSINESS_TYPE_LOADING, payload: false });
    dispatch(getKycActivationStatus());
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: SWITCH_BUSINESS_TYPE_LOADING, payload: false });
  };

  dispatch({ type: SWITCH_BUSINESS_TYPE_LOADING, payload: true });
  dispatch(api.put(requestURL, {}, successHandler, errorHandler));
};

export const resendVerificationEmail = (onFinish) => (dispatch) => {
  let requestURL = `${ONBOARDING_API}/resend-verification-email`;

  let successHandler = (response) => {
    showMessage("Successful.", "Verification mail resent.");
    onFinish && onFinish(true);
  };

  let errorHandler = (error) => {
    showError(error);
    onFinish && onFinish(false);
  };

  dispatch(api.post(requestURL, {}, successHandler, errorHandler));
};

export const checkEmailIsVerified = (onFinish) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}/is-email-verified`;

  let successHandler = (response) => {
    onFinish && onFinish(response.data.responseBody);
  };

  let errorHandler = (error) => {
    showError(error);
    onFinish && onFinish(false);
  };

  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const updateKycVerification = (data) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}`;

  let successHandler = (response) => {
    dispatch({
      type: UPDATE_KYC_VERIFICATION_SUCCESS,
      payload: response.data.responseBody.kycDetails,
    });
    dispatch({ type: GET_ID_TYPES_LOADING, payload: false });
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: GET_ID_TYPES_LOADING, payload: false });
  };

  dispatch({ type: GET_ID_TYPES_LOADING, payload: true });
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const updateCompanyRegistration = (data) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}`;

  let successHandler = (response) => {
    dispatch({
      type: UPDATE_COMPANY_REGISTRATION_SUCCESS,
      payload: response.data.responseBody.kycDetails,
    });
    dispatch({ type: UPDATE_COMPANY_REGISTRATION_LOADING, payload: false });
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: UPDATE_COMPANY_REGISTRATION_LOADING, payload: false });
  };

  dispatch({ type: UPDATE_COMPANY_REGISTRATION_LOADING, payload: true });
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const updateBankVerification = (data) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}`;

  let successHandler = (response) => {
    dispatch({
      type: UPDATE_BANK_VERIFICATION_SUCCESS,
      payload: response.data.responseBody.kycDetails,
    });
    dispatch({ type: UPDATE_BANK_VERIFICATION_LOADING, payload: false });
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: UPDATE_BANK_VERIFICATION_LOADING, payload: false });
  };

  dispatch({ type: UPDATE_BANK_VERIFICATION_LOADING, payload: true });
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const validateEmail = (reference) => (dispatch) => {
  let requestURL = `${ONBOARDING_API}/validate-email-reference/${reference}`;

  let successHandler = (response) => {
    dispatch({
      type: VALIDATE_EMAIL_SUCCESS,
      payload: "success",
    });
    showMessage("Successful!", "Email successfully verified!");
    dispatch({ type: VALIDATE_EMAIL_LOADING, payload: false });
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: VALIDATE_EMAIL_LOADING, payload: false });
    dispatch({ type: VALIDATE_EMAIL_FAILURE, payload: "failure" });
  };

  dispatch({ type: VALIDATE_EMAIL_LOADING, payload: true });
  dispatch(api.post(requestURL, {}, successHandler, errorHandler, true));
};

export const routeToLogin = () => (dispatch) => {
  dispatch(push("/login"));
};

export const updateVerificationFlag = (verificationType) => (dispatch) => {
  if (verificationType === "ID") {
    dispatch({ type: UPDATE_VERIFY_PROFILE_FLAG, payload: false });
  }
  if (verificationType === "BVN") {
    dispatch({ type: UPDATE_VERIFY_BANK_FLAG, payload: false });
  }
  if (verificationType === "BUSINESS") {
    dispatch({ type: UPDATE_VERIFY_BUSINESS_FLAG, payload: false });
  }
};

export const completeUserActivation = (data, reference) => (dispatch) => {
  let requestURL = `${ACTIVATION_API}/complete/${reference}`;

  let successHandler = (response) => {
    showMessage(
      "Congrats! Your account has been created successfully. Please login to proceed...."
    );
    dispatch({ type: COMPLETE_USER_INVITE_LOADING, payload: true });
    dispatch(push("/login"));
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: COMPLETE_USER_INVITE_LOADING, payload: true });
  };

  dispatch({ type: COMPLETE_USER_INVITE_LOADING, payload: true });
  dispatch(api.put(requestURL, data, successHandler, errorHandler, true));
};

export const initiateUserActivation = (reference) => (dispatch) => {
  let requestURL = `${ACTIVATION_API}/initiate/${reference}`;

  let successHandler = (response) => {
    dispatch({ type: ACTIVATE_USER_INVITATION_LOADING, payload: false });
    dispatch({
      type: ACTIVATE_USER_INVITATION,
      payload: response.data.responseBody,
    });
    dispatch({ type: ACTIVATE_USER_INVITATION_STATUS, payload: "success" });
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch({ type: ACTIVATE_USER_INVITATION_LOADING, payload: false });
    dispatch({ type: ACTIVATE_USER_INVITATION_STATUS, payload: "failure" });
  };

  dispatch({ type: ACTIVATE_USER_INVITATION_STATUS, payload: "" });
  dispatch({ type: ACTIVATE_USER_INVITATION_LOADING, payload: true });
  dispatch(api.post(requestURL, {}, successHandler, errorHandler, true));
};

export const getApplicableKyc = (onFinish) => (dispatch) => {
  let requestURL = `${MERCHANT_KYC_API}/get-applicable-kyc`;
  dispatch({ type: UPDATE_APPLICABLE_KYC_LOADING, payload: true });
  let successHandler = (response) => {
    dispatch({ type: UPDATE_APPLICABLE_KYC_LOADING, payload: false });
    dispatch({
      type: GET_APPLICABLE_KYC,
      payload: response.data.responseBody,
    });
    onFinish && onFinish(true);
  };

  let errorHandler = (error) => {
    dispatch({ type: UPDATE_APPLICABLE_KYC_LOADING, payload: false });
    showError(error);
    onFinish && onFinish(false);
  };

  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const getFormDataFromRequest = (request, kycInfoMap) => {
  let formData = new FormData();
  let iterator = 0;
  for (const key in request) {
    if (!request[key]) continue;

    switch (kycInfoMap[key].dataType) {
      case "DIRECTORS":
        request[key] = Object.values(request[key])
          .filter((el) => el != null)
          .map((req) => {
            return {
              ...req,
              bvnDob: req.bvnDob.format("YYYY-MM-DD"),
            };
          });
        break;
      case "MANAGEMENT_LIST":
        request[key] = Object.values(request[key]).filter((el) => el != null);
        break;
      case "BVN":
        if (!request[key].bvn || !request[key].bvnDob) {
          continue;
        }

        request[key] = {
          ...request[key],
          bvnDob: request[key].bvnDob.format("YYYY-MM-DD"),
        };
        break;
      default:
    }
    formData.append(`items[${iterator}].kycInformationCode`, key);

    if (typeof request[key] !== "string") {
      if ("file" in request[key]) {
        formData.append(`items[${iterator}].file`, request[key].file);
      } else {
        formData.append(
          `items[${iterator}].kycData`,
          JSON.stringify(request[key])
        );
      }
    } else {
      formData.append(`items[${iterator}].kycData`, request[key]);
    }

    iterator++;
  }

  return formData;
};

export const updateMerchantKycInformation =
  (request, kycInfoMap, onFinish) => (dispatch) => {
    let requestURL = `${MERCHANT_KYC_API}/update-merchant-kyc-information`;
    let formData = getFormDataFromRequest(request, kycInfoMap);

    let successHandler = (response) => {
      showMessage(
        "Update Successfully",
        "Your request was processed successfully."
      );
      onFinish && onFinish(true);
    };

    let errorHandler = (error) => {
      showError(error);
      onFinish && onFinish(false);
    };

    dispatch(api.post(requestURL, formData, successHandler, errorHandler));
  };

export const getUploadedKycFile =
  (kycInformationCode, onFinish) => (dispatch) => {
    dispatch(
      api.get(
        `${MERCHANT_KYC_API}/get-uploaded-kyc-file?kycInformationCode=${kycInformationCode}`,
        {},
        (response) => {
          const { file, url } = response.data.responseBody;
          const lastIndex = url.lastIndexOf(".");
          const extension = url.substr(lastIndex + 1).toLowerCase();

          const contentType = Util.extensionMapper[extension];
          const base64Url = `data:${contentType};base64,${file}`;

          onFinish && onFinish(base64Url);
        },
        (error) => {
          showError(error);
        }
      )
    );
  };

export const getSampleKycInfo =
  (kycInformationCode, onFinish) => (dispatch) => {
    dispatch(
      api.get(
        `${MERCHANT_KYC_API}/get-sample-kyc-file?kycInformationCode=${kycInformationCode}`,
        {},
        (response) => {
          const { file, url } = response.data.responseBody;
          const lastIndex = url.lastIndexOf(".");
          const extension = url.substr(lastIndex + 1).toLowerCase();

          const contentType = Util.extensionMapper[extension];
          const base64Url = `data:${contentType};base64,${file}`;

          onFinish && onFinish(base64Url);
        },
        (error) => {
          showError(error);
        }
      )
    );
  };

export const validateBVN =
  (request, onFinish = () => {}) =>
  (dispatch) => {
    dispatch(
      api.post(
        `${MERCHANT_KYC_API}/verify-bvn`,
        request,
        (response) => {
          onFinish(response.data.responseBody.name);
        },
        (error) => onFinish(null)
      )
    );
  };

export const saveUserAuthorities = (data) => (dispatch) => {
  dispatch({ type: SAVE_USER_AUTHORITIES, payload: data });
};
export const setLoginStatus = (status) => (dispatch) => {
  dispatch({ type: LOGIN_STATUS, payload: status });
};
