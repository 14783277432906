import _ from 'lodash';
import { ADD_FORM_MESSAGE, CLEAR_FORM_MESSAGE } from "./../actions/types";

const INITIAL_STATE = {
}

export default function (state = INITIAL_STATE, action) {
    let newState;

    switch (action.type) {
        case ADD_FORM_MESSAGE:
            newState = _.merge(state, { [action.payload.form]: {
                    message: action.payload.message,
                    type: action.payload.type
                }
            })
            return { ...state, newState }

        case CLEAR_FORM_MESSAGE:
            newState = _.merge(state, { [action.payload.form]: {}})

            return { ...state, newState };
            // return _.omit(state, [action.payload.form]);

        default:
            return state
    }
}