import React from "react";
import { connect } from "react-redux";
import { initLogin } from "../../../common/actions/auth";
import MonnifyIcon from "../../../common/icons";
import "./style.scss";

@connect(null, { initLogin })
class BasePage extends React.Component {
  static defaultProps = {
    loginRequired: false,
    containerExtraClass: "out-app-page",
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.initLogin();
  }

  render() {
    return (
      <main className="monnify-no-auth-page main-login main-login--fullscreen">
        <div className="container">
          <div className="content-wrap">
            {/* <div className="intro-section">
              <div className='dark_blue_bg'></div>
              <div className='text-body'>
                <div><img src={'/images/logo-white.svg'} alt="Logo" /></div>
                
                <h1 className="title">{this.props.mainHeading}</h1>
                <div className="description">
                  <p>{this.props.subHeading}</p>
                  <p>{this.props.subHeading2}</p>
                </div>
              </div>
            </div> */}
            <div className="main-content">
              <img
                className="logo-image"
                src={"images/logo-white.svg"}
                alt=""
              />
              <div className={`form-section ${this.props.className}`}>
                {this.props.children}
              </div>

              <div className={`footer-links`}>
              <div className="links">
                <a href ="https://monnify.com">
                  <MonnifyIcon type="MONNIFY_BLUE_ICON" /> Monnify Website
                </a>
                <a href ="https://support.monnify.com">
                  <MonnifyIcon type="QUESTION_MARK_BLUE" /> Support Portal
                </a>
                <a href ="https://developers.monnify.com">
                  <MonnifyIcon type="DEVELOPER_BLUE" /> Developer Documentation
                </a>
              </div>
            </div>
            </div>

            <div className={`footer-brand-logo`}>
              <span className={`by-text`}>by</span>
              <img src="images/moniepointLogo-white.svg" alt="Moniepoint-logo"/>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default BasePage;
