import React from "react";
import { Form, Input, Radio, Button, Select } from "antd";
import { Helmet } from "react-helmet-async";
import connect from "react-redux/es/connect/connect";
import { OnboardingStyle, RadioStyle } from "./style";
import {
  saveBusinessTypeAndCategoryData,
  validateKycStatus,
} from "../../../common/actions/onboarding";
import Page from "../../../common/components/LayoutComponents/Page";
import { getBusinessCategories } from "../../../common/actions/onboarding";
import dataStore from "../../../common/dataStore";
import { DATA_STORE_KEYS } from "../../../common/dataStore/keys";
import "./style.scss";
import EmailVerficationBanner from "../../../common/components/LayoutComponents/EmailBanner";

const FormItem = Form.Item;
const { Option } = Select;

const mapStateToProps = (state) => {
  let onboardingData = state.onboarding || {};
  let businessCategory = state.onboarding.businessCategory || [];

  return {
    onboardingData,
    businessCategory,
  };
};

@connect(mapStateToProps, {
  saveBusinessTypeAndCategoryData,
  validateKycStatus,
  getBusinessCategories,
})
@Form.create()
class SelectBusinessType extends React.Component {
  state = {
    businessName: "",
  };
  static defaultProps = {
    pathName: "Onboarding",
    breadcrumb: [
      {
        text: "Dashboard",
        link: "/dashboard",
      },
      {
        text: "Onboarding",
      },
    ],
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { form, processing } = this.props;

    if (processing) {
      return;
    }

    form.validateFields((error, values) => {
      if (error) {
        return;
      } else {
        this.props.saveBusinessTypeAndCategoryData({
          businessCategoryName: values.businessCategory,
          businessType: values.businessType,
        });
      }
    });
  };

  onChange = (e) => {
    this.setState({
      businessType: e.target.value,
    });
  };

  componentDidMount() {
    const business = dataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);
    this.setState({ businessName: business.businessName });

    this.props.validateKycStatus();
    setTimeout(this.props.getBusinessCategories, 1000);
    this.props.form.validateFields();
  }

  hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  render() {
    const { form, onboardingData, businessCategory } = this.props;

    const businessTypeError =
      form.isFieldTouched("businessType") && form.getFieldError("businessType");
    const businessCategoryError =
      form.isFieldTouched("businessCategory") &&
      form.getFieldError("businessCategory");

    const businessCategoryContainsValue =
      form.getFieldValue("businessCategory") != undefined;

    return (
      <div className="onboarding-outer-body">
        <div className="banner-design"></div>

        {/* <EmailActivationDialog /> */}

        <OnboardingStyle>
          <div className="onboarding-body">
            <div className="welcome-section">
              <h1 className="header large">👋 Hi, {this.state.businessName}</h1>
              <p>
                We are happy to have you on Monnify. Let’s start helping you
                receive payments from your customers easily.
              </p>
            </div>

            <Form
              className="onboard-form"
              hideRequiredMark
              onSubmit={this.onSubmit}
            >
              <p className="header secondary">
                What type of business do you operate?
              </p>
              <div>
                <FormItem
                  help={businessTypeError || ""}
                  className="radio-group-wrap"
                >
                  {form.getFieldDecorator("businessType", {
                    rules: [
                      {
                        required: true,
                        message: "Please select your business type",
                        type: "string",
                      },
                    ],
                  })(
                    <Radio.Group
                      className="radio-group"
                      onChange={this.onChange}
                    >
                      <div className="radio-wrap">
                        <RadioStyle value="REGISTERED">
                          <h4 className="header tertiary">
                            Registered Business
                          </h4>
                          <p className="body-text">
                            For businesses that are registered with a
                            valid&nbsp;
                            <br />
                            identification number
                          </p>
                        </RadioStyle>
                      </div>
                      <div className="radio-wrap">
                        <RadioStyle value="STARTER">
                          <h4 className="header tertiary">Starter Business</h4>
                          <p className="body-text">
                            For solopreneurs, freelancers and other <br />
                            unregistered businesses
                          </p>
                        </RadioStyle>
                      </div>
                    </Radio.Group>,
                  )}
                </FormItem>
              </div>

              <div>
                <p className="header secondary">Business category:</p>
                <FormItem
                  label={
                    businessCategoryContainsValue
                      ? "Business Category"
                      : undefined
                  }
                  help={businessCategoryError || ""}
                >
                  {form.getFieldDecorator("businessCategory", {
                    // initialValue:
                    //   verifyBusinessData.submitted &&
                    //   verifyBusinessData.submitted.businessCategory.name,
                    rules: [
                      {
                        required: true,
                        message: "Please select your business category",
                      },
                    ],
                  })(
                    <Select placeholder="ex: Financial Services">
                      {businessCategory.map((category, index) => (
                        <Option key={index} value={category.name}>
                          {category.description}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </div>

              <div className="btn-wrap">
                <Button
                  disabled={this.hasErrors(form.getFieldsError())}
                  loading={onboardingData.loading}
                  type="secondary"
                  htmlType="submit"
                >
                  Save and proceed
                </Button>
              </div>
            </Form>
          </div>
        </OnboardingStyle>
      </div>
    );
  }
}

export default SelectBusinessType;
