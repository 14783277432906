import dataStore from '../../../../common/dataStore';
import { DATA_STORE_KEYS } from '../../../../common/dataStore/keys';

export const TOGGLE_ENV_MODE = 'TOGGLE_ENV_MODE';
export const LOAD_SAVED_STATE = 'LOAD_SAVED_STATE';
export const RESET_ENV_STATE = 'RESET_ENV_STATE';

export const resetEnvironment = () => dispatch => {
  dispatch({
    type: RESET_ENV_STATE
  });
};

export const switchEnvironment = isLiveMode => dispatch => {
  dataStore.save(DATA_STORE_KEYS.WAS_PREVIOUSLY_ON_LIVE_VIEW, isLiveMode);
  dispatch({
    type: TOGGLE_ENV_MODE,
    payload: isLiveMode
  });
};

export const buildEnvAwareData = (action, isLiveMode) => {
  const { type, payload } = action;
  return {
    type,
    payload: {
      environment: isLiveMode ? 'live' : 'test',
      data: payload
    }
  };
};

export const initEnvironmentAware = () => (dispatch, getState) => {
  const enviromentAwareState = getState().environmentAware;
  
  if (enviromentAwareState.stateLoadedFromLocalStorage === false) {
    //load state from local storage
    const kycStatus = dataStore.get(DATA_STORE_KEYS.KYC_STATUS);
    let appMode = 'LEGACY' === kycStatus || 'COMPLETED' === kycStatus;

    if (appMode === true) {
      let wasPreviouslyOnLiveView = dataStore.get(
        DATA_STORE_KEYS.WAS_PREVIOUSLY_ON_LIVE_VIEW
      );

      if (wasPreviouslyOnLiveView !== null) {
        appMode = wasPreviouslyOnLiveView;
      }
    }

    dispatch({
      type: LOAD_SAVED_STATE,
      payload: appMode
    });
  }
};


export const initEnvironmentAwareNew = (dispatch, getState) => {
  const enviromentAwareState = getState().environmentAware;
    //load state from local storage
    const kycStatus = dataStore.get(DATA_STORE_KEYS.KYC_STATUS);
    let appMode = 'LEGACY' === kycStatus || 'COMPLETED' === kycStatus;

    if (appMode === true) {
      let wasPreviouslyOnLiveView = dataStore.get(
        DATA_STORE_KEYS.WAS_PREVIOUSLY_ON_LIVE_VIEW
      );

      if (wasPreviouslyOnLiveView !== null) {
        appMode = wasPreviouslyOnLiveView;
      }
    }else{
      dataStore.save(DATA_STORE_KEYS.WAS_PREVIOUSLY_ON_LIVE_VIEW, appMode);
    }

    dispatch({
      type: LOAD_SAVED_STATE,
      payload: appMode
    });
 
};
