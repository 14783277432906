import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import qs from "querystring";
import { setLayoutState } from "./../../../reducers/app";
import { merge } from "lodash";
import dataStore from "../../../dataStore";
import { DATA_STORE_KEYS } from "../../../dataStore/keys";

const mapStateToProps = (state, props) => ({
  layoutState: state.app.layoutState,
});

@connect(mapStateToProps)
@withRouter
class LayoutState extends React.PureComponent {
  bootstrapLayoutSettings() {
    const { dispatch } = this.props;
    const urlParams = qs.parse(this.props.location.search.replace("?", ""));
    const storageParams = dataStore.get(DATA_STORE_KEYS.APP_LAYOUT_STATE);

    if (storageParams) {
      delete storageParams.settingsOpened;
    }

    const mergedParams = merge({}, storageParams, urlParams);
    const booleanMergedParams = JSON.parse(
      JSON.stringify(mergedParams),
      (key, value) =>
        value === "true" ? true : value === "false" ? false : value,
    );
    dispatch(setLayoutState({ ...booleanMergedParams }));
  }

  UNSAFE_componentWillMount() {
    this.bootstrapLayoutSettings();
  }

  render() {
    return null;
  }
}

export default LayoutState;
