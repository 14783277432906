import {
  SAVE_WORKFLOW_SUMMARY,
  WORKFLOW_LOADING,
  SAVE_USER_HISTORY_WORKFLOW,
  SAVE_ALL_HISTORY_WORKFLOW,
  SAVE_ALL_PENDING_WORKFLOW,
  SAVE_USER_PENDING_WORKFLOW,
  SAVE_WORKFLOW_FILTER_PARAMS,
} from "../actions/types";
import _ from "lodash";

const INIT_DATA = {
  summary: {
    myRequests: 0,
    myPendingApprovals: 0,
  },
  myRequestPendingWorkflow: {
    test: [],
    live: [],
  },
  myRequestHistoryWorkflow: {
    test: [],
    live: [],
  },
  teamHistoryWorkflow: {
    test: [],
    live: [],
  },
 teamPendingWorkflow: {
    test: [],
    live: [],
  },
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
    pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
  },
  filterParams: {},
  loading: false,
};

export default (state = INIT_DATA, action) => {
  switch (action.type) {
    case SAVE_WORKFLOW_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case SAVE_USER_PENDING_WORKFLOW:
      let myRequestPendingWorkflow = _.cloneDeep(state.myRequestPendingWorkflow);
      myRequestPendingWorkflow[action.payload.environment] = action.payload.data;

      return {
        ...state,
        myRequestPendingWorkflow,
      };
      case SAVE_ALL_PENDING_WORKFLOW:
        let teamPendingWorkflow = _.cloneDeep(state.teamPendingWorkflow);
        teamPendingWorkflow[action.payload.environment] = action.payload.data;
  
        return {
          ...state,
          teamPendingWorkflow,
        };
    case SAVE_USER_HISTORY_WORKFLOW:
      let myRequestHistoryWorkflow = _.cloneDeep(
        state.myRequestHistoryWorkflow
      );
      myRequestHistoryWorkflow[action.payload.environment] =
        action.payload.data;

      return {
        ...state,
        myRequestHistoryWorkflow,
      };
      case SAVE_ALL_HISTORY_WORKFLOW:
        let teamHistoryWorkflow = _.cloneDeep(
          state.teamHistoryWorkflow
        );
        teamHistoryWorkflow[action.payload.environment] =
          action.payload.data;
  
        return {
          ...state,
          teamHistoryWorkflow,
        };
    case WORKFLOW_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SAVE_WORKFLOW_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
      };
    default:
      return state;
  }

}