import {
  SET_KYC_ON_LOGIN,
  SAVE_BUSINESS_TYPE_LOADING,
  SELF_SIGN_UP_LOADING,
  SELF_SIGN_UP_SUCCESS,
  KYC_VERIFY_PROFILE_LOADING,
  KYC_VERIFY_BANK_LOADING,
  VALIDATE_KYC_STATUS,
  KYC_VERIFY_PROFILE_SUCCESS,
  KYC_VERIFY_BANK_SUCCESS,
  GET_BUSINESS_CATEGORIES,
  KYC_ACTIVATION_STATUS_LOADING,
  KYC_ACTIVATION_STATUS_SUCCESS,
  GET_ID_TYPES_LOADING,
  GET_ID_TYPES_SUCCESS,
  GET_COMPANY_REG_TYPES_LOADING,
  GET_COMPANY_REG_TYPES_SUCCESS,
  SWITCH_BUSINESS_TYPE_LOADING,
  SWITCH_BUSINESS_TYPE_SUCCESS,
  UPDATE_KYC_VERIFICATION_SUCCESS,
  UPDATE_BANK_VERIFICATION_SUCCESS,
  UPDATE_BANK_VERIFICATION_LOADING,
  UPDATE_BUSINESS_VERIFICATION_LOADING,
  UPDATE_BUSINESS_VERIFICATION_SUCCESS,
  VALIDATE_EMAIL_FAILURE,
  VALIDATE_EMAIL_LOADING,
  VALIDATE_EMAIL_SUCCESS,
  KYC_VERIFY_BUSINESS_LOADING,
  KYC_VERIFY_BUSINESS_SUCCESS,
  UPDATE_VERIFY_PROFILE_FLAG,
  UPDATE_VERIFY_BANK_FLAG,
  UPDATE_VERIFY_BUSINESS_FLAG,
  UPDATE_COMPANY_REGISTRATION_SUCCESS,
  GET_LGA,
  KYC_VERIFY_COMPANY_LOADING,
  COMPLETE_USER_INVITE_LOADING,
  ACTIVATE_USER_INVITATION,
  ACTIVATE_USER_INVITATION_LOADING,
  ACTIVATE_USER_INVITATION_STATUS,
  SAVE_USER_AUTHORITIES,
  SET_KYC,
  GET_APPLICABLE_KYC,
  UPDATE_APPLICABLE_KYC_LOADING,
  SAVE_STATIC_KYC_INFORMATION,
  LOGIN_STATUS,
  SAVE_KYC_STATUS

} from '../actions/types';

const INIT_STATE = {
  firstName: '',
  lastName: '',
  businessType: '',
  businessCategory: [],
  loading: false,
  stage: '',
  merchantKycStatus: null,
  kycStatusCompleted: false,
  merchantkycItemStatusMap: {},
  staticKycInformation: {},
  emailValidationMessage: '',
  emailValidationLoading: false,
  signUp: {
    loading: false,
    businessName: '',
    email: '',
  },
  verifyProfile: {
    loading: false,
    isVerified: false,
    idTypes: [],
  },
  verifyBank: {
    loading: false,
    isVerified: false,
  },
  verifyBusiness: {
    loading: false,
    isVerified: false,
    countries: [],
    states: [],
    lgas: [],
  },
  userInvitation: {
    loading: false,
    isProcessingActivation: false,
    status: '',
    data: {},
  },
  authorities: [],
  applicableKyc: [],
  applicableKycLoadin: false,
  isLoggedIn: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_KYC_ON_LOGIN:
      return {
        ...state,
        merchantKycStatus: action.payload.merchantKycStatus,
        kycStatusCompleted: true,
      };
    case SAVE_KYC_STATUS:
      return {
        ...state,
        merchantKycStatus: action.payload
      };
    case SET_KYC:
      return {
        ...state,
        merchantKycStatus: action.payload,
        kycStatusCompleted: action.payload === "COMPLETED" || action.payload === "LEGACY"
      };
    case SELF_SIGN_UP_LOADING:
      return {
        ...state,
        ...state.signUp,
        loading: !!action.payload,
      };
    case COMPLETE_USER_INVITE_LOADING:
      return {
        ...state,
        ...state.userInvitation,
        loading: !!action.payload,
      };
    case ACTIVATE_USER_INVITATION_LOADING:
      return {
        ...state,
        userInvitation: {
          ...state.userInvitation,
          isProcessingActivation: action.payload,
        },
      };
    case ACTIVATE_USER_INVITATION:
      return {
        ...state,
        userInvitation: {
          ...state.userInvitation,
          data: { ...action.payload },
        },
      };
    case ACTIVATE_USER_INVITATION_STATUS:
      return {
        ...state,
        userInvitation: {
          ...state.userInvitation,
          status: action.payload,
        },
      };
    case SELF_SIGN_UP_SUCCESS:
      return {
        ...state,
        merchantKycStatus: action.payload.merchantKycStatus,
        kycStatusCompleted: true,
      };
    case GET_BUSINESS_CATEGORIES:
      return { ...state, businessCategory: action.payload };
    case SAVE_BUSINESS_TYPE_LOADING:
      return {
        ...state,
        loading: !!action.payload,
      };
    case KYC_VERIFY_PROFILE_LOADING:
      return {
        ...state,
        verifyProfile: { ...state.verifyProfile, loading: !!action.payload },
      };
    case KYC_VERIFY_PROFILE_SUCCESS:
      return {
        ...state,
        verifyProfile: { ...state.verifyProfile, isVerified: action.payload },
      };

    case UPDATE_VERIFY_PROFILE_FLAG:
      return {
        ...state,
        verifyProfile: { ...state.verifyProfile, isVerified: action.payload },
      };

    case VALIDATE_KYC_STATUS:
      return {
        ...state,
        businessType: action.payload.businessType,
        merchantKycStatus: action.payload.kycStatus,
        kycStatusCompleted: true,
      };
    case KYC_VERIFY_BANK_LOADING:
      return {
        ...state,
        verifyBank: {
          ...state.verifyBank,
          loading: !!action.payload,
        },
      };
    case KYC_VERIFY_BANK_SUCCESS:
      return {
        ...state,
        verifyBank: {
          ...state.verifyBank,
          isVerified: action.payload,
        },
      };

    case KYC_VERIFY_BUSINESS_LOADING: {
      return {
        ...state,
        verifyBusiness: {
          ...state.verifyBusiness,
          loading: !!action.payload,
        },
      };
    }

    case UPDATE_VERIFY_BANK_FLAG:
      return {
        ...state,
        verifyBank: { ...state.verifyBank, isVerified: action.payload },
      };

    case UPDATE_VERIFY_BUSINESS_FLAG:
      return {
        ...state,
        verifyBusiness: { ...state.verifyBusiness, isVerified: action.payload },
      };

    case KYC_VERIFY_BUSINESS_SUCCESS: {
      return {
        ...state,
        verifyBusiness: {
          ...state.verifyBusiness,
          isVerified: action.payload,
        },
      };
    }

    case UPDATE_BUSINESS_VERIFICATION_LOADING: {
      return {
        ...state,
        verifyBusiness: {
          ...state.verifyBusiness,
          loading: !!action.payload,
        },
      };
    }

    case UPDATE_BUSINESS_VERIFICATION_SUCCESS: {
      return {
        ...state,
        verifyBusiness: {
          ...state.verifyBusiness,
          submitted: action.payload.businessDetails,
        },
      };
    }
    case KYC_ACTIVATION_STATUS_LOADING:
      return {
        ...state,
        loading: !!action.payload,
      };
    case KYC_ACTIVATION_STATUS_SUCCESS: {
      return {
        ...state,
        merchantkycItemStatusMap: { ...action.payload.kycItemStatusMap },
      };
    }
    case SAVE_STATIC_KYC_INFORMATION: {
      return {
        ...state,
        merchantKycStatus: action.payload.kycStatus,
        staticKycInformation: { ...action.payload },
      };
    }
    case GET_ID_TYPES_LOADING: {
      return {
        ...state,
        verifyProfile: {
          ...state.verifyProfile,
          loading: !!action.payload,
        },
      };
    }
    case GET_ID_TYPES_SUCCESS: {
      return {
        ...state,
        verifyProfile: {
          ...state.verifyProfile,
          idTypes: action.payload,
        },
      };
    }

    case GET_COMPANY_REG_TYPES_LOADING: {
      return {
        ...state,
        verifyProfile: {
          ...state.verifyProfile,
          loading: !!action.payload,
        },
      };
    }

    case GET_COMPANY_REG_TYPES_SUCCESS: {
      return {
        ...state,
        verifyProfile: {
          ...state.verifyProfile,
          idTypes: action.payload,
        },
      };
    }

    case SWITCH_BUSINESS_TYPE_LOADING: {
      return {
        ...state,
        loading: !!action.payload,
      };
    }

    case SWITCH_BUSINESS_TYPE_SUCCESS: {
      return {
        ...state,
        businessType: action.payload,
        verifyProfile: {
          ...state.verifyProfile,
          submitted: {},
        },
      };
    }

    case UPDATE_KYC_VERIFICATION_SUCCESS: {
      return {
        ...state,
        verifyProfile: {
          ...state.verifyProfile,
          submitted: action.payload.identityCard,
        },
      };
    }

    case UPDATE_COMPANY_REGISTRATION_SUCCESS: {
      return {
        ...state,
        verifyProfile: {
          ...state.verifyProfile,
          submitted: action.payload.companyRegistrationDetails,
        },
      };
    }

    case KYC_VERIFY_COMPANY_LOADING: {
      return {
        ...state,
        verifyProfile: {
          ...state.verifyProfile,
          loading: !!action.payload,
        },
      };
    }

    case UPDATE_BANK_VERIFICATION_SUCCESS: {
      return {
        ...state,
        verifyBank: {
          ...state.verifyBank,
          submitted: action.payload.accountDetails,
        },
      };
    }

    case UPDATE_BANK_VERIFICATION_LOADING: {
      return {
        ...state,
        verifyBank: {
          ...state.verifyBank,
          loading: !!action.payload,
        },
      };
    }

    case VALIDATE_EMAIL_FAILURE: {
      return {
        ...state,
        emailValidationMessage: action.payload,
      };
    }

    case VALIDATE_EMAIL_LOADING: {
      return {
        ...state,
        emailValidationLoading: !!action.payload,
      };
    }

    case VALIDATE_EMAIL_SUCCESS: {
      return {
        ...state,
        emailValidationMessage: action.payload,
      };
    }

    case SAVE_USER_AUTHORITIES: {
      return {
        ...state,
        authorities: action.payload.authorities,
      };
    }

    case GET_APPLICABLE_KYC: {
      return {
        ...state,
        applicableKyc: action.payload
      }
    }
    case UPDATE_APPLICABLE_KYC_LOADING: {
      return {
        ...state,
        applicableKycLoading: action.payload
      }
    }
    case LOGIN_STATUS: {
      return {
        ...state,
        isLoggedIn: action.payload
      }
    }
    default:
      return state;
  }
};
