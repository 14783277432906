import { UPDATE_APP_ENVIRONMENT } from "./../actions/types";
import { DATA_STORE_KEYS } from "../dataStore/keys";
import dataStore from "../dataStore";


const INITIAL_STATE = {
    isLive:  !!dataStore.get(DATA_STORE_KEYS.APP_ENVIRONMENT),
    switchInProgress: false
};

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {
        case UPDATE_APP_ENVIRONMENT:
            dataStore.save(DATA_STORE_KEYS.APP_ENVIRONMENT, action.payload);
            return { ...state, isLive : action.payload };
        default:
            return state
    }
};