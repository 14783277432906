import React from "react";
import { connect } from "react-redux";
import { login, LOGIN_FORM } from "../../../../common/actions/auth";
import Optional from "../../../../common/components/LayoutComponents/Optional";
import { Alert, Button, Form, Input } from "antd/es";
import "./style.scss";
import { Link } from "react-router-dom";
import Icon from "antd/es/icon";
import AuthenticationFormAlert from "../../../../common/components/LayoutComponents/AuthenticationFormAlert";

const FormItem = Form.Item;

const mapStateToProps = (state, props) => {
  let submitError = null;
  if (state.formError && state.formError[LOGIN_FORM]) {
    submitError = state.formError[LOGIN_FORM];
  }

  return {
    isSubmitForm: state.app.submitForms && state.app.submitForms[LOGIN_FORM],
    submitError: submitError,
  };
};

@connect(mapStateToProps)
@Form.create()
class LoginForm extends React.Component {
  static defaultProps = {};

  onSubmit = (event) => {
    event.preventDefault();
    const { form, dispatch, isSubmitForm } = this.props;
    if (isSubmitForm) {
      return;
    }

    form.validateFields((error, values) => {
      if (error) {
        return;
      }

      dispatch(login(values));
    });
  };

  hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  render() {
    const { form, isSubmitForm } = this.props;

    return (
      <div className="monnify-login-form monnify-no-auth-form">
        <div className="form-container">
          <h1 className="title">Login to your account</h1>

          <Optional visible={this.props.submitError}>
            <AuthenticationFormAlert
              type="danger"
              title={this.props.submitError}
            />
            {/* <Alert
              message="Error:"
              description={this.props.submitError}
              showIcon
              icon={
                <Icon
                  theme="filled"
                  style={{ color: "#ec6160" }}
                  type="warning"
                />
              }
              type="error"
              banner
            /> */}
          </Optional>

          <Form
            layout="vertical"
            hideRequiredMark
            name="loginForm"
            id="loginForm"
            onSubmit={this.onSubmit}
          >
            <FormItem label="Email">
              {form.getFieldDecorator("username", {
                initialValue: "",
                rules: [
                  {
                    type: "string",
                    message: "The input is not a valid email",
                  },
                  { required: true, message: "Please input your email" },
                ],
              })(<Input />)}
            </FormItem>

            <FormItem label="Password">
              {form.getFieldDecorator("password", {
                initialValue: "",
                rules: [
                  { required: true, message: "Please input your password" },
                ],
              })(<Input.Password type="password" />)}
            </FormItem>
            <div className="call-to-action">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>

            <div>
              <Button
                type="secondary"
                htmlType="submit"
                disabled={this.hasErrors(form.getFieldsError())}
                loading={isSubmitForm}
              >
                Login
              </Button>
            </div>
          </Form>
        </div>

        <div className="footer-action">
          <span className="copy1">New to Monnify? </span>
          <Link to="/create-account" className="copy2">
            Create an account
          </Link>{" "}
        </div>
      </div>
    );
  }
}

export default LoginForm;
