import {
  SAVE_MFA_DETAILS,
  VERIFY_OTP,
  MFA_LOADING,
  UPDATE_MFA_CONFIG,
  MFA_PREFERENCE_LOADING,
  SAVE_CONTACT_DETAILS,
  VERIFY_MOBILE_LOADING,
  REQUEST_OTP_LOADING,
} from "../actions/types";

const INIT_STATE = {
  data: {},
  otpVerification: {},
  loading: false,
  preferenceLoading: false,
  contactDetails: {},
  verifyMobileLoading: false,
  otpRequestLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_MFA_DETAILS:
      return { ...state, data: action.payload };
    case VERIFY_OTP:
      return { ...state, otpVerification: action.payload };
    case MFA_LOADING:
      return { ...state, loading: action.payload };
    case UPDATE_MFA_CONFIG:
      const data = state.data;
      return { ...state, data: { ...data, ...action.payload } };
    case MFA_PREFERENCE_LOADING:
      return { ...state, preferenceLoading: action.payload };
    case SAVE_CONTACT_DETAILS:
      return { ...state, contactDetails: action.payload };
    case VERIFY_MOBILE_LOADING:
      return { ...state, verifyMobileLoading: action.payload };
    case REQUEST_OTP_LOADING:
      return { ...state, otpRequestLoading: action.payload }
    default:
      return state;
  }
};
