import React from "react";
import { Tag, Button } from "antd";
import styled from "styled-components";
import CopyText from "../CopyText";
import MonnifyIcon from "../../../icons";

function MerchantCodeTag({ business }) {
  const merchantCode = business?.merchantCode;
  return merchantCode ? (
    <StyledMerchantCodeWrap>
      <span className="merchant-code-data">
        <span className="merchant-label"> Business Code</span>
        <Tag>
          {merchantCode.slice(0, 12)}
          <CopyText high value={merchantCode}>
            <button className="ant-btn">
              <MonnifyIcon type="copy_icon" />
            </button>
          </CopyText>
        </Tag>
      </span>
    </StyledMerchantCodeWrap>
  ) : null;
}

export default MerchantCodeTag;

const StyledMerchantCodeWrap = styled("div")`
  letter-spacing: 0.0115em;
  padding: 16px 0;
  .merchant-code-data {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 4px;
  }
  .merchant-label {
    font-weight: 500;
    font-size: 10px;
    text-align: left;
    color: rgba(0, 0, 0, 0.5);
    line-height: 12.1px;
    margin: 0!important;
    white-space: nowrap;
  }
  .ant-tag {
    background-color: rgba(180, 203, 213, 0.3);
    border-radius: 50px;
    font-size: 11px;
    line-height: 14.52px;
    font-weight: 600;
    text-align: center;
    padding: 2px 6px;
    border: none;
    max-width: 125px;
    color: rgba(0, 0, 0, 0.7);
    margin: 0!important;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover{
      opacity: 1;
    }
    .monnify-icon{
      margin-left: 3px;
      svg{
        height: 12px;
        width: 12px;
        path{
          fill: #063A4F4D;
        }
      }
    }
  
  }
  button, button:hover, button:focus{
    background-color: transparent;
    margin: 0;
    border: none;
    display: inline-block;
    width: unset;
    padding: 0;
    height: unset;
    box-shadow: none;
    .monnify-icon{
      margin-right: 0
    }
  }
  
`;
