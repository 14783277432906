import React from "react";
import { connect } from "react-redux";
import { Layout, Menu, Button, Avatar, Tooltip, Divider } from "antd";
import { Link, withRouter } from "react-router-dom";
import { includes, reduce, intersection } from "lodash";
import { setLayoutState } from "../../../../reducers/app";
import { default as menuData } from "./menuData";
import "./style.scss";
import dataStore from "../../../../dataStore";
import { DATA_STORE_KEYS } from "../../../../dataStore/keys";
import Media from "react-media";
import {
  toggleShowSelectBusinessModal,
  moveToAddBusinessPage,
} from "../../../../actions/businesses";
import MonnifyIcon from "../../../../icons";
import { QuestionCircleFilled } from "@ant-design/icons";
import MenuBadge from "../../Badge";
import MerchantCodeTag from "../../MerchantCodeTag";

const { Sider } = Layout;

const mapStateToProps = (state, props) => {
  const { app, appEnvironment, onboarding, business } = state;
  const { layoutState } = app;

  const user = dataStore.get(DATA_STORE_KEYS.USER_DATA);

  return {
    pathname: props.location && props.location.pathname,
    collapsed: !!layoutState.menuOpened,
    theme: "light",
    settingsOpened: false,
    isLiveEnvironment: state.environmentAware.isLiveMode,
    user: user || {},
    merchantKycStatus: onboarding.merchantKycStatus,
    authorities:
      onboarding.authorities && onboarding.authorities.length > 0
        ? onboarding.authorities
        : dataStore.get(DATA_STORE_KEYS.AUTHORITIES),
    hideSwitch: business.showBusinessModal,
    badges: {
      eventLogsFailedCount: state.eventLogs.eventLogsAggregate.failedCount,
      pendingWorkflowCount: state.workflow.summary.myPendingApprovals,
      workflowRequestCount: state.workflow.summary.myRequests,
    },
  };
};

@connect(mapStateToProps, {
  toggleShowSelectBusinessModal,
  moveToAddBusinessPage,
})
@withRouter
class MenuLeft extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pathname: props.location.pathname,
      collapsed: props.collapsed,
      theme: props.theme,
      selectedKeys: "",
      openKeys: [""],
      settingsOpened: props.settingsOpened,
      isLiveEnvironment: props.isLiveEnvironment,
      businessActionsExpanded: false,
    };

    this.PENDING_BUSINESS_TYPE = "PENDING_BUSINESS_TYPE";
  }

  componentDidMount() {
    this.getActiveMenuItem(this.props, menuData);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    let newState = {
      selectedKeys: "",
      pathname: nextProps.location.pathname,
      theme: nextProps.theme,
      settingsOpened: nextProps.settingsOpened,
      isLiveEnvironment: nextProps.isLiveEnvironment,
    };

    if (nextProps.hideSwitch) {
      newState["businessActionsExpanded"] = false;
    }
    this.setState(newState, () => {
      if (!nextProps.isMobile) {
        this.getActiveMenuItem(nextProps, menuData);
      }
    });
  }

  hasRole(requiredRole) {
    if (!requiredRole) {
      return true;
    }
    return (
      intersection(this.props.authorities, requiredRole.split(/,/)).length > 0
    );
  }

  canAccessFeature(requiredFeatureToggleKey) {
    const bussiness = dataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);

    if (!requiredFeatureToggleKey) {
      return true;
    }

    if (bussiness === null || !bussiness.merchantAllowedFeatures) {
      return false;
    }

    if (Array.isArray(requiredFeatureToggleKey)) {
      return (
        intersection(
          bussiness.merchantAllowedFeatures,
          requiredFeatureToggleKey,
        ).length > 0
      );
    }

    return includes(
      bussiness.merchantAllowedFeatures,
      requiredFeatureToggleKey,
    );
  }

  handleClick = (e) => {
    this.props.isMobile && this.toggleCollapsed();

    // set current selected keys
    this.setState({
      selectedKeys: e.key,
    });
  };

  onOpenChange = (openKeys) => {
    this.setState({
      openKeys: openKeys,
    });
  };

  getPath(data, id, parents = []) {
    const { selectedKeys } = this.state;

    let items = reduce(
      data,
      (result, entry) => {
        if (result.length) {
          return result;
        } else if (entry.url === id && selectedKeys === "") {
          return [entry].concat(parents);
        } else if (entry.key === id && selectedKeys !== "") {
          return [entry].concat(parents);
        }

        return result;
      },
      [],
    );

    return items.length > 0 ? items : [false];
  }

  getActiveMenuItem = (props, items) => {
    const { selectedKeys, pathname } = this.state;
    let { collapsed } = props;

    let [activeMenuItem, ...path] = this.getPath(
      items,
      !selectedKeys ? pathname : selectedKeys,
    );

    if (collapsed) {
      path = [""];
    }

    this.setState({
      selectedKeys: activeMenuItem ? activeMenuItem.key : "",
      openKeys: activeMenuItem ? path.map((entry) => entry.key) : [],
    });
  };

  generateMenuPartitions(items) {
    let allowedMenu = items.filter((menu) => {
      const kycStatus = dataStore.get(DATA_STORE_KEYS.KYC_STATUS);
      if (menu.key === "home") {
        return (
          kycStatus === "PENDING_BUSINESS_TYPE" ||
          kycStatus === "PENDING_KYC_VERIFICATION"
        );
      }
      return (
        this.hasRole(menu.requiredRole) &&
        this.canAccessFeature(menu.requiredFeatureToggleKey)
      );
    });
    return allowedMenu.map((menuItem, index) => {
      return this.generateMenuItem(menuItem, index);
    });
  }

  generateMenuItem(item, index) {
    const { key, title, url, icon, disabled, isSection, isDivider, badgeKey } =
      item;
    const { collapsed, badges } = this.props;

    if (isDivider) {
      return <div key={index} className="menu-divider collapsed"></div>;
    }

    if (isSection) {
      return collapsed ? (
        <div key={index} className="menu-divider collapsed"></div>
      ) : (
        <div key={index} className="menu-divider">
          {title.toUpperCase()}
        </div>
      );
    }

    return (
      <Menu.Item key={key} disabled={disabled} title={title}>
        <Link to={url}>
          {icon && (
            <span
              className="menu-left-icon"
              dangerouslySetInnerHTML={{ __html: icon }}
            />
          )}
          {title && <span className="menu-left-title">{title}</span>}
          {badgeKey && <MenuBadge data={badges[badgeKey]} />}
        </Link>
      </Menu.Item>
    );
  }

  onCollapse = (value, type) => {
    const { dispatch } = this.props;
    const { collapsed } = this.props;

    dispatch(
      setLayoutState({ menuCollapsed: !collapsed, menuOpened: collapsed }),
    );
  };

  toggleCollapsed = () => {
    const { dispatch } = this.props;

    dispatch(
      setLayoutState({
        menuCollapsed: this.props.collapsed,
        menuOpened: !this.props.collapsed,
      }),
    );
  };

  onChangeBusinessClick = (e) => {
    this.props.toggleShowSelectBusinessModal();
  };
  onAddBusinessClick = (e) => {
    this.props.moveToAddBusinessPage();
  };
  toggleExpandBusinessActions = (e) => {
    this.setState({
      businessActionsExpanded: !this.state.businessActionsExpanded,
    });
  };

  renderMerchantCodeTag = (collapsed, business) =>
    !collapsed ? (
      <div className="merchant-code-tag">
        <Divider />
        <MerchantCodeTag business={business} />
      </div>
    ) : null;

  render() {
    const {
      selectedKeys,
      openKeys,
      isLiveEnvironment,
      pathname,
      businessActionsExpanded,
    } = this.state;
    const { user, merchantKycStatus, hideSwitch } = this.props;
    const menuItems = this.generateMenuPartitions(menuData);

    const merchant_businesses = dataStore.get(
      DATA_STORE_KEYS.MERCHANT_BUSINESSES,
    );
    const active_business = dataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);

    const collapsed =
      pathname === "/add-business" ? true : this.props.collapsed;

    // const paramsMobile = {
    //   width: 256,
    //   collapsible: true,
    //   collapsed: collapsed,
    //   onCollapse: this.onCollapse,
    //   theme: 'light'
    // };

    // const paramsDesktop = {
    //   width: 256,
    //   collapsible: true,
    //   collapsed: collapsed,
    //   onCollapse: this.onCollapse,
    //   theme: 'light'
    // };
    return (
      <div className={`menus-parent ${collapsed && "collapsed"}`}>
        <div className="menus">
          {pathname === "/add-business" ? (
            <div className="menu-blur"></div>
          ) : (
            ""
          )}

          <div className={`toggler ${collapsed && "collapsed"}`}>
            {!collapsed && <img src={"/images/logo.svg"} alt="Logo" />}
            <Media
              key="1"
              query="(min-width: 600px)"
              render={() => (
                <Button type="link" onClick={this.toggleCollapsed}>
                  <MonnifyIcon type="menu" />
                </Button>
              )}
            />
            {/*  */}
          </div>
          <Media key="2" query="(max-width: 600px)">
            {(matches) =>
              matches ? (
                <Sider
                  theme="light"
                  collapsible
                  collapsed={collapsed}
                  className="menu-left"
                  trigger={null}
                  collapsedWidth="0"
                >
                  <div className="user-card">
                    <div>
                      <div className="main">
                        <div className="avatar-cont">
                          <Avatar
                            size={38}
                            style={{ backgroundColor: "#00B8C2" }}
                          >
                            {user.firstName ? user.firstName[0] : ""}
                          </Avatar>
                        </div>
                        <div className="text-cont">
                          <div className="greeting">
                            {active_business
                              ? active_business.businessName
                              : "Welcome"}
                          </div>
                          <div className="name ellipsis">
                            <span>{user.firstName || "Hi"}</span>{" "}
                            <span>{user.lastName || "there!"}</span>
                          </div>
                        </div>
                      </div>
                      {this.renderMerchantCodeTag(collapsed, active_business)}
                    </div>
                  </div>
                  {merchantKycStatus !== this.PENDING_BUSINESS_TYPE && (
                    <Menu
                      onClick={this.handleClick}
                      selectedKeys={[selectedKeys]}
                      openKeys={openKeys}
                      onOpenChange={this.onOpenChange}
                      mode="inline"
                      className="menu-left-navigation"
                    >
                      {menuItems}
                    </Menu>
                  )}
                  <div className="extra-links extra-links-sm">
                    <ul>
                      <li>
                        <a
                          href="https://monnify.statuspage.io/"
                          target="_blank"
                        >
                          {" "}
                          <Button type="text" className="btn-status">
                            {" "}
                            {this.props.collapsed ? (
                              <MonnifyIcon
                                type="visit"
                                style={{
                                  textAlign: "center",
                                  marginLeft: "2px",
                                }}
                              />
                            ) : (
                              <>
                                Monnify Status
                                <span className="status-icon">
                                  <MonnifyIcon type="visit" />{" "}
                                </span>
                              </>
                            )}
                          </Button>
                        </a>
                      </li>
                      <li>
                        <a href="https://support.monnify.com/" target="_blank">
                          <Button type="primary" className="btn-help">
                            {" "}
                            <QuestionCircleFilled className="icon-links" />
                            Need help ?
                          </Button>
                        </a>
                      </li>
                      <li className="brand-logo">
                        <span className={`by-text`}>by</span>
                        <img
                          src="images/moniepointLogo-grey.svg"
                          alt="Moniepoint-logo"
                        />
                      </li>
                    </ul>
                  </div>
                </Sider>
              ) : (
                <Sider
                  theme="light"
                  collapsible
                  collapsed={collapsed}
                  className="menu-left"
                  width={250}
                  trigger={null}
                >
                  <div className="user-card">
                    <div>
                      <div className="main">
                        <div className="avatar-cont">
                          <Avatar
                            size={38}
                            style={{
                              fontWeight: "bolder",
                              backgroundColor: "#00B8C2",
                            }}
                          >
                            {user.firstName
                              ? user.firstName[0].toUpperCase()
                              : ""}
                          </Avatar>
                        </div>
                        <div className="text-cont">
                          <div className="greeting ellipsis">
                            <Tooltip
                              title={
                                active_business
                                  ? active_business.businessName
                                  : "Welcome"
                              }
                            >
                              {active_business
                                ? active_business.businessName
                                : "Welcome"}
                            </Tooltip>
                          </div>
                          <div className="name ellipsis">
                            <span>{user.firstName || "Hi"}</span>{" "}
                            <span>{user.lastName || "there!"}</span>
                          </div>
                        </div>
                        {/* {merchantKycStatus !== this.PENDING_BUSINESS_TYPE &&
                      !collapsed && (
                        <div
                          onClick={this.toggleExpandBusinessActions}
                          className={`view-icon ${
                            businessActionsExpanded ? "active" : ""
                          }`}
                        >
                          <span className="arrow"></span>
                        </div>
                      )} */}
                        <div className="clearfix"></div>
                      </div>
                      {this.renderMerchantCodeTag(collapsed, active_business)}
                    </div>
                    {merchantKycStatus !== this.PENDING_BUSINESS_TYPE &&
                      !collapsed && (
                        <div
                          className={`business-actions-wrapper ${
                            businessActionsExpanded ? "" : "hide"
                          }`}
                        >
                          <div className={`business-actions `}>
                            {merchant_businesses &&
                            merchant_businesses.length > 1 ? (
                              <Button
                                className="save-button"
                                type="secondary"
                                onClick={this.onChangeBusinessClick}
                                style={{
                                  borderBottom:
                                    "1px solid rgba(218, 218, 218, 0.4)",
                                }}
                              >
                                <MonnifyIcon type="switch_business" />
                                Switch Business
                                <span className="monnify-tag">
                                  {merchant_businesses.length}
                                </span>
                              </Button>
                            ) : (
                              ""
                            )}

                            <Button
                              className="save-button"
                              type="secondary"
                              onClick={this.onAddBusinessClick}
                            >
                              <MonnifyIcon type="add_business" />
                              Add Business
                            </Button>
                          </div>
                        </div>
                      )}
                  </div>
                  {merchantKycStatus !== this.PENDING_BUSINESS_TYPE && (
                    <Menu
                      onClick={this.handleClick}
                      selectedKeys={[selectedKeys]}
                      openKeys={openKeys}
                      onOpenChange={this.onOpenChange}
                      mode="inline"
                      className="menu-left-navigation"
                    >
                      {menuItems}
                    </Menu>
                  )}
                  <div className="extra-links">
                    <ul>
                      <li>
                        <a
                          href="https://monnify.statuspage.io/"
                          target="_blank"
                        >
                          {" "}
                          {this.props.collapsed ? (
                            <Tooltip placement="rightTop" title={"Status Page"}>
                              <MonnifyIcon
                                type="visit"
                                style={{
                                  textAlign: "center",
                                  marginLeft: "4px",
                                }}
                              />{" "}
                            </Tooltip>
                          ) : (
                            <Button type="text" className="btn-status">
                              {" "}
                              Monnify Status
                              <span className="status-icon">
                                <MonnifyIcon type="visit" />{" "}
                              </span>
                            </Button>
                          )}
                        </a>
                      </li>
                      <li>
                        <a href="https://support.monnify.com/" target="_blank">
                          {this.props.collapsed ? (
                            <Tooltip placement="rightTop" title={"Support"}>
                              {" "}
                              <QuestionCircleFilled className="icon-sm" />{" "}
                            </Tooltip>
                          ) : (
                            <Button type="primary" className="btn-help">
                              {" "}
                              <QuestionCircleFilled className="icon-links" />
                              Need help ?
                            </Button>
                          )}
                        </a>
                      </li>
                      {this.props.collapsed ? (
                        <li className="brand-logo">
                          <span className={`by-text`}>by:</span>
                          <br />
                          <img
                            src="images/moniepointLogo-grey.svg"
                            alt="Moniepoint-logo"
                            width={60}
                          />
                        </li>
                      ) : (
                        <li className="brand-logo">
                          <span className={`by-text`}>by</span>
                          <img
                            src="images/moniepointLogo-grey.svg"
                            alt="Moniepoint-logo"
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </Sider>
              )
            }
          </Media>
        </div>
      </div>
    );
  }
}

export { MenuLeft, menuData };
