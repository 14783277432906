import { SAVE_EVENT_LOGS_FILTER_PARAMS, SAVE_EVENT_LOGS_AGGREGATE} from '../actions/types';

const INIT_STATE = {
  filterParams: {
    pagination: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    searchData: {}
  },
  eventLogsAggregate : {
    failedCount: 0,
    sentCount: 0, 
    inProgressCount: 0
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_EVENT_LOGS_FILTER_PARAMS:
      return {
        ...state, filterParams: { ...action.payload}
      };
      
    case SAVE_EVENT_LOGS_AGGREGATE: 
    return { ...state, eventLogsAggregate: action.payload };
    
    default:
      return state;
  }
};
