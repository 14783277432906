const FO_APP_VERSION = "1.0.17";

const configMap = {
  test: {
    common: {
      USER_SERVICE_URL:
        "http://monnify.frontoffice.development.teamapt.com/api/v1",
      SIFT_APP_ID: "f86db0fd8d",
    },

    live: {
      FRONT_OFFICE_SERVICE_URL:
        "http://monnify.frontoffice.development.teamapt.com/api/v1",
      SDK_BASE_URL: "http://monnify.frontoffice.development.teamapt.com",
      FRONT_OFFICE_SERVICE_URL_V2:
        "http://monnify.frontoffice.playground.teamapt.com/api/v2",
    },
    test: {
      FRONT_OFFICE_SERVICE_URL:
        "http://sandbox.monnify.frontoffice.development.teamapt.com/api/v1",
      SDK_BASE_URL:
        "http://sandbox.monnify.frontoffice.development.teamapt.com",
      FRONT_OFFICE_SERVICE_URL_V2:
        "http://sandbox.monnify.frontoffice.playground.teamapt.com/api/v2",
    },
  },
  dev: {
    common: {
      USER_SERVICE_URL: "http://localhost:9092/api/v1",
      FRONT_OFFICE_SERVICE_URL_V2: "http://localhost:9092/api/v2",
      OFFLINE_PAYMENT_SERVICE_URL: "http://localhost:9110/api/v1",
      OFFLINE_SERVICE_SANDBOX_PROVIDER_AUTH:
        "TU5GWV9PUEZSV1E3RlU1Tlg6WE1IV1lMODhQQjNUTk5YUDlCTUpYQlIyR0NKVUNSQko=",
      SIFT_APP_ID: "f86db0fd8d",
    },

    live: {
      FRONT_OFFICE_SERVICE_URL: "http://localhost:9092/api/v1",
      SDK_BASE_URL: "http://localhost:9092/api/v1",
      FRONT_OFFICE_SERVICE_URL_V2: "http://localhost:9092/api/v2",
      OFFLINE_PAYMENT_SERVICE_URL: "http://localhost:9110/api/v1",
    },
    test: {
      FRONT_OFFICE_SERVICE_URL: "http://localhost:9092/api/v1",
      SDK_BASE_URL: "http://localhost:9092",
      FRONT_OFFICE_SERVICE_URL_V2: "http://localhost:9092/api/v2",
    },
  },
  playground: {
    common: {
      USER_SERVICE_URL: "https://frontoffice.playground.monnify.com/api/v1",
      FRONT_OFFICE_SERVICE_URL_V2:
        "https://frontoffice.playground.monnify.com/api/v2",
      OFFLINE_PAYMENT_SERVICE_URL:
        "https://sandbox-offline-payment.playground.monnify.com/api/v1",
      OFFLINE_SERVICE_SANDBOX_PROVIDER_AUTH:
        "TU5GWV9PUEZHQURNOUYyRkE6NFdVWVU0NlE2TkhZWDlBOFFQQlVSOU1KOVJaWDNKNlA=",
      SIFT_APP_ID: "f86db0fd8d",
    },

    live: {
      FRONT_OFFICE_SERVICE_URL:
        "https://frontoffice.playground.monnify.com/api/v1",
      FRONT_OFFICE_SERVICE_URL_V2:
        "https://frontoffice.playground.monnify.com/api/v2",
      SDK_BASE_URL: "https://api.monnify.com",
      PAYMENT_PAGE_CLIENT_URL: "https://pages.playground.monnify.com",
    },
    test: {
      FRONT_OFFICE_SERVICE_URL:
        "https://frontoffice-service-sandbox.playground.monnify.com/api/v1",
      SDK_BASE_URL: "https://sandbox.monnify.com",
      FRONT_OFFICE_SERVICE_URL_V2:
        "https://frontoffice-service-sandbox.playground.monnify.com/api/v2",
      PAYMENT_PAGE_CLIENT_URL: "https://sandbox-pages-playground.monnify.com",
    },
  },
  production: {
    common: {
      USER_SERVICE_URL: "https://fo.monnify.com/api/v1",
      FRONT_OFFICE_SERVICE_URL_V2: "https://fo.monnify.com/api/v2",
      OFFLINE_PAYMENT_SERVICE_URL:
        "https://sandbox.offline.payment.monnify.com/api/v1",
      OFFLINE_SERVICE_SANDBOX_PROVIDER_AUTH:
        "TU5GWV9PUEgzRURUUFhBOVE6MzEzQ0JSVkxFVUgxWjJHR0w1MlBLQVhYS1JWMDlSNlo=",
      SIFT_APP_ID: "01dfa3c082",
    },

    live: {
      FRONT_OFFICE_SERVICE_URL: "https://fo.monnify.com/api/v1",
      SDK_BASE_URL: "https://api.monnify.com",
      FRONT_OFFICE_SERVICE_URL_V2: "https://fo.monnify.com/api/v2",
      PAYMENT_PAGE_CLIENT_URL: "https://paylink.monnify.com",
    },
    test: {
      FRONT_OFFICE_SERVICE_URL: "https://sandbox.fo.monnify.com/api/v1",
      SDK_BASE_URL: "https://sandbox.monnify.com",
      FRONT_OFFICE_SERVICE_URL_V2: "https://sandbox.fo.monnify.com/api/v2",
      PAYMENT_PAGE_CLIENT_URL: "https://paylink.monnify.com",
    },
  },
  beta: {
    common: {
      USER_SERVICE_URL: "https://beta.fo.monnify.com/api/v1",
      FRONT_OFFICE_SERVICE_URL_V2: "https://beta.fo.monnify.com/api/v2",
      OFFLINE_PAYMENT_SERVICE_URL:
        "https://sandbox.offline.payment.monnify.com/api/v1",
      OFFLINE_SERVICE_SANDBOX_PROVIDER_AUTH:
        "TU5GWV9PUEgzRURUUFhBOVE6MzEzQ0JSVkxFVUgxWjJHR0w1MlBLQVhYS1JWMDlSNlo=",
      SIFT_APP_ID: "01dfa3c082",
    },

    live: {
      FRONT_OFFICE_SERVICE_URL: "https://beta.fo.monnify.com/api/v1",
      SDK_BASE_URL: "https://api.monnify.com",
      FRONT_OFFICE_SERVICE_URL_V2: "https://beta.fo.monnify.com/api/v2",
      PAYMENT_PAGE_CLIENT_URL: "https://payment-pages.beta.monnify.com",
    },
    test: {
      FRONT_OFFICE_SERVICE_URL: "https://sandbox.fo.monnify.com/api/v1",
      SDK_BASE_URL: "https://sandbox.monnify.com",
      FRONT_OFFICE_SERVICE_URL_V2: "https://sandbox.fo.monnify.com/api/v2",
      PAYMENT_PAGE_CLIENT_URL: "https://payment-pages.beta.monnify.com",
    },
  },
  staging: {
    common: {
      USER_SERVICE_URL: "https://fo.staging.monnify.com/api/v1",
      FRONT_OFFICE_SERVICE_URL_V2: "https://fo.staging.monnify.com/api/v2",
      OFFLINE_PAYMENT_SERVICE_URL:
        "https://sandbox.offline.payment.staging.monnify.com/api/v1",
      OFFLINE_SERVICE_SANDBOX_PROVIDER_AUTH:
        "TU5GWV9PUFhGTTRHN1dKUEc6Q1FVTlRGTk1DM0YxVDdEQVZKTUNSR0FFM0pBQUQzTlk=",
      SIFT_APP_ID: "f86db0fd8d",
    },

    live: {
      FRONT_OFFICE_SERVICE_URL: "https://fo.staging.monnify.com/api/v1",
      SDK_BASE_URL: "https://fo.staging.monnify.com",
      FRONT_OFFICE_SERVICE_URL_V2: "https://fo.staging.monnify.com/api/v2",
      PAYMENT_PAGE_CLIENT_URL:
        "https://monnify-payment-pages.staging.monnify.com",
    },
    test: {
      FRONT_OFFICE_SERVICE_URL: "https://sandbox.fo.staging.monnify.com/api/v1",
      SDK_BASE_URL: "https://sandbox.fo.staging.monnify.com",
      FRONT_OFFICE_SERVICE_URL_V2:
        "https://sandbox.fo.staging.monnify.com/api/v2",
      PAYMENT_PAGE_CLIENT_URL:
        "https://monnify-payment-pages.staging.monnify.com",
    },
  },
};

const mfaPaths = [
  "/auth/2fa-login",
  "/auth/verify/mobile",
  "/auth/otp/requery",
  "/auth/otp/resend",
  "/auth/2fa-alternate-resend-otp",
  "/auth/2fa/preference",
  "/auth/otp/send",
  "/auth/update/mobile",
];

const merchantCodeNotRequiredPaths = new Set([
  "/login",
  "/onboarding/signup",
  "/onboarding/validate-email-reference",
  "/onboarding/resend-verification-email",
  "/users/change-password",
  "/users/change-password",
  "/users/create-password",
  "/users/complete-password-reset",
  "/merchant-kyc",
  "/merchant-kyc/add-basic-merchant-details",
  "/merchant-kyc/update-settlement-account",
  "/merchant-kyc/update-business-details",
  "/merchant-kyc/get-business-categories",
  "/business/create",
  "/app-info/get-version",
  // '/disbursement/get-accounts',
  // '/disbursement/account-topup-details?accountNumber',
]);

const nonExactMerchantCodeNotRequiredRoutes = [
  "/login",
  "/auth/select",
  "auth/select",
  "/business",
  "/users/initiate-password-reset",
  "/user-activation",
  "/reset-password",
  "/request-reference",
  "/users/complete-password-reset",
  "/verify-email",
  "/onboarding/validate-email-reference/",
  ...mfaPaths,
];

const liveModeOnlyAPIPaths = new Set([
  "/login",
  "/onboarding/signup",
  "/onboarding/validate-email-reference",
  "/onboarding/resend-verification-email",
  "/users/change-password",
  "/users/change-password",
  "/users/create-password",
  "/users/complete-password-reset",
  "/user-management/authorities",
  "/user-management/merchant-roles",
  "/user-activation/invite",
  "/disbursement/generate-account-statement",
  // '/auth/select',
  // 'auth/select',
  "/business/create",
  ...mfaPaths,
  // '/business/'
]);

const nonExactLiveMatchingRoutes = [
  "/onboarding/validate-email-reference",
  "/merchant-kyc/update-identity-info",
  "dashboard-data",
  "/merchant-kyc/change-business-type",
  "/account-inquiry",
  "/update-company-registration-details",
  "/user-activation",
  "/auth/select",
  "auth/select",
  "/business/",
  "/business",
  "/user-management",
  "/user-activation",
  "/users",
  "/merchant-kyc",
  "/disbursement/generate-account-statement",
  "/disbursement/check-statement-export-status",
  "/disbursement/download-historical-statement",
  "/app-info/get-version",
];

const v2Endpoints = [
  "/disbursement-transactions",
  "/account-balance",
  "/account-topup-details",
  "/account-transactions",
  "/download-disbursement-transactions",
  "/download-account-transactions",
  "merchant/get-contract-list",
  "merchant/update-settlement-account",
  // "/reserved-accounts/"
];

const apiMatcher = [
  {
    name: "Exact Path Matcher",
    process: (path) => {
      return liveModeOnlyAPIPaths.has(path);
    },
  },
  {
    name: "Contains Path Matcher",
    process: (path) => {
      let matched = false;
      for (let i = 0; i < nonExactLiveMatchingRoutes.length; i++) {
        matched = path.includes(nonExactLiveMatchingRoutes[i]);
        if (matched) {
          break;
        }
      }
      return matched;
    },
  },
  {
    name: "Contains v2 path",
    process: (path) => {
      let matched = false;
      for (let i = 0; i < v2Endpoints.length; i++) {
        matched = path.includes(v2Endpoints[i]);
        if (matched) {
          break;
        }
      }
      return matched;
    },
  },
];

const APP_ENV = window.REACT_APP_ENV ? window.REACT_APP_ENV : "playground";

const config =
  configMap[APP_ENV];

const commonConfig = config["common"] || {};

export const getSDKBaseURL = (isLiveMode) => {
  const envConfig = isLiveMode ? config["live"] : config["test"];
  return envConfig.SDK_BASE_URL;
};

const getIsLiveOnly = (path) => {
  if (apiMatcher[1].process(path) || apiMatcher[0].process(path)) {
    return true;
  }
};

export const getBaseUrL = (path, isLiveMode, extra) => {
  const envConfig = isLiveMode ? config["live"] : config["test"];
  const isLiveOnly = getIsLiveOnly(path);

  if ("version" in extra) {
    switch (extra.version) {
      case "v2":
        if (isLiveOnly) return config["live"].FRONT_OFFICE_SERVICE_URL_V2;
        return envConfig.FRONT_OFFICE_SERVICE_URL_V2;
      default:
        if (isLiveOnly) return config["live"].FRONT_OFFICE_SERVICE_URL;
        return envConfig.FRONT_OFFICE_SERVICE_URL;
    }
  }

  if (apiMatcher[2].process(path)) {
    if (isLiveOnly) return config["live"].FRONT_OFFICE_SERVICE_URL_V2;
    return envConfig.FRONT_OFFICE_SERVICE_URL_V2;
  } else {
    if (isLiveOnly) return config["live"].FRONT_OFFICE_SERVICE_URL;
    return envConfig.FRONT_OFFICE_SERVICE_URL;
  }
};

export const getPaymentPageBaseUrl = (isLiveMode) => {
  const envConfig = isLiveMode ? config["live"] : config["test"];

  return envConfig.PAYMENT_PAGE_CLIENT_URL;
};

export const pathDoesNotRequireMerchantCode = (path) => {
  // return !merchantCodeNotRequiredPaths.has(path);

  let doesNotRequireMerchantCode = false;
  for (let i = 0; i < requiresMerchantCodePathMatchers.length; i++) {
    const matcher = requiresMerchantCodePathMatchers[i];
    doesNotRequireMerchantCode = matcher.process(path);
    if (doesNotRequireMerchantCode) {
      break;
    }
  }

  return doesNotRequireMerchantCode;
};

export const isMfaPath = (path) => {
  return !!mfaPaths.filter((mfaPath) => path.includes(mfaPath)).length;
};

const requiresMerchantCodePathMatchers = [
  {
    name: "Exact Path Matcher",
    process: (path) => {
      return merchantCodeNotRequiredPaths.has(path);
    },
  },
  {
    name: "Contains Path Matcher",
    process: (path) => {
      let matched = false;
      for (let i = 0; i < nonExactMerchantCodeNotRequiredRoutes.length; i++) {
        matched = path.includes(nonExactMerchantCodeNotRequiredRoutes[i]);
        if (matched) {
          break;
        }
      }
      return matched;
    },
  },
];

export const getSystemAppVersion = () => {
  return FO_APP_VERSION;
};

export default {
  // Add common config values here
  ...commonConfig,
  APP_ENV,
};
