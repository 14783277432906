import axios from "axios";
import { push } from "react-router-redux";
import { logout, USER_DETAILS_API } from "../actions/auth";
import { MAKE_API_CALL } from "../actions/types";
import { _setFrom } from "../reducers/app";
import {
  getBaseUrL,
  pathDoesNotRequireMerchantCode,
  isMfaPath,
} from "../config";
import notification from "antd/es/notification";
import dataStore from "../dataStore";
import { DATA_STORE_KEYS } from "../dataStore/keys";
import api from "../actions/api";
import { DEFAULT_PRE_VERIFIED_HOME_ROUTE } from "../utils";

const handleSuccess = (response, dispatch, config) => {
  if (!response || response.status !== 202) {
    return;
  }

  if (!response.data || !response.data.responseMessage) {
    return;
  }

  if (config && config.overrideNotificationMessage) {
    return;
  }

  notification.open({
    type: "warning",
    message: response.data.responseMessage,
  });
};

const handleError = (error, dispatch, path) => {
  if (error.response && error.response.status === 401 && error.response.data) {
    // check if the token has expired
    return handleExpiredToken(dispatch, error);
  } else if (
    (error.response && error.response.status === 401) ||
    (!error.response && USER_DETAILS_API === path)
  ) {
    //handle user no access to resource
    dispatch(logout());
    dispatch(_setFrom(""));
    return Promise.reject(error);
  } else if (error.response && error.response.status === 403) {
    const merchantKyc = dataStore.get(DATA_STORE_KEYS.KYC_STATUS);
    if (merchantKyc === "PENDING_KYC_VERIFICATION") {
      dispatch(push(DEFAULT_PRE_VERIFIED_HOME_ROUTE));
    } else if(!error.response.data?.responseMessage.includes("OTP")){
      dispatch(push("/dashboard"));
    }
    notification.open({
      type: "error",
      message: error.response.data.responseMessage,
    });

    return Promise.reject(error);
  } else if (error.response && error.response.status === 500) {
    let description = "";
    if (error.response.data) {
      description = error.response.data.responseMessage;
    }

    notification.open({
      type: "error",
      message: "System error",
      description: description,
    });

    return Promise.reject(error);
  } else if (!error.response) {
    // run user details to confirm token is still valid
    // dispatch(
    //   api.get(
    //     USER_DETAILS_API,
    //     {},
    //     function () {},
    //     function () {}
    //   )
    // );
  }
};

const handleExpiredToken = (dispatch, error) => {
  dispatch(logout());
  dispatch(_setFrom(""));
  return Promise.reject(error);
};

const handleApiCall = async (
  isLiveMode,
  dispatch,
  process,
  success,
  failure,
  skipAuthHeader,
  path,
  netWorkConfig,
) => {
  try {
    const baseUrl = getBaseUrL(path, isLiveMode, netWorkConfig);

    let axiosConfig = { baseURL: baseUrl };

    if (
      "shouldTrackUpload" in netWorkConfig &&
      netWorkConfig.shouldTrackUpload
    ) {
      axiosConfig["onUploadProgress"] = netWorkConfig.onUploadProgress;
    }

    const instance = axios.create(axiosConfig);

    const merchantBusiness = dataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);

    if (!pathDoesNotRequireMerchantCode(path) && !merchantBusiness)
      throw new Error("No merchant code specified");

    //set up the request interceptor here
    instance.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        if (skipAuthHeader) {
          return config;
        }

        if (isMfaPath(path)) {
          // include code-verifier and auth-code in header if path is included in mfaPaths
          const codeVerifier = dataStore.get(DATA_STORE_KEYS.CODE_VERIFIER);
          const authCode = dataStore.get(DATA_STORE_KEYS.AUTH_CODE);
          if (codeVerifier && authCode) {
            config.headers = {
              ...config.headers,
              "auth-code": authCode,
              "code-verifier": codeVerifier,
            };
          }

          return config;
        }

        const authorization = dataStore.get(DATA_STORE_KEYS.ACCESS_TOKEN);
        if (!authorization) {
          dispatch(logout());
          return Promise.reject("user not logged in");
        }

        if (merchantBusiness) {
          config.headers = {
            ...config.headers,
            merchantCode: merchantBusiness.merchantCode,
          };
        }

        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${authorization}`,
        };

        config = { ...netWorkConfig, ...config };

        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    //set up the response interceptor

    // Add a response interceptor
    instance.interceptors.response.use(
      function (response) {
        // Do something with response data
        handleSuccess(response, dispatch, netWorkConfig);
        return response;
      },
      function (error) {
        // Do something with response error
        handleError(error, dispatch, path);
        return Promise.reject(error);
      },
    );

    //make call here
    const response = await process(instance);
    success(response);
  } catch (error) {
    failure(error);
  }
};

const doDelete = (
  isLiveMode,
  dispatch,
  path,
  data = {},
  successHandler,
  errorHandler,
  skipAuthHeader = false,
  config = {},
) => {
  handleApiCall(
    isLiveMode,
    dispatch,
    (axios) => axios.delete(path, { data }),
    successHandler,
    errorHandler,
    skipAuthHeader,
    path,
    config,
  );
};

const doPatch = (
  isLiveMode,
  dispatch,
  path,
  data = {},
  successHandler,
  errorHandler,
  skipAuthHeader = false,
  config = {},
) => {
  handleApiCall(
    isLiveMode,
    dispatch,
    (axios) => axios.patch(path, data, config),
    successHandler,
    errorHandler,
    skipAuthHeader,
    path,
    config,
  );
};

const doPut = (
  isLiveMode,
  dispatch,
  path,
  data = {},
  successHandler,
  errorHandler,
  skipAuthHeader = false,
  config = {},
) => {
  handleApiCall(
    isLiveMode,
    dispatch,
    (axios) => axios.put(path, data, config),
    successHandler,
    errorHandler,
    skipAuthHeader,
    path,
    config,
  );
};

const doGet = (
  isLiveMode,
  dispatch,
  path,
  params = {},
  successHandler,
  errorHandler,
  skipAuthHeader = false,
  config = {},
) => {
  handleApiCall(
    isLiveMode,
    dispatch,
    (axios) => axios.get(path, { params }),
    successHandler,
    errorHandler,
    skipAuthHeader,
    path,
    config,
  );
};

const doPost = (
  isLiveMode,
  dispatch,
  path,
  data = {},
  successHandler,
  errorHandler,
  skipAuthHeader = false,
  config = {},
) => {
  handleApiCall(
    isLiveMode,
    dispatch,
    (axios) => axios.post(path, data, config),
    successHandler,
    errorHandler,
    skipAuthHeader,
    path,
    config,
  );
};

export default ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    if (action.type !== MAKE_API_CALL) {
      return next(action);
    }

    const { isLiveMode } = getState().environmentAware;

    const {
      method,
      path,
      data,
      params,
      successHandler,
      errorHandler,
      skipAuthHeader,
      config,
    } = action.payload;

    switch (method) {
      case "put":
        await doPut(
          isLiveMode,
          dispatch,
          path,
          data,
          successHandler,
          errorHandler,
          skipAuthHeader,
          config,
        );
        break;
      case "post":
        await doPost(
          isLiveMode,
          dispatch,
          path,
          data,
          successHandler,
          errorHandler,
          skipAuthHeader,
          config,
        );
        break;
      case "get":
        await doGet(
          isLiveMode,
          dispatch,
          path,
          params,
          successHandler,
          errorHandler,
          skipAuthHeader,
          config,
        );
        break;
      case "patch":
        await doPatch(
          isLiveMode,
          dispatch,
          path,
          data,
          successHandler,
          errorHandler,
          skipAuthHeader,
          config,
        );
        break;
      case "delete":
        await doDelete(
          isLiveMode,
          dispatch,
          path,
          data,
          successHandler,
          errorHandler,
          skipAuthHeader,
          config,
        );
        break;
      default:
        return next(action);
    }
  };
