import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { connectRouter } from "connected-react-router";
import { pendingTasksReducer } from "react-redux-spinner";
import history from "./../config/history";
import app from "./app";
import appEnvironment from "./appEnvironment";
import formError from "./formError";
import formMessage from "./formMessage";
import transactions from "./transactionsReducer";
import reservedAccounts from "./reservedAccountsReducer";
import contracts from "./contractsReducer";
import authentications from "./authenticationReducer";
import merchant from "./merchantReducer";
import subAccounts from "./subAccountsReducer";
import invoices from "./invoiceReducer";
import onboarding from "./onboardingReducer";
import dashboardReducer from "./dashboardReducer";
import payoutReducer from "./payoutReducer";
import tills from "./tills";
import disbursement from "./disbursementReducer";
import environmentAware from "../../modules/util/EnvironmentAware/reducer/index";
import teams from "./teamsReducer";
import business from "./businessesReducer";
import { PURGE_STATE, CLEAN_STATE } from "../actions/types";
import wallet from "./walletReducer";
import addressReducer from "./addressReducer";
import offlinePaymentReducer from "./offlinePaymentReducer";
import paycodeReducer from "./paycodeReducer";
import refundReducer from "./refundReducer";
import applicationInfo from "./applicationInfo";
import paymentPage from "./paymentPage";
import eventLogs from "./eventLogs";
import workflow from "./workflowReducer";
import mfa from "./mfa";

const appReducer = combineReducers({
  routing: routerReducer,
  router: connectRouter(history),
  pendingTasks: pendingTasksReducer,
  app,
  appEnvironment,
  formError,
  formMessage,
  transactions,
  reservedAccounts,
  contracts,
  authentications,
  merchant,
  subAccounts,
  dashboardReducer,
  payoutReducer,
  invoices,
  onboarding,
  environmentAware,
  tills,
  disbursement,
  teams,
  business,
  wallet,
  addressReducer,
  offlinePaymentReducer,
  paycodeReducer,
  refundReducer,
  applicationInfo,
  paymentPage,
  eventLogs,
  workflow,
  mfa,
});

const rootReducer = (state, action) => {
  if (action.type === PURGE_STATE || action.type === CLEAN_STATE) {
    const {
      routing,
      router,
      pendingTasks,
      app,
      appEnvironment,
      formError,
      formMessage,
      business,
      onboarding,
    } = state;
    switch (action.type) {
      case PURGE_STATE:
        state = {
          routing,
          router,
          pendingTasks,
          app,
          appEnvironment,
          formError,
          formMessage,
          business,
        };
        break;
      case CLEAN_STATE:
        state = { routing, router, pendingTasks, app };
        break;
    }
  }
  return appReducer(state, action);
};

export default rootReducer;
