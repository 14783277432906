import React, { Component } from "react";
import Optional from "../Optional";
import Util from "../../../utils";
import MonnifyIcon from "../../../icons";
import "./style.scss";

class AuthenticationFormAlert extends Component {
  render() {
    let icon = "DANGER_MAIL_ICON";
    switch (this.props.type) {
      case "success":
        icon = "SUCCESS_MAIL_ICON";
        break;
      case "danger":
      default:
        icon = "DANGER_MAIL_ICON";
    }
    return (
      <div className={`authentication-form-error-alert ${this.props.type}`}>
        <MonnifyIcon type={icon} />
        <div>
          <div className="title">{this.props.title}</div>
          <div className="description">{this.props.description}</div>
        </div>
      </div>
    );
  }
}

export default AuthenticationFormAlert;
