import React from "react";
import { MenuLeft } from "./MenuLeft";
import { MenuTop } from "./MenuTop";
import { connect } from "react-redux";
import { setLayoutState } from "../../../../common/reducers/app";
import "./style.scss";
import "rc-drawer/assets/index.css";

const mapStateToProps = ({ app }, props) => ({
  open: app.layoutState.menuMobileOpened,
  isMenuTop: app.layoutState.isMenuTop,
});

@connect(mapStateToProps)
class AppMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      isMenuTop: props.isMenuTop,
    };
  }

  UNSAFE_componentWillReceiveProps({ open, isMenuTop }) {
    this.setState({ open, isMenuTop });
  }

  toggleOpen = () => {
    const { dispatch } = this.props;
    const { open } = this.state;
    dispatch(setLayoutState({ menuMobileOpened: !open }));
  };

  render() {
    const { isMobile } = this.props;
    const props = this.props;
    const { isMenuTop } = this.state;

    let menu;
    if (isMenuTop) {
      menu = <MenuTop {...props} />;
    } else {
      menu = <MenuLeft {...props} />;
    }

    if (isMobile) {
      // menu = <DrawerMenu getContainer={null} level={null} open={open} onMaskClick={this.toggleOpen}
      //                    onHandleClick={this.toggleOpen}>
      //     <MenuLeft {...props} />
      // </DrawerMenu>;
      menu = <MenuLeft {...props} />;
    }

    return menu;
  }
}

export default AppMenu;
