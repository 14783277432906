import {
  UPDATE_TILLS_LOADING,
  SAVE_TILLS_DATA,
  UPDATE_TILL_DETAIL_LOADING,
  SAVE_TILL_DETAIL_DATA,
  UPDATE_DEALLOCATE_TILL_ACCOUNT_PROCESSING,
  GET_ALL_TILLS,
  TILL_ACCOUNTS_LOADING,
  SAVE_TILL_TRANSACTION_DATA,
  SAVE_USER_DEVICES,
  SAVE_TILL_SUMMARY,
  SAVE_TILL_DEVICES,
  SAVE_DEVICE_DETAIL,
  DEVICE_DETAIL_LOADING,
  UPDATE_PROVIDERS_LOADING,
  UPDATE_PROVIDERS,
} from "./../actions/types";
import _ from "lodash";

const INIT_STATE = {
  data: {
    test: [],
    live: [],
  },
  tillsData: {},
  lastPageRequest: {},
  pagination: {
    test: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    live: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },
  loading: false,
  tillDetailLoading: false,
  tillDetailData: [],
  tillTransactionData: [],
  tillTransactionPagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
    pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
  },
  processingTillAccountDeallocation: false,
  tills: [],
  userDevices: [],
  userDevicesPagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
    pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
  },
  tillSummary: {
    totalAmountPaid: 0,
    totalTransactionsCount: 0,
    totalLinkedTills: 0,
  },
  tillDevices: {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },
  deviceDetail: {},
  deviceDetailLoading: false,
  providers: {
    test: [],
    live: [],
  },
  providersLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PROVIDERS_LOADING:
      return { ...state, providersLoading: action.payload };
    case UPDATE_PROVIDERS:
      let providers = _.cloneDeep(state.providers);

      providers[action.payload.environment] = action.payload.data;

      return { ...state, providers };
    case SAVE_TILLS_DATA:
      let data = _.cloneDeep(state.data);
      data[action.payload.environment] = action.payload.data.data;

      let pagination = _.cloneDeep(state.pagination);
      pagination[action.payload.environment] = action.payload.data.pagination;

      return {
        ...state,
        data,
        pagination,
        loading: !!action.payload.loading,
      };

    case TILL_ACCOUNTS_LOADING:
      return { ...state, loading: action.payload };

    case UPDATE_TILLS_LOADING:
      return { ...state, loading: !!action.payload };
    case UPDATE_TILL_DETAIL_LOADING:
      return { ...state, tillDetailLoading: action.payload };
    case DEVICE_DETAIL_LOADING:
      return { ...state, deviceDetailLoading: action.payload };
    case SAVE_TILL_DETAIL_DATA:
      return { ...state, tillsData: action.payload.data };
    case SAVE_TILL_TRANSACTION_DATA:
      return {
        ...state,
        tillTransactionData: action.payload.data,
        tillTransactionPagination: action.payload.pagination,
        loading: !!action.payload.loading,
      };
    case SAVE_USER_DEVICES:
      return {
        ...state,
        userDevices: action.payload.data,
        userDevicesPagination: action.payload.pagination,
        loading: !!action.payload.loading,
      };
    case SAVE_TILL_SUMMARY:
      return {
        ...state,
        tillSummary: action.payload,
      };
    case SAVE_DEVICE_DETAIL:
      return {
        ...state,
        deviceDetail: action.payload,
      };
    case UPDATE_DEALLOCATE_TILL_ACCOUNT_PROCESSING:
      return { ...state, processingTillAccountDeallocation: !!action.payload };
    case SAVE_TILL_DEVICES:
      return {
        ...state,
        tillDevices: {
          ...action.payload,
          pagination: {
            ...state.tillDevices.pagination,
            ...(action.payload.payload || {}),
          },
        },
      };
    default:
      return state;
  }
};
