import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setUpdatingContent} from "./../../../reducers/app";
import {isEmpty} from "lodash";
import "./style.scss";
import BreadCrumb from "../Breadcrumb";

const mapStateToProps = (state, props) => ({
    isUpdatingContent: state.app.isUpdatingContent
});

@connect(mapStateToProps)
class AppContent extends React.Component {
    static contextTypes = {
        getContentBuffer: PropTypes.func
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !(this.props.isUpdatingContent && !nextProps.isUpdatingContent);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isUpdatingContent, dispatch} = this.props;
        if (isUpdatingContent) {
            dispatch(setUpdatingContent(false));
        }
    }



    render() {
        const {getContentBuffer} = this.context;
        const {pathName, content, breadcrumb} = getContentBuffer();

        return isEmpty(content) ? (<div className="utils__loadingPage"/>) : (
            <div className="utils__content">
                <div className="page-head">
                    <div className="page-header-title">{pathName}</div>
                    <BreadCrumb data={breadcrumb}/>
                </div>
                <div className = "page-body">
                {content}
                </div>
                


                <div className="clearfix"></div>
            </div>
        );
    }
}

export default AppContent;
