import {
  SAVE_TRANSACTION_DATA,
  UPDATE_TRANSACTIONS_LOADING,
  SAVE_TRANSACTION_DETAILS,
  SAVE_RECENT_TRANSACTION_DATA,
  SAVE_TRANSACTION_LIST_DATA,
  IS_TRANSACTIONS_FILTER,
  SAVE_REFUND_ELIGIBLE_TRANSACTIONS,
  CLEAR_REFUND_ELIGIBLE_TRANSACTIONS,
  SAVE_TRANSACTION_BENEFICIARIES,
  SAVE_TRANSACTION_REFUNDS,
  RECEIPT_DOWNLOADED,
  SAVE_TRANSACTION_NOTIFICATION_REPORT,
  SAVE_TRANSACTION_FILTER_PARAMS
} from "./../actions/types";
import _ from "lodash";

const INIT_STATE = {
  data: {
    live: [],
    test: [],
  },
  transactionData: {
    paymentSessionDTOs: []
  },
  lastPageRequest: {},
  recentTransactions: {
    live: [],
    test: [],
  },
  refundEligibleTransactions: {
    live: [],
    test: [],
  },
  pagination: {
    live: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    test: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },
  loading: false,
  isTransactionsFilter: { test: false, live: false },
  transactionBeneficiary: [],
  transactionRefunds: {},
  receiptBlob: null,
  notificationReport: {},
  filterParams: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_TRANSACTION_DATA:
      let data = _.cloneDeep(state.data);
      data[action.payload.environment] = action.payload.data.data;

      let pagination = _.cloneDeep(state.pagination);
      pagination[action.payload.environment] = action.payload.data.pagination;
      return {
        ...state,
        data: data,
        pagination: pagination,
        loading: !!action.payload.loading,
      };

    case SAVE_TRANSACTION_LIST_DATA:
      let newData = _.cloneDeep(state.data);
      newData[action.payload.environment] = action.payload.data;

      return {
        ...state,
        data: newData,
        loading: !!action.payload.loading,
      };

    case UPDATE_TRANSACTIONS_LOADING:
      return { ...state, loading: !!action.payload };

    case CLEAR_REFUND_ELIGIBLE_TRANSACTIONS:
      return { ...state, refundEligibleTransactions: [] };

    case SAVE_TRANSACTION_DETAILS:
      return { ...state, transactionData: action.payload };

    case SAVE_REFUND_ELIGIBLE_TRANSACTIONS:
      let refundEligibleTransactions = _.cloneDeep(state.refundEligibleTransactions);
      refundEligibleTransactions[action.payload.environment] = action.payload.data;
      return {
        ...state,
        refundEligibleTransactions,
      };
    case SAVE_RECENT_TRANSACTION_DATA:
      let recentTransactions = _.cloneDeep(state.recentTransactions);
      recentTransactions[action.payload.environment] = action.payload.data;
      return {
        ...state,
        recentTransactions,
      };

    case IS_TRANSACTIONS_FILTER: {
      let isTransactionsFilter = _.cloneDeep(state.isTransactionsFilter);
      isTransactionsFilter[action.payload.environment] = action.payload;
      return {
        ...state,
        isTransactionsFilter,
      };
    }
    case SAVE_TRANSACTION_BENEFICIARIES:
      return {...state, transactionBeneficiary: action.payload}

    case SAVE_TRANSACTION_REFUNDS:
      return {...state, transactionRefunds: action.payload}
    case RECEIPT_DOWNLOADED:
      return {...state, receiptBlob: action.payload}
    case SAVE_TRANSACTION_NOTIFICATION_REPORT:
        return {...state, notificationReport: action.payload}
    case SAVE_TRANSACTION_FILTER_PARAMS:
        return {...state, filterParams: action.payload}
    default:
      return state;
  }
};

// action, data, getState
