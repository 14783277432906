import {
  SAVE_PAYCODES,
  SAVE_BULK_PAYCODES,
  SAVE_PAYCODE_DETAILS,
  SAVE_PAYCODE_ACCOUNT_SUMMARY,
  UPDATE_PAYCODE_LOADING,
  UPDATE_BULK_PAYCODE_LOADING,
  IS_PAYCODE_FILTER,
  SAVE_PAYCODE_APPROVALS,
  UPDATE_PAYCODE_APPROVAL_LOADING,
  SAVE_PAYCODE_APPROVAL_DETAILS,
  SAVE_PAYCODE_APPROVAL_ACCOUNT_SUMMARY,
  SAVE_BULK_PAYCODE_DETAILS,
  SAVE_BULK_PAYCODE_ACCOUNT_SUMMARY,
} from "../actions/types";
import _ from "lodash";

const INIT_STATE = {
  data: {
    test: [],
    live: [],
  },
  paycodeSummary: {
    test: {},
    live: {},
  },
  bulkPaycodeSummary: {
    test: {},
    live: {},
  },
  paycodeApprovalSummary: {
    test: {},
    live: {},
  },
  paycodeDetails: {},
  bulkPaycodeDetails: {},
  paycodeApprovalDetails: {},
  loading: false,
  isFilter: {
    test: false,
    live: false,
  },

  bulkPaycodes: {
    test: [],
    live: [],
  },
  bulkPaycodesLoading: false,
  bulkPaycodesPagination: {
    live: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    test: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },
  paycodeApprovals: {
    test: [],
    live: [],
  },
  paycodeApprovalsLoading: false,
  paycodeApprovalsPagination: {
    live: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    test: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },

  pagination: {
    live: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    test: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },
};

export default (state = INIT_STATE, action) => {
  let merchantProductDetail;

  switch (action.type) {
    case SAVE_PAYCODES:
      let data = _.cloneDeep(state.data);
      data[action.payload.environment] = action.payload.data.data;

      let pagination = state.pagination;
      pagination[action.payload.environment] = action.payload.data.pagination;

      return {
        ...state,
        data,
        pagination: pagination,
        loading: !!action.payload.loading,
      };
    case SAVE_BULK_PAYCODES:
      let bulkPaycodes = _.cloneDeep(state.bulkPaycodes);
      bulkPaycodes[action.payload.environment] = action.payload.data.data;

      let bulkPaycodesPagination = state.bulkPaycodesPagination;
      bulkPaycodesPagination[action.payload.environment] =
        action.payload.data.pagination;

      return {
        ...state,
        bulkPaycodes,
        bulkPaycodesPagination,
        bulkPaycodesLoading: !!action.payload.loading,
      };
    case SAVE_PAYCODE_APPROVALS:
      let paycodeApprovals = _.cloneDeep(state.paycodeApprovals);
      paycodeApprovals[action.payload.environment] = action.payload.data.data;

      let paycodeApprovalsPagination = state.paycodeApprovalsPagination;
      paycodeApprovalsPagination[action.payload.environment] =
        action.payload.data.pagination;

      return {
        ...state,
        paycodeApprovals,
        paycodeApprovalsPagination,
        paycodeApprovalsLoading: !!action.payload.loading,
      };

    case UPDATE_PAYCODE_LOADING:
      return { ...state, loading: !!action.payload };
    case UPDATE_BULK_PAYCODE_LOADING:
      return { ...state, bulkPaycodesLoading: !!action.payload };
    case UPDATE_PAYCODE_APPROVAL_LOADING:
      return { ...state, paycodeApprovalsLoading: !!action.payload };

    case SAVE_PAYCODE_DETAILS:
      return {
        ...state,
        paycodeDetails: { paycodeTransactionDTO: action.payload },
      };
    case SAVE_BULK_PAYCODE_DETAILS:
      return { ...state, bulkPaycodeDetails: action.payload };
    case SAVE_PAYCODE_APPROVAL_DETAILS:
      return { ...state, paycodeApprovalDetails: action.payload };

    case SAVE_BULK_PAYCODE_ACCOUNT_SUMMARY:
      let bulkPaycodeSummary = _.cloneDeep(state.bulkPaycodeSummary);
      bulkPaycodeSummary[action.payload.environment] = action.payload.data;
      return { ...state, bulkPaycodeSummary };
    case SAVE_PAYCODE_ACCOUNT_SUMMARY:
      let paycodeSummary = _.cloneDeep(state.paycodeSummary);
      paycodeSummary[action.payload.environment] = action.payload.data;
      return { ...state, paycodeSummary };
    case SAVE_PAYCODE_APPROVAL_ACCOUNT_SUMMARY:
      let paycodeApprovalSummary = _.cloneDeep(state.paycodeApprovalSummary);
      paycodeApprovalSummary[action.payload.environment] = action.payload.data;
      return { ...state, paycodeApprovalSummary };

    case IS_PAYCODE_FILTER: {
      let isFilter = _.cloneDeep(state.isFilter);
      isFilter[action.payload.environment] = action.payload;
      return {
        ...state,
        isFilter,
      };
    }

    default:
      return state;
  }
};
