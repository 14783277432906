import { SAVE_FO_APP_VERSION } from "../actions/types";

const INITIAL_STATE = {
  appVersion: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_FO_APP_VERSION:
      return { ...state, appVersion: action.payload };

    default:
      return state;
  }
}
