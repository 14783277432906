class OtpUtil {
  static maskUserInfo(inputString) {
    const visibleCharsLen = 4;
    const inputStringLen = inputString.length;

    if (typeof inputString !== "string" || inputStringLen < visibleCharsLen) {
      return inputString;
    }
    const isEmail = inputString.includes("@");
    if (isEmail || isNaN(inputString)) {
      let separator = isEmail ? "@" : "";

      const [username, domain = ""] = inputString.split("@");
      const usernameLen = username.length;

      if (usernameLen <= 2) {
        return inputString;
      }

      const maskLength = Math.floor(usernameLen / 2);
      const visibleCharsCount = Math.ceil((usernameLen - maskLength) / 2);
      return (
        username.slice(0, visibleCharsCount) +
        "*".repeat(maskLength) +
        username.slice(-visibleCharsCount) +
        separator +
        domain
      );
    }

    return "*".repeat(visibleCharsLen) + inputString.slice(-visibleCharsLen);
  }

  static getError(otpError, errors = OTP_ERROR_MESSAGES) {
    const key =
      Object.keys(errors).find((val) =>
        otpError?.toLowerCase()?.includes(val),
      ) || "default";
    return errors[key] || {};
  }

  static getAlternateDeliveryType(sentViaEmail) {
    return sentViaEmail ? OTP_DELIVERY_TYPE.SMS : OTP_DELIVERY_TYPE.EMAIL;
  }

  static transformStatus(status = "") {
    status = status.toLowerCase();
    if (status.includes("invalid")) {
      return "INVALID_OTP";
    }
    if (status.includes("expire")) {
      return "EXPIRED_OTP";
    }
    return status.replace(" ", "_").toUpperCase();
  }
}

export const OTP_INTENT = {
  TWOFA_LOGIN: "TWOFA_LOGIN",
  VERIFY_MOBILE: "VERIFY_MOBILE",
  TRANSACTION: "TRANSACTION",
  WORKFLOW: "WORKFLOW",
  ACCESS_CONTENT: "ACCESS_CONTENT",
};

export const OTP_DELIVERY_TYPE = {
  EMAIL: "EMAIL",
  SMS: "SMS",
};

export const OTP_ERROR_MESSAGES = {
  invalid: {
    title: "Invalid OTP",
    message: "The OTP entered was invalid, please try again",
  },
  expired: {
    title: "Expired OTP",
    message: "The OTP entered has expired, please try again",
  },
  default: {
    title: "Unknown Error",
    message: "There was an issue processing your request, please try again",
  },
};

export const OTP_STATUS = {
  SENT: "SUCCESS",
  PENDING: "PENDING",
  FAILED: "FAILED",
  ERROR: "ERROR",
  EMAIL_SUCCESS: "SUCCESSFUL"
};

export const statusMapForOtpUI = {
  INVALID_OTP: {
    icon: { type: "error" },
    ...OTP_ERROR_MESSAGES.invalid,
  },
  EXPIRED_OTP: {
    icon: { type: "error" },
    ...OTP_ERROR_MESSAGES.expired,
  },
  DEFAULT: {
    icon: { type: "error" },
    ...OTP_ERROR_MESSAGES.default,
  },
};

export default OtpUtil;
