import styled from 'styled-components';
import { Radio } from 'antd';

const OnboardingStyle = styled.main`
  background: #ffffff;
  border-radius: 5px;
  // padding: 80px 70px;
  width: 85%;
  margin: 0 auto;



  @media only screen and (max-width: 1024px) {
    width: 80%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 30px 20px;
  }

  .onboarding-body{
    width: 80%;
    max-width: 500px;
    margin: 0 auto;
    padding: 34px 0;

    @media only screen and (max-width: 768px) {
      width: 50%;
    }
  

    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }



  .onboard-form {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ant-form-item {
    margin-bottom: 15px;
    padding: 20px 15px !important;
  }

  .ant-form-item-control-wrapper {
    margin-top: 0;
  }

  input {
    &::placeholder {
      font-size: 0.8125rem;
      font-weight: normal;
    }
  }

  .header {
    font-weight: bold;
    color: #2e384d;

    &.large {
      font-size: 24px;
    }

    &.primary {
      font-size: 1.125rem;
      margin-bottom: 20px;
    }

    &.secondary {
      font-size: 1rem;
      margin-top: 10px 0;
    }

    &.tertiary {
      font-size: 0.8125rem;
      margin: 0;
      line-height: 22px;
    }
  }

  .body-text {
    margin: 0;
    font-size: 0.8125rem;
    color: #8798ad;
  }

  .radio-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .btn-wrap {
    width: 100%;
    margin-top: 20px;
    align-self: center;

    .ant-btn {
      width: 100%;
      font-weight: bold;
      min-height: 64px;
      font-size: 0.9375rem;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 0;
    }
  }

  .radio-label {
    margin-left: 15px;
  }

  .ant-form-item.radio-group-wrap {
    border: none !important;
    padding: 0 !important;
  }

  .ant-form-item label {
    display: flex;
  }
`;

const RadioStyle = styled(Radio)`
  display: flex;
  align-items: center;

  span:nth-child(2) {
    padding: 0 9px;
  }


  .ant-radio-checked{
    &::after{
      height: 26px;
    }
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #f2c94c;
    &::after{
      background-color: #FA9F17;
      top: 7px;
      left: 7px;
      display: table;
      width: 10px;
      height: 10px;
    }
  }

  .ant-radio {
    &:hover {
      border-color: #f2c94c !important;
    }
  }

  .ant-radio-inner {
    width: 26px;
    height: 26px;
    /* border: #f2c94c; */

    &::after {
      background-color: #f2c94c;
      top: 4px;
      left: 4px;
      display: table;
      width: 16px;
      height: 16px;
    }
  }

  br {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    br {
      display: initial;
    }
  }
`;

export { OnboardingStyle, RadioStyle };
