import {
  SAVE_BENEFICIARY_PAYOUT,
  SAVE_PAYOUT_TRANSACTION,
  UPDATE_PAYOUT_LOADING,
  UPDATE_PAYOUT_TRANSACTION_LOADING,
  IS_PAYOUT_FILTER
} from './../actions/types';
import _ from 'lodash';

const INIT_STATE = {
  data: {
    test: [],
    live: []
  },
  lastPageRequest: {},
  pagination: {
    test: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ['10', '20', '30', '40', '50', '100']
    },
    live: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ['10', '20', '30', '40', '50', '100']
    }
  },
  loading: false,
  payoutTransaction: {
    test: [],
    live: []
  },
  payoutPagination: {
    test: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ['10', '20', '30', '40', '50', '100']
    },
    live: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ['10', '20', '30', '40', '50', '100']
    }
  },
  payoutLoading: false,
  payoutTransactionLoading: false,
  isPayoutFilter: { test: false, live: false }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_BENEFICIARY_PAYOUT:
      let data = _.cloneDeep(state.data);
      data[action.payload.environment] = action.payload.data.data;

      let pagination = _.cloneDeep(state.pagination);
      pagination[action.payload.environment] = action.payload.data.pagination;

      return {
        ...state,
        data: data,
        pagination: pagination,
        loading: !!action.payload.loading
      };
    case SAVE_PAYOUT_TRANSACTION:
      let payoutTransaction = _.cloneDeep(state.payoutTransaction);
      payoutTransaction[action.payload.environment] = action.payload.data.data;

      let payoutPagination = _.cloneDeep(state.pagination);
      payoutPagination[action.payload.environment] =
        action.payload.data.pagination;

      return {
        ...state,
        payoutTransaction,
        payoutPagination,
        payoutTransactionLoading: !!action.payload.loading
      };

    case UPDATE_PAYOUT_LOADING:
      return {
        ...state,
        loading: !!action.payload
      };
    case UPDATE_PAYOUT_TRANSACTION_LOADING:
      return {
        ...state,
        payoutTransactionLoading: !!action.payload
      };

    case IS_PAYOUT_FILTER: {
      let isPayoutFilter = _.cloneDeep(state.isPayoutFilter);
      isPayoutFilter[action.payload.environment] = action.payload;
      return {
        ...state,
        isPayoutFilter
      };
    }
    default:
      return state;
  }
};
