import {
  SAVE_DASHBOARD_GRAPH_DATA,
  SAVE_DASHBOARD_STATIC_DATA,
  UPDATE_DASHBOARD_GRAPH_LOADING,
  UPDATE_DASHBOARD_STATIC_LOADING,
  SAVE_CURRENT_FILTER
} from './../actions/types';

import _ from 'lodash'

const INIT_STATE = {
  data: {
    live: null,
    test: null
  },
  loading: false,
  staticData: {
    test: false,
    live: false
  },
  currentFilter: 'TODAY'
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_CURRENT_FILTER:
      return { ...state, currentFilter: action.payload };
    case SAVE_DASHBOARD_GRAPH_DATA:
      let recentDashboardData = _.cloneDeep(state.data);
      recentDashboardData[action.payload.environment] = action.payload.data;
      return {
        ...state,
        data: recentDashboardData
      };
    case SAVE_DASHBOARD_STATIC_DATA:
      let recentDashboardStaticData = _.cloneDeep(state.staticData);
      recentDashboardStaticData[action.payload.environment] =
        action.payload.data;
      return {
        ...state,
        staticData: recentDashboardStaticData
      };
    case UPDATE_DASHBOARD_GRAPH_LOADING:
      return {
        ...state,
        loading: !!action.payload
      };
    case UPDATE_DASHBOARD_STATIC_LOADING:
      return {
        ...state,
        staticLoading: !!action.payload
      };

    default:
      return state;
  }
};
