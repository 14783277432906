import api from '../api';
import { push } from 'react-router-redux';
import { showError } from '../index';
import moment from 'moment';
import {
  SAVE_DASHBOARD_STATIC_DATA,
  SAVE_DASHBOARD_GRAPH_DATA,
  UPDATE_DASHBOARD_GRAPH_LOADING,
  UPDATE_DASHBOARD_STATIC_LOADING,
  SAVE_CURRENT_FILTER
} from '../types';
import { buildEnvAwareData } from '../../../modules/util/EnvironmentAware/action/index';

const BASE_DASHBOARD_API = '/dashboard-view';
const DASHBOARD_GRAPH_API = '/dashboard-graph-data';
const DASHBOARD_STATIC_API = '/dashboard-static-data';

export const moveToDashboard = () => dispatch => {
  dispatch(push("/dashboard"));
};


export const saveDashboardGraphDetails = data => {
  return {
    type: SAVE_DASHBOARD_GRAPH_DATA,
    payload: data
  };
};
export const saveDashboardStaticDetails = data => {
  return {
    type: SAVE_DASHBOARD_STATIC_DATA,
    payload: data
  };
};

export const updateDashboardGraphLoading = isLoading => {
  return {
    type: UPDATE_DASHBOARD_GRAPH_LOADING,
    payload: isLoading
  };
};
export const updateDashboardStaticLoading = isLoading => {
  return {
    type: UPDATE_DASHBOARD_STATIC_LOADING,
    payload: isLoading
  };
};

let to = moment()
  .endOf('day')
  .valueOf();
let from = moment(to);

const defaultFilter = {
  all_time: false,
  from: from.startOf('day').valueOf(),
  to: moment()
    .endOf('day')
    .valueOf()
};

export const saveCurrentFilter = currentFilter => {
  return {
    type: SAVE_CURRENT_FILTER,
    payload: currentFilter
  };
};

export const filterGraph = (filter, customTo, customFrom) => {
  let to = moment()
    .endOf('day')
    .valueOf();
  let from = moment(to);
  let all_time = false;

  switch (filter) {
    case 'TODAY':
      from = from.startOf('day').valueOf(); // 15 hours ago
      to = moment()
        .endOf('day')
        .valueOf();
      break;
    case '7_DAYS':
      from = from
        .subtract(7, 'days')
        .startOf('day')
        .valueOf();
      break;
    case '30_DAYS':
      from = from
        .subtract(30, 'days')
        .startOf('day')
        .valueOf();
      break;
    case 'CUSTOM':
      from = moment(customFrom)
        .startOf('day')
        .add(1, 'hours');
      to = moment(customTo)
        .startOf('day')
        .add(1, 'hours');
      if (from.valueOf() === to.valueOf()) {
        to = from.endOf('day');
        from = new moment(from).startOf('day');
      }
      from = from.valueOf();
      to = to.valueOf();
      break;
    case 'ALL_TIME':
      all_time = true;
      break;
  }

  return { to, from, all_time };
};

export const getDashboardGraphDetails = (customTo, customFrom) => (
  dispatch,
  getState
) => {
  const isLiveMode = getState().environmentAware.isLiveMode;

  const filters = filterGraph(
    getState().dashboardReducer.currentFilter,
    customTo,
    customFrom
  );
  let all_time = filters && filters.all_time;
  let to = filters && filters.to;
  let from = filters && filters.from;
  let url = `${BASE_DASHBOARD_API}${DASHBOARD_GRAPH_API}`;

  if (!all_time) {
    url += `?from=${from}&to=${to}`;
  } else {
    url += `?selectAllTime=${all_time}`;
  }

  let successHandler = response => {
    dispatch(updateDashboardGraphLoading(false));
    dispatch(
      buildEnvAwareData(
        saveDashboardGraphDetails(response.data.responseBody),
        isLiveMode
      )
    );
  };

  let errorHandler = error => {
    dispatch(updateDashboardGraphLoading(false));
    //dispatch(push('/dashboard'));
    showError(error);
  };

  dispatch(updateDashboardGraphLoading(true));
  dispatch(api.get(url, {}, successHandler, errorHandler));
};

export const getDashboardStaticDetails = (customTo, customFrom) => (
  dispatch,
  getState
) => {
  const isLiveMode = getState().environmentAware.isLiveMode;

  const filters = filterGraph(
    getState().dashboardReducer.currentFilter,
    customTo,
    customFrom
  );

  let all_time = filters && filters.all_time;
  let to = filters && filters.to;
  let from = filters && filters.from;

  let url = `${BASE_DASHBOARD_API}${DASHBOARD_STATIC_API}`;

  if (!all_time) {
    url += `?from=${from}&to=${to}`;
  } else {
    url += `?selectAllTime=${all_time}`;
  }

  let successHandler = response => {
    dispatch(updateDashboardStaticLoading(false));
    dispatch(
      buildEnvAwareData(
        saveDashboardStaticDetails(response.data.responseBody),
        isLiveMode
      )
    );
  };

  let errorHandler = error => {
    dispatch(updateDashboardStaticLoading(false));
    //dispatch(push('/dashboard'));
    showError(error);
  };

  dispatch(updateDashboardStaticLoading(true));
  dispatch(api.get(url, {}, successHandler, errorHandler));
};
