import React, { Component } from "react";
import styled from "styled-components";
import { Modal, Form, Radio, Button, Checkbox, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import datastore from "../../../dataStore";
import { DATA_STORE_KEYS } from "../../../dataStore/keys";
import { connect } from "react-redux";
import {
  switchBusiness,
  checkMerchantBusinessOnReload,
  toggleShowSelectBusinessModal,
  setDefaultBusiness
} from "../../../actions/businesses";
import "./style.scss";
import SelectBusinessType from "../../../../modules/onboarding/selectBusinessType";
import EmailVerificationBanner from "../EmailBanner";

const FormItem = Form.Item;
const RadioStyle = styled(Radio)`
  display: flex;
  align-items: center;

  span:nth-child(2) {
    padding-left: 20px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #f2c94c;
  }

  .ant-radio {
    &:hover {
      border-color: #f2c94c !important;
    }
  }

  .ant-radio-inner {
    width: 26px;
    height: 26px;
    /* border: #f2c94c; */

    &::after {
      background-color: #f2c94c;
      top: 4px;
      left: 4px;
      display: table;
      width: 16px;
      height: 16px;
    }
  }

  br {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    br {
      display: initial;
    }
  }
`;
const StyledModal = styled(Modal)`
  padding: 0;

  .ant-modal-body {
    padding: 0;
    border-radius: 0 !important;
  }

  .ant-modal-content {
    border-radius: 5px;
  }

  .ant-modal-close {
    position: absolute;
    top: -45px;
    right: -20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #fff;
    }
    .ant-modal-close-x {
      font-size: 12px;
    }
    &::before {
      content: "CLOSE";
      margin-right: -15px;
      font-weight: 400;
      font-size: 12px;
    }
  }
`;

const StyledBusinessModal = styled.div`
.switch-business-modal{

}

`

const mapStateToProps = ({ business, onboarding }) => {
  let merchantKycStatus = onboarding.merchantKycStatus || {};
  return {
    showBusinessModal: business.showBusinessModal,
    loading: business.updateSwitchBusinessLoading,
    merchantKycStatus

  };
};

@connect(mapStateToProps, {
  switchBusiness,
  checkMerchantBusinessOnReload,
  toggleShowSelectBusinessModal,
  setDefaultBusiness
})
@Form.create()
class SelectBusinessTypeModal extends Component {
  state = {
    businesses: [],
    active_business: {},
    isVisible: false,
    searchWord: ""
  };

  componentDidMount() {
    this.props.checkMerchantBusinessOnReload();
  }
  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  componentWillUnmount() {
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.setDefaultBusiness)
          this.props.setDefaultBusiness(values.merchantCode);

        this.props.switchBusiness(values, () => {
          this.setState({ isVisible: false, searchWord: "" });
          this.props.toggleShowSelectBusinessModal();
          this.props.form.setFieldsValue({
            searchBusiness: this.state.searchWord
          });
        });
      }
    });
  };

  render() {
    const { form, showBusinessModal, merchantKycStatus, loading } = this.props;
    const { businesses, active_business, isLoggedIn} = this.state;

    let isVisible = this.state.isVisible;

    if (showBusinessModal) isVisible = true;

    const { getFieldsError } = form;

    const show = merchantKycStatus === "PENDING_BUSINESS_TYPE" && datastore.get(DATA_STORE_KEYS.ACCESS_TOKEN)  !== null

    return (

      <StyledModal
        className="select-business-type-modal"
        visible={show}
        footer={null}
        closable={false}
        style={{ top: 20 }}
      // destroyOnClose={true}
      // afterClose = {afterClose}
      >
         <EmailVerificationBanner/>
       <SelectBusinessType/>
       <div className="footer-design"></div>
      </StyledModal>
    );
  }
}

export default SelectBusinessTypeModal;
