import React, { Component } from "react";
import styled from "styled-components";
import { Modal, Form, Radio, Button, Checkbox, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import datastore from "../../../dataStore";
import { DATA_STORE_KEYS } from "../../../dataStore/keys";
import { connect } from "react-redux";
import {
  switchBusiness,
  checkMerchantBusinessOnReload,
  toggleShowSelectBusinessModal,
  setDefaultBusiness
} from "../../../actions/businesses";
import { environmentAware } from "../../../../modules/util/EnvironmentAware";
import "./style.scss";

const FormItem = Form.Item;
const RadioStyle = styled(Radio)`
  display: flex;
  align-items: center;

  span:nth-child(2) {
    padding-left: 20px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #f2c94c;
  }

  .ant-radio {
    &:hover {
      border-color: #f2c94c !important;
    }
  }

  .ant-radio-inner {
    width: 26px;
    height: 26px;
    /* border: #f2c94c; */

    &::after {
      background-color: #f2c94c;
      top: 4px;
      left: 4px;
      display: table;
      width: 16px;
      height: 16px;
    }
  }

  br {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    br {
      display: initial;
    }
  }
`;
const StyledModal = styled(Modal)`
  padding: 0;

  .ant-modal-body {
    padding: 0;
    border-radius: 0 !important;
  }

  .ant-modal-content {
    border-radius: 5px;
  }

  .ant-modal-close {
    position: absolute;
    top: -45px;
    right: -20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #fff;
    }
    .ant-modal-close-x {
      font-size: 12px;
    }
    &::before {
      content: "CLOSE";
      margin-right: -15px;
      font-weight: 400;
      font-size: 12px;
    }
  }
`;

const StyledBusinessModal = styled.div`
.switch-business-modal{

}

`

const mapStateToProps = ({ business }) => {
  return {
    showBusinessModal: business.showBusinessModal,
    loading: business.updateSwitchBusinessLoading,

  };
};

@connect(mapStateToProps, {
  switchBusiness,
  checkMerchantBusinessOnReload,
  toggleShowSelectBusinessModal,
  setDefaultBusiness
})
@Form.create()
class BusinessesModal extends Component {
  state = {
    businesses: [],
    active_business: {},
    isVisible: false,
    searchWord: ""
  };

  componentDidMount() {
    this.props.checkMerchantBusinessOnReload();
  }
  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  componentWillUnmount() {
  }
  componentDidUpdate(prevProps, prevState) {
    const businesses = datastore.get(DATA_STORE_KEYS.MERCHANT_BUSINESSES);
    const active_business = datastore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);
    if (businesses) {
      if (this.state.businesses.length !== businesses.length) {
          const sortedBusinesses = businesses.sort(
          this.compare(businesses.businessName)
        );
        this.setState({ businesses: sortedBusinesses, active_business});
      }
    }
  }
  compare = () => {
    return function (a, b) {
      return a.businessName.toLowerCase().localeCompare(b.businessName.toLowerCase());
    };
  };

  onHandleSearch = (e) => {
    let searchWord = e.target.value.toLowerCase();
    this.setState({ searchWord });
  };

  filterBusiness = (business) => {
    const { searchWord } = this.state
    if (searchWord.trim() === "" || !searchWord) {
      return true;
    }
    return (
      business.businessName.toLowerCase().indexOf(searchWord) !== -1
    );
}
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.setDefaultBusiness)
          this.props.setDefaultBusiness(values.merchantCode);

        this.props.switchBusiness(values, () => {
          this.setState({ isVisible: false, searchWord: "" });
          this.props.toggleShowSelectBusinessModal();
          this.props.form.setFieldsValue({
            searchBusiness: this.state.searchWord
          });
        });
      }
    });
  };

  render() {
    const { form, showBusinessModal, loading } = this.props;
    const { businesses, active_business} = this.state;

    let isVisible = this.state.isVisible;

    if (showBusinessModal) isVisible = true;

    const { getFieldsError } = form;
    return (

      <StyledModal
        className="bank-account-modal"
        visible={isVisible}
        onCancel={() => this.props.toggleShowSelectBusinessModal()}
        footer={null}
      // destroyOnClose={true}
      // afterClose = {afterClose}
      >
        <div className="switch-business-modal">
          <div className="bank-accounts switch-business-modal">
            <h1 className="title">Select a business</h1>
            <Form layout="vertical" hideRequiredMark onSubmit={null}>
              <FormItem>
                {form.getFieldDecorator("searchBusiness", {
                  initialValue: ""
                })(
                  <Input
                  prefix={<SearchOutlined />}
                    allowClear
                    disabled={loading}
                    placeholder="Search for business"
                    style={{
                      border: "1px solid #edeef0",
                      borderRadius: "0",
                      height: "40px",
                      padding: "10px"
                    }}
                    onChange={this.onHandleSearch}
                  />
                )}
              </FormItem>
              <FormItem className="merchantCode">
                {form.getFieldDecorator("merchantCode", {
                  initialValue: active_business && active_business.merchantCode,
                  validateFirst: true,
                  rules: [{ required: true, message: "Select Business" }]
                })(
                  <Radio.Group onChange={this.onChangeAuthType}>
                    <div className="radio-wrap">
                      {businesses &&
                       businesses.filter(this.filterBusiness).map((business) => {
                          return (
                            <RadioStyle
                              key={business.merchantCode}
                              value={business.merchantCode}
                            >
                              {" "}
                              {business.businessName}
                            </RadioStyle>
                          );
                        })}
                    </div>
                  </Radio.Group>
                )}
              </FormItem>

              <FormItem className="setDefaultBusiness">
                {form.getFieldDecorator(
                  "setDefaultBusiness",
                  {}
                )(<Checkbox>Set selected value as default business</Checkbox>)}
              </FormItem>

              <Button
                disabled={this.hasErrors(getFieldsError())}
                className="save-button"
                style={{ padding: "0", margin: "10px auto", width: "233px" }}
                key="submit"
                type="secondary"
                loading={loading}
                onClick={e => this.handleSubmit(e, form)}
              >
                Switch business
              </Button>
            </Form>
          </div>
        </div>
      </StyledModal>
    );
  }
}

export default BusinessesModal;
