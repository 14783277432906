import {
  GET_AUTHORITIES,
  TEAMS_ISLOADING,
  TEAMS_ISPROCESSING,
  GET_ALL_ROLES,
  GET_MERCHANT_USERS,
  GET_MERCHANT_USERS_LOADING,
} from '../actions/types';

const INIT_STATE = {
  data: {},
  authorities: [],
  roles: [],
  users: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUTHORITIES: {
      return {
        ...state,
        authorities: action.payload,
      };
    }

    case GET_ALL_ROLES: {
      return {
        ...state,
        roles: action.payload,
      };
    }

    case TEAMS_ISLOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case TEAMS_ISPROCESSING: {
      return {
        ...state,
        isProcessing: action.payload,
      };
    }
    case GET_MERCHANT_USERS_LOADING: {
      return {
        ...state,
        merchantUsersLoading: action.payload,
      };
    }
    case GET_MERCHANT_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    default:
      return state;
  }
};
