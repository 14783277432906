import {
  SAVE_REFUNDS,
  SAVE_REFUND_DETAILS,
  SAVE_REJECTED_PAYMENT_DETAILS,
  SAVE_REFUND_ACCOUNT_SUMMARY,
  UPDATE_REFUNDS_LOADING,
  IS_PAYCODE_FILTER,
  SAVE_REFUND_ELIGIBLE_TRANSACTIONS,
  SAVE_REJECTED_TRANSACTIONS,
  UPDATE_REJECTED_LOADING,
  SAVE_BULK_REJECTED_PAYMENTS_UPDATE_PROGRESS,
  SAVE_BULK_REFUND_UPDATE_PROGRESS,
  SAVE_REJECTED_PAYMENT_SUMMARY,
  SAVE_REFUND_HISTORY,
  UPDATE_REFUND_HISTORY_LOADING,
} from "../actions/types";
import _ from "lodash";

const INIT_STATE = {
  data: {
    test: [],
    live: [],
  },
  refundSummary: {
    test: {},
    live: {},
  },
  rejectedPaymentSummary: {
    test: {},
    live: {},
  },
  refundDetails: {
    transactionRecordDTO: {
      customerDTO: {},
    },
  },
  loading: false,
  isFilter: {
    test: false,
    live: false,
  },
  rejectedPaymentsData: {
    test: [],
    live: [],
  },
  rejectedPaymentDetails: {},
  rejectedPaymentsBulkUpdateProgress: {},
  refundBulkUpdateProgress: {},
  pagination: {
    live: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    test: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },
  refundHistoryData: {
    test: [],
    live: [],
  },
};

export default (state = INIT_STATE, action) => {
  let merchantProductDetail;

  switch (action.type) {
    case SAVE_REFUNDS:
      let data = _.cloneDeep(state.data);
      data[action.payload.environment] = action.payload.data.data;

      let pagination = state.pagination;
      pagination[action.payload.environment] = action.payload.data.pagination;

      return {
        ...state,
        data,
        pagination: pagination,
      };

    case UPDATE_REFUNDS_LOADING:
      return { ...state, loading: !!action.payload };

    case SAVE_REFUND_DETAILS:
      return { ...state, refundDetails: action.payload };

    case SAVE_REFUND_ACCOUNT_SUMMARY:
      let refundSummary = _.cloneDeep(state.refundSummary);
      refundSummary[action.payload.environment] = action.payload.data;
      return { ...state, refundSummary };

    case SAVE_REJECTED_PAYMENT_SUMMARY:
      let rejectedPaymentSummary = _.cloneDeep(state.rejectedPaymentSummary);
      rejectedPaymentSummary[action.payload.environment] = action.payload.data;
      return { ...state, rejectedPaymentSummary };

    case SAVE_REJECTED_TRANSACTIONS:
      let rejectedPaymentsData = _.cloneDeep(state.rejectedPaymentsData);
      rejectedPaymentsData[action.payload.environment] = action.payload.data;

      let rejectedPaymentsPagination = state.pagination;
      rejectedPaymentsPagination[action.payload.environment] =
        action.payload.pagination;

      return {
        ...state,
        rejectedPaymentsData,
        pagination: rejectedPaymentsPagination,
      };

    case SAVE_REJECTED_PAYMENT_DETAILS:
      return { ...state, rejectedPaymentDetails: action.payload };

    case SAVE_BULK_REJECTED_PAYMENTS_UPDATE_PROGRESS:
      return { ...state, rejectedPaymentsBulkUpdateProgress: action.payload };

    case SAVE_BULK_REFUND_UPDATE_PROGRESS:
      return { ...state, refundBulkUpdateProgress: action.payload };

    case UPDATE_REJECTED_LOADING:
      return { ...state, loading: !!action.payload };

    case IS_PAYCODE_FILTER: {
      let isFilter = _.cloneDeep(state.isFilter);
      isFilter[action.payload.environment] = action.payload;
      return {
        ...state,
        isFilter,
      };
    }

    case SAVE_REFUND_HISTORY:
      let refundHistoryData = _.cloneDeep(state.refundHistoryData);
      refundHistoryData[action.payload.environment] = action.payload.data;

      let refundHistoryPagination = state.pagination;
      refundHistoryPagination[action.payload.environment] =
        action.payload.pagination;

      return {
        ...state,
        refundHistoryData,
        pagination: refundHistoryPagination,
      };

    case UPDATE_REFUND_HISTORY_LOADING:
      return { ...state, loading: !!action.payload };

    default:
      return state;
  }
};
