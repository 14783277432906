import {
  TOGGLE_ENV_MODE,
  LOAD_SAVED_STATE,
  RESET_ENV_STATE
} from '../action/index';
import dataStore from '../../../../common/dataStore';
import { DATA_STORE_KEYS } from '../../../../common/dataStore/keys';
const INIT_STATE = {
  stateLoadedFromLocalStorage: false,
  isLiveMode:
    dataStore.get(DATA_STORE_KEYS.WAS_PREVIOUSLY_ON_LIVE_VIEW) || false,
  isLoading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_ENV_MODE:
      return { ...state, isLiveMode: action.payload };
    case RESET_ENV_STATE:
      return {
        ...state,
        stateLoadedFromLocalStorage: false,
        isLiveMode: false
      };
    case LOAD_SAVED_STATE:
      return {
        ...state,
        stateLoadedFromLocalStorage: true,
        isLiveMode: action.payload
      };
    default:
      return state;
  }
};
