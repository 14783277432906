import api from "./api";
import { push } from "react-router-redux";
import { showError, showMessage } from "./index";
import {
  ACTIVE_BUSINESS_SET,
  UPDATE_SWITCH_BUSINESS_LOADING,
  CREATE_BUSINESS_LOADING,
  SHOW_SELECT_BUSINESS_MODAL,
  TOGGLE_SHOW_SELECT_BUSINESS_MODAL,
  UPDATE_BUSINESS_DETAILS_LOADING,
  SAVE_BUSINESS_DETAILS,
  SET_KYC,
  TOGGLE_BUSINESS_REFRESH,
  SAVE_BUSINESS_LOGO,
  UPDATE_BUSINESS_LOGO_LOADING,
  GET_BUSINESS_DETAILS_LOADING,
  CLEAR_BUSINESS_LOGO,
  PURGE_STATE,
} from "../actions/types";
import { saveUserAuthorities } from "./onboarding";
import { initEnvironmentAwareNew } from "../../modules/util/EnvironmentAware/action";
import { DATA_STORE_KEYS } from "../dataStore/keys";
import DataStore from "../dataStore";
import dataStore from "../dataStore";
import ReactDOM from "react-dom";
import { DEFAULT_PRE_VERIFIED_HOME_ROUTE } from "../utils";

const BUSINESS_BASE_URL = "/business";

const updateCreateBusinessLoading = (payload) => {
  return {
    type: CREATE_BUSINESS_LOADING,
    payload,
  };
};
const updateBusinessDetailsLoading = (payload) => {
  return {
    type: UPDATE_BUSINESS_DETAILS_LOADING,
    payload,
  };
};
const getBusinessDetailsLoading = (payload) => {
  return {
    type: GET_BUSINESS_DETAILS_LOADING,
    payload,
  };
};
const updateSwitchBusinessLoading = (payload) => {
  return {
    type: UPDATE_SWITCH_BUSINESS_LOADING,
    payload,
  };
};

export const saveBusinessDetails = (payload) => {
  return {
    type: SAVE_BUSINESS_DETAILS,
    payload,
  };
};

export const saveBusinessLogo = (payload) => {
  return {
    type: SAVE_BUSINESS_LOGO,
    payload,
  };
};
export const updateBusinessLogoLoading = (payload) => {
  return {
    type: UPDATE_BUSINESS_LOGO_LOADING,
    payload,
  };
};

export const moveToDashboard = (dispatch) => {
  dispatch({ type: ACTIVE_BUSINESS_SET });

  dispatch(push("/dashboard"));
};

export const moveToAddBusinessPage = () => (dispatch) => {
  dispatch(push("/add-business"));
};

// switchBusy = function(args){
//   return function(dispatch){

//   }
// }

export const switchBusiness =
  (request, onSuccess, onFail) => (dispatch, getState) => {
    switchBusinessDispatch({ request, onSuccess, onFail }, dispatch, getState);
  };

export const switchBusinessDispatch = (
  { request, onSuccess, onFail },
  dispatch,
  getState,
) => {
  let requestURL = `auth/select/${request.merchantCode}`;

  let successHandler = (response) => {
    const {
      merchantKycStatus,
      businessType,
      selectedMerchant,
      merchantList,
      authorities,
    } = response.data.responseBody;
    const {
      merchantCode,
      merchantBusinessName,
      merchantEmail,
      merchantAllowedFeatures,
    } = selectedMerchant;

    ReactDOM.unmountComponentAtNode(
      document.querySelector(".monnify-main-content-wrap"),
    );
    dispatch({ type: PURGE_STATE });

    dispatch(updateSwitchBusinessLoading(false));
    showMessage("Switch business", `Switched to ${merchantBusinessName}`);

    saveBusinessInfo({
      kycStatus: merchantKycStatus,
      businessType,
      businessName: merchantBusinessName,
      merchantEmail,
      merchantCode,
      merchantList,
      authorities,
      merchantEmail,
      merchantAllowedFeatures,
    });

    dispatch({ type: SET_KYC, payload: merchantKycStatus });

    let viewDashboard = "MFO_VIEW_DASHBOARD";
    let viewTransactions = "MFO_VIEW_TRANSACTIONS";
    let viewReservedAccounts = "MFO_VIEW_RESERVED_ACCOUNTS";
    let viewSubAccounts = "MFO_VIEW_SUB_ACCOUNTS";
    let viewSettlement = "MFO_VIEW_SETTLEMENTS";
    let viewDisbursement = "MFO_VIEW_DISBURSEMENT_FEATURE";
    let viewTills = "MFO_VIEW_TILL_ACCOUNTS";

    let canViewDashboard = authorities?.some((role) => role === viewDashboard);
    let canViewTransactions = authorities?.some(
      (role) => role === viewTransactions,
    );
    let canViewReservedAccounts = authorities?.some(
      (role) => role === viewReservedAccounts,
    );
    let canViewSubAccounts = authorities?.some(
      (role) => role === viewSubAccounts,
    );
    let canViewSettlement = authorities?.some(
      (role) => role === viewSettlement,
    );
    let canViewDisbursement = authorities?.some(
      (role) => role === viewDisbursement,
    );
    let canViewTills = authorities?.some((role) => role === viewTills);

    onSuccess && onSuccess();
    if (merchantKycStatus === "PENDING_BUSINESS_TYPE") {
      // dispatch(push("/onboard"));
    } else if (merchantKycStatus === "PENDING_KYC_VERIFICATION") {
      dispatch(push(DEFAULT_PRE_VERIFIED_HOME_ROUTE));
    } else if (canViewDashboard) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/dashboard"));
    } else if (canViewTransactions) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/transactions"));
    } else if (canViewReservedAccounts) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/reserved-accounts"));
    } else if (canViewSubAccounts) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/sub-accounts"));
    } else if (canViewSettlement) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/settlements"));
    } else if (canViewDisbursement) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/transfers"));
    } else if (canViewTills) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/tills"));
    } else {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/settings"));
    }

    dispatch({ type: CLEAR_BUSINESS_LOGO });
    dispatch(saveUserAuthorities({ authorities }));
    initEnvironmentAwareNew(dispatch, getState);
  };

  let errorHandler = (error) => {
    onFail && onFail();
    dispatch(updateSwitchBusinessLoading(false));
    showError(error);
  };

  dispatch(updateSwitchBusinessLoading(true));
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const checkMerchantBusinessOnReload = () => (dispatch) => {
  const merchantBusinesses = DataStore.get(DATA_STORE_KEYS.MERCHANT_BUSINESSES);
  if (
    !DataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS) &&
    merchantBusinesses &&
    merchantBusinesses.length > 0
  ) {
    dispatch({ type: SHOW_SELECT_BUSINESS_MODAL });
  }
};

export const toggleShowSelectBusinessModal = () => (dispatch) => {
  if (DataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS)) {
    dispatch({
      type: TOGGLE_SHOW_SELECT_BUSINESS_MODAL,
    });
  }
};

const saveBusinessInfo = (data) => {
  const {
    kycStatus,
    businessType,
    businessName,
    merchantCode,
    merchantList,
    authorities,
    merchantAllowedFeatures,
    merchantEmail,
  } = data;
  let existingBusinesses = DataStore.get(DATA_STORE_KEYS.MERCHANT_BUSINESSES);
  const newBusiness = {
    businessName,
    merchantCode,
    merchantAllowedFeatures,
    merchantEmail,
  };
  const saveMerchantList = merchantList || [...existingBusinesses, newBusiness];

  DataStore.save(DATA_STORE_KEYS.ACTIVE_BUSINESS, newBusiness);
  DataStore.save(DATA_STORE_KEYS.KYC_STATUS, kycStatus);
  DataStore.save(DATA_STORE_KEYS.BUSINESS_TYPE, businessType);
  //DataStore.save(DATA_STORE_KEYS.USER_DATA, data);
  DataStore.save(DATA_STORE_KEYS.MERCHANT_BUSINESSES, saveMerchantList);
  DataStore.save(DATA_STORE_KEYS.AUTHORITIES, authorities);
};

export const createBusiness = (request) => (dispatch, getState) => {
  let requestURL = `${BUSINESS_BASE_URL}/create`;
  let successHandler = (response) => {
    showMessage("Create new business", `${request.name} has been created.`);
    switchBusinessDispatch(
      {
        request: response.data.responseBody,
        onSuccess: () => {
          dispatch(updateCreateBusinessLoading(false));
        },
        onFail: () => {
          dispatch(updateCreateBusinessLoading(false));
        },
      },
      dispatch,
      getState,
    );
    // switchBusiness(response.data.responseBody, null, null, dispatch, getState);

    //dispatch(push("/activate-business"));
  };

  let errorHandler = (error) => {
    dispatch(updateCreateBusinessLoading(false));
    showError(error);
  };

  dispatch(updateCreateBusinessLoading(true));
  dispatch(api.post(requestURL, request, successHandler, errorHandler));
};

export const setDefaultBusiness = (code) => (dispatch) => {
  let requestURL = `${BUSINESS_BASE_URL}/set-default/${code}`;
  let successHandler = (response) => {
    showMessage(
      "Set business as default",
      `Businesss has been set as default.`,
    );
  };

  let errorHandler = (error) => {
    showError(error);
  };

  dispatch(api.post(requestURL, {}, successHandler, errorHandler));
};

export const getBusinessDetails = () => (dispatch, getState) => {
  const business = dataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);

  if (!business) return;

  let requestURL = `${BUSINESS_BASE_URL}/${business.merchantCode}`;

  let successHandler = (response) => {
    dispatch(saveBusinessDetails(response.data.responseBody));
    dispatch(getBusinessDetailsLoading(false));
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch(getBusinessDetailsLoading(false));
  };

  dispatch(getBusinessDetailsLoading(true));
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const updateBusinessDetails = (request) => (dispatch, getState) => {
  const business = dataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);

  let {
    businessCategoryName,
    businessDescription,
    supportEmail,
    chargeBackEmail,
    address,
    website,
    facebookUsername,
    twitterUsername,
    linkedinUsername,
    instagramUsername,
    file,
    logo,
    lgaCode,
    businessName,
    email,
    phoneNumber,
    sendEmailToCustomer,
  } = request;

  let formData = new FormData();

  businessName && formData.append("businessName", businessName);
  sendEmailToCustomer &&
    formData.append("sendEmailToCustomer", sendEmailToCustomer);
  email && formData.append("email", email);
  phoneNumber && formData.append("phoneNumber", phoneNumber);
  businessCategoryName &&
    formData.append("businessCategoryName", businessCategoryName);
  businessDescription &&
    formData.append("businessDescription", businessDescription);
  supportEmail && formData.append("supportEmail", supportEmail);
  chargeBackEmail && formData.append("chargeBackEmail", chargeBackEmail.trim());
  address && formData.append("address", address);
  lgaCode && formData.append("lgaCode", lgaCode);

  if (logo) {
    formData.append("logoFileType", file.extension);
    formData.append("logo", logo);
  }
  if (!!website) {
    formData.append("website", website);
  }
  if (!!facebookUsername) {
    formData.append("facebookUsername", facebookUsername);
  }
  if (!!twitterUsername) {
    formData.append("twitterUsername", twitterUsername);
  }
  if (!!instagramUsername) {
    formData.append("instagramUsername", instagramUsername);
  }
  if (!!linkedinUsername) {
    formData.append("linkedinUsername", linkedinUsername);
  }

  let requestURL = `${BUSINESS_BASE_URL}/update/${business.merchantCode}`;

  let successHandler = (response) => {
    showMessage("Update business details", `Business detail has been updated.`);
    dispatch(updateBusinessDetailsLoading(false));
  };

  let errorHandler = (error) => {
    showError(error);
    dispatch(updateBusinessDetailsLoading(false));
  };

  dispatch(updateBusinessDetailsLoading(true));
  dispatch(api.put(requestURL, formData, successHandler, errorHandler));
};

export const getBusinessLogo = () => (dispatch) => {
  let requestURL = `${BUSINESS_BASE_URL}/get-business-logo`;

  let successHandler = (response) => {
    dispatch(saveBusinessLogo(response.data.responseBody));
    dispatch(updateBusinessLogoLoading(false));
  };

  let errorHandler = (error) => {
    //showError(error);
    dispatch({ type: CLEAR_BUSINESS_LOGO });
    dispatch(updateBusinessLogoLoading(false));
  };

  dispatch(updateBusinessLogoLoading(true));
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};
