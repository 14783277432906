import {
  ADD_PAYMENT_PAGE_CUSTOM_ATTRIBUTES,
  CLEAR_PAYMENT_PAGE_FORM,
  REMOVE_CUSTOM_ATTRIBUTE,
  SAVE_PAGE_TRANSACTIONS,
  SAVE_PAYMENT_PAGES_LIST,
  SAVE_PAYMENT_PAGES_LIST_DETAILS,
  SAVE_PAYMENT_PAGE_STATIC_SUMMARY,
  SAVE_PAYMENT_PAGE_STATIC_SUMMARY_GRAPH,
  UPDATE_PAYMENT_PAGE_FORM_DATA,
  UPDATE_PAYMENT_PAGE_LOADING,
  SET_INITIAL_CUSTOM_ATTRIBUTE,
} from "../actions/types";
import _ from "lodash";

const INIT_STATE = {
  data: {
    test: [],
    live: [],
  },
  paymentPageDetails: {},
  loading: false,
  pagination: {
    live: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    test: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },
  formDetails: {
    customAttributes: [],
  },
  initialCustomAttribute: {},
  pageTransactions: {
    data: [],
    pagination: {
      current: 1,
      size: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },
  paymentPageStaticSummary: {
    value: 0,
    volume: 0,
    totalCustomers: 0,
  },

  graphData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_PAGE_TRANSACTIONS:
      let transactions = _.cloneDeep(state.pageTransactions);
      transactions.data = action.payload.data;
      transactions.pagination = action.payload.pagination;
      return {
        ...state,
        pageTransactions: transactions,
      };
    case SAVE_PAYMENT_PAGES_LIST:
      let data = _.cloneDeep(state.data);

      let pagination = _.cloneDeep(state.pagination);

      data[action.payload.environment] = action.payload.data.content;

      // let pagination = state.pagination;
      pagination[action.payload.environment] = {
        ...pagination[action.payload.environment],
        total: action.payload.data.pagination.total,
        current: action.payload.data.pagination.current + 1,
      };

      return {
        ...state,
        data,
        pagination,
      };

    case UPDATE_PAYMENT_PAGE_LOADING:
      return { ...state, loading: !!action.payload };

    case SAVE_PAYMENT_PAGES_LIST_DETAILS:
      return { ...state, paymentPageDetails: action.payload };

    case SAVE_PAYMENT_PAGE_STATIC_SUMMARY:
      return { ...state, paymentPageStaticSummary: action.payload };

    case SAVE_PAYMENT_PAGE_STATIC_SUMMARY_GRAPH:
      return { ...state, graphData: action.payload };

    case UPDATE_PAYMENT_PAGE_FORM_DATA:
      const pageData = _.cloneDeep(state.formDetails);
      return { ...state, formDetails: { ...pageData, ...action.payload } };

    case CLEAR_PAYMENT_PAGE_FORM:
      return {
        ...state,
        formDetails: {
          customAttributes: [],
        },
      };
    case SET_INITIAL_CUSTOM_ATTRIBUTE:
      return { ...state, initialCustomAttribute: action.payload };

    case ADD_PAYMENT_PAGE_CUSTOM_ATTRIBUTES:
      let form = _.cloneDeep(state.formDetails);

      const isNewData = _.isEmpty(state.initialCustomAttribute);

      if (!isNewData) {
        form.customAttributes.splice(
          state.initialCustomAttribute.index,
          1,
          action.payload,
        );
      } else {
        form.customAttributes.push(action.payload);
      }

      return {
        ...state,
        initialCustomAttribute: {},
        formDetails: {
          ...form,
          customAttributes: [...form.customAttributes],
        },
      };

    case REMOVE_CUSTOM_ATTRIBUTE:
      let form_cs = _.cloneDeep(state.formDetails);

      form_cs.customAttributes.splice(action.payload, 1);

      return {
        ...state,
        formDetails: {
          ...form_cs,
          customAttributes: [...form_cs.customAttributes],
        },
      };

    default:
      return state;
  }
};
