import React from "react";
import Page from "../../../common/components/LayoutComponents/Page";
import BasePage from "../BasePage";
import { Helmet } from "react-helmet-async";
import LoginForm from "./LoginForm";

class LoginPage extends React.Component {
  static defaultProps = {
    loginRequired: false,
    containerExtraClass: "out-app-page",
  };

  mainHeading = "Next Level \n Collections for your Business";
  subHeading = `Monnify is a leading payment technology that powers \n seamless transactions for businesses through \n omnichannel platforms`;

  render() {
    const { match, ...props } = this.props;

    return (
      <Page {...props}>
        <Helmet title="Login" />
        <BasePage
          className="login"
          mainHeading={this.mainHeading}
          subHeading={this.subHeading}
        >
          <LoginForm />
        </BasePage>
      </Page>
    );
  }
}

export default LoginPage;
