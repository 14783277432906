import React, { Component } from "react";
import { Form } from "antd";
import { connect } from "react-redux";
import notification from "antd/es/notification";
import { Button } from "antd";
import "./style.scss";
import { getSystemAppVersion } from "../../../config";
import { getFOApplicationVersionFromServer } from "../../../actions/application";
import { getBadges } from "../../../actions/badges";
import dataStore from "../../../dataStore";
import { DATA_STORE_KEYS } from "../../../dataStore/keys";
import moment from "moment";

const PAGE_UPDATE_REFRESH_LIMIT = 2;

const mapStateToProps = ({ applicationInfo: { appVersion },onboarding: { isLoggedIn } }) => {
  return {
    appVersion,
    isLoggedIn
  };
};

@connect(mapStateToProps, {
  getFOApplicationVersionFromServer,
  getBadges
})
@Form.create()
class AppUpdateNotification extends Component {
  componentDidMount() {
    this.props.getFOApplicationVersionFromServer();
    this.props.getBadges();
  }

  checkApplicationVersionDoesNotMatch = () => {
    return this.props.appVersion && this.props.appVersion !== getSystemAppVersion() 
  }

  showUpdateNotification = () => {
    notification.open({
      message: "Update Application",
      description: (
        <span>
          Do you want to update the application?{" "}
          <Button onClick={this.handleUpdate}>Update</Button>
        </span>
      ),
    });
  }

  componentDidUpdate(prevProps) {
    
    const isAppVersionChanged = this.props.appVersion !== prevProps.appVersion;
    const userLoggedIn = prevProps.isLoggedIn === false && this.props.isLoggedIn



    if(userLoggedIn && !this.props.appVersion){
      setTimeout(this.props.getFOApplicationVersionFromServer, 1000)
    }

    if (isAppVersionChanged && this.checkApplicationVersionDoesNotMatch()) {
      this.handleUpdate()
    }
  }

  handleUpdate = () => {
    const pageUpdateRefreshes = dataStore.get(DATA_STORE_KEYS.PAGE_UPDATE_REFRESHES);
    const pageUpdateRefreshExpiry = dataStore.get(DATA_STORE_KEYS.PAGE_UPDATE_REFRESH_EXPIRY);
    const currentDate = moment().unix()

    if(pageUpdateRefreshExpiry){
      const expiryDate = parseInt(pageUpdateRefreshExpiry)
      const refreshes = parseInt(pageUpdateRefreshes) || 0

      if(expiryDate >= currentDate && refreshes < PAGE_UPDATE_REFRESH_LIMIT){
        this.refreshPage( refreshes, false)
        return;
      }else if( expiryDate <= currentDate ){
        this.refreshPage( 0, true)
      }
    }else{
      this.refreshPage( 0, true)
    }
  };

  refreshPage = (refreshes, shouldUpdateExpiry) => {
    const expiryDate = moment().add(2, 'm').unix()

    shouldUpdateExpiry && dataStore.save(DATA_STORE_KEYS.PAGE_UPDATE_REFRESH_EXPIRY, expiryDate);
    dataStore.save(DATA_STORE_KEYS.PAGE_UPDATE_REFRESHES, refreshes + 1);
    window.location.reload(true);
  }

  render() {
    return <div></div>;
  }
}

export default AppUpdateNotification;
