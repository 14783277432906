import React from "react";
import { DATA_STORE_KEYS } from "../../dataStore/keys";
import DataStore from "../../dataStore";
import Util from "../../utils";
import config from "../../config";

class Sift extends React.Component {
  state = {
    app_id: config.SIFT_APP_ID
};
  
  appendScript() {
    function ls() {
      var e = document.createElement('script');
      e.src = 'https://cdn.sift.com/s.js';
      document.body.appendChild(e);
    }
    if (window.attachEvent) {
      window.attachEvent('onload', ls);
    } else {
      window.addEventListener('load', ls, false);
    }
  }

 
  componentDidMount() {
    const authorization = DataStore.get(DATA_STORE_KEYS.ACCESS_TOKEN);
  if(authorization){
    this.appendScript();
    this.sendPageView(authorization)
  }
  }

   sendPageView = (authorization) => {
       let token = authorization && Util.parseJwt(authorization)
       let data = token && {
           user_id: token.username,
           session_id: token.exp
       };

        var _sift = window._sift = window._sift || [];
        _sift.push(['_setAccount', this.state.app_id]);
        _sift.push(['_setUserId', data.user_id]);
        _sift.push(['_setSessionId', data.session_id]);
        _sift.push(['_trackPageview']);
    }

  render() {
    return false;
  }
}

export default Sift;
