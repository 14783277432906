import _ from 'lodash';
import { CLEAR_FORM_SUBMIT_ERROR, FORM_SUBMIT_ERROR } from "./../actions/types";

const INITIAL_STATE = {
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FORM_SUBMIT_ERROR:
            const newState = _.merge(state, { [action.payload.form]: action.payload.error })
            return { ...state, newState }
        case CLEAR_FORM_SUBMIT_ERROR:
            return _.omit(state, [action.payload.form]);
        default:
            return state
    }
}