import './style.scss';
import React from 'react';
import { Row, Col, Switch } from 'antd';
import { connect } from 'react-redux';
import { switchEnvironment } from '../../../modules/util/EnvironmentAware/action';

const mapStateToProps = (state, props) => {
  const { appEnvironment } = state;

  return {
    isLiveMode: state.environmentAware.isLiveMode,
    isLoading: state.environmentAware.isLoading,
    switchInProgress: appEnvironment.switchInProgress
  };
};

@connect(mapStateToProps, { switchEnvironment })
class EnvironmentSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLiveMode: props.isLiveMode
    };
  }

  onChange = checked => {
    const { dispatch } = this.props;
    this.props.switchEnvironment(checked);
  };

  getLabelActiveClass = mode => {
    if (mode === 'live' && this.props.isLiveMode) {
      return 'active';
    }

    if (mode === 'test' && !this.props.isLiveMode) {
      return 'active';
    }

    return '';
  };

  getSwitchClass = () => {
    return this.props.isLiveMode ? true : false;
  };

  render() {
    return (
      <div style={this.props.style} className="monnify-env-switch">
        <Row>
          <Col
            className={`switch-label test ${this.getLabelActiveClass('test')}`}
          >
            Test
          </Col>
          <Col 
           className={`switch-cont`}>
            <Switch
              checked={this.props.isLiveMode}
              disabled={this.props.isLoading}
              onChange={this.onChange}
            />
          </Col>
          <Col
            className={`switch-label live ${this.getLabelActiveClass('live')}`}
          >
            Live
          </Col>
        </Row>
      </div>
    );
  }
}

export default EnvironmentSwitch;
