class DataStore {
  store = window.localStorage;

  save = (key, value) => {
    if (typeof value == "undefined") {
      this.store.setItem(key, "undefined");
    } else {
      this.store.setItem(key, JSON.stringify(value));
    }
  };

  setItem = (key, value) => {
    return this.store.setItem(key, value);
  };

  getItem = (key, value) => {
    return this.store.getItem(key, value);
  };

  delete = (key) => {
    this.store.removeItem(key);
  };

  get = (key) => {
    var value = this.store.getItem(key);
    if (value !== "undefined") {
      value = JSON.parse(value);
    } else {
      value = undefined;
    }

    return value;
  };

  clear = () => {
    this.store.clear();
  };
}

export default new DataStore();
