import {
  SHOW_SELECT_BUSINESS_MODAL,
  BUSINESSES_CLEARED_FROM_STORE,
  ACTIVE_BUSINESS_SET,
  ACTIVE_BUSINESS_UNSET,
  UPDATE_SWITCH_BUSINESS_LOADING,
  CREATE_BUSINESS_LOADING,
  TOGGLE_SHOW_SELECT_BUSINESS_MODAL,
  TOGGLE_BUSINESS_REFRESH,
  SAVE_BUSINESS_DETAILS,
  UPDATE_BUSINESS_DETAILS_LOADING,
  GET_BUSINESS_DETAILS_LOADING,
  SAVE_BUSINESS_LOGO,
  UPDATE_BUSINESS_LOGO_LOADING,
  CLEAR_BUSINESS_LOGO
} from "../actions/types";

const INIT_STATE = {
  showBusinessModal: false,
  active_business: false,
  toggle_refresh: false,
  switch_business: false,
  createBusinessLoading: false,
  updateSwitchBusinessLoading: false,
  businessDetail: {},
  businessDetailLoading: false,
  updateBusinessDetailLoading: false,
  updateBusinessLogoLoading: false,
  businessLogo: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_SELECT_BUSINESS_MODAL:
      return {
        ...state,
        showBusinessModal: true
      };

    case TOGGLE_SHOW_SELECT_BUSINESS_MODAL:
      return {
        ...state,
        showBusinessModal: !state.showBusinessModal
      };
    case BUSINESSES_CLEARED_FROM_STORE:
      return {
        ...state,
        bussinesses_loaded: false
      };
    case ACTIVE_BUSINESS_UNSET:
      return {
        ...state,
        active_business: false
      };
    case ACTIVE_BUSINESS_SET:
      return {
        ...state,
        active_business: true
      };

    case TOGGLE_BUSINESS_REFRESH:
      return {
        ...state,
        toggle_refresh: !state.toggle_refresh
      };

    case CREATE_BUSINESS_LOADING:
      return {
        ...state,
        createBusinessLoading: action.payload
      };
    case UPDATE_BUSINESS_DETAILS_LOADING:
      return {
        ...state,
        updateBusinessDetailLoading: action.payload
      };
    case GET_BUSINESS_DETAILS_LOADING:
      return {
        ...state,
        businessDetailLoading: action.payload
      };
    case UPDATE_SWITCH_BUSINESS_LOADING:
      return {
        ...state,
        updateSwitchBusinessLoading: action.payload
      };
    case SAVE_BUSINESS_DETAILS:
      return {
        ...state,
        businessDetail: action.payload
      };
    case SAVE_BUSINESS_LOGO:
      return {
        ...state,
        businessLogo: action.payload
      };

    case UPDATE_BUSINESS_LOGO_LOADING:
      return{
        ...state,
        updateBusinessLogoLoading: action.payload
      }

    case CLEAR_BUSINESS_LOGO:
      return {
        ...state,
        businessLogo: {}
      }
    default:
      return state;
  }
};
