export const DATA_STORE_KEYS = {
  ACCESS_TOKEN: "monnify.access-token",
  USER_DATA: "monnify.user-data",
  KYC_STATUS: "monnify.user-kyc",
  BUSINESS_TYPE: "monnify.user-business",
  APP_ENVIRONMENT: "monnify.app.environment",
  APP_LAYOUT_STATE: "monnify.app.layout-state",
  WAS_PREVIOUSLY_ON_LIVE_VIEW: "monnify.was-previously-on-live-view",
  AUTHORITIES: "monnify.user.authorities",
  ACTIVE_BUSINESS: "monnify.active-business",
  MERCHANT_BUSINESSES: "monnify.merchant-businesses",
  WALLET_ID: "monnify.wallet-id",
  ACCOUNT_NO: "monnify.account-no",
  FRESCHAT_RESTORE_ID: "monnify.freschat-restore-id",
  VISITED_PAGES: "monnify.visited-pages",
  PAGE_UPDATE_REFRESHES: "monnify.page-update-refreshes",
  PAGE_UPDATE_REFRESH_EXPIRY: "monnify.page-update-refresh-expiry",
  REFUND_BULKUPLOAD_SESSION_ID: "refund-bulkupload-session-id",
  RJECTED_PAYMENT_BULKUPLOAD_SESSION_ID:
    "RJECTED_PAYMENT_BULKUPLOAD_SESSION_ID",
  CODE_VERIFIER: "monnify.code-verifier",
  AUTH_CODE: "monnify.auth-code",
  USER_MFA_CONFIG: "monnify.user-mfa-config",
};
