import api from './api';
import { push } from 'react-router-redux';
import { showError, showMessage } from './index';
import { SAVE_FO_APP_VERSION } from './types';
import Util from './../utils';

const APP_INFO_URL = '/app-info';

export const saveFOApplicationVersion = (payload) => {
    return {
        type: SAVE_FO_APP_VERSION,
        payload
    }
};

export const getFOApplicationVersionFromServer = (onFinish = () => {}) => (dispatch) => {
   
    let url = `${APP_INFO_URL}/get-version`;

    let _successHandler = (response) => {
        dispatch(saveFOApplicationVersion(response.data.responseBody.appVersion))
        onFinish(response.data.responseBody.appVersion)
    };

    let _errorHandler = (error) => {
        showError(error);
    };
    dispatch(api.get(url, {}, _successHandler, _errorHandler, true));
};
