import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "./common/components/LayoutComponents/Layout";
import Sift from "./common/components/Sift";
import "./App.scss";

import { pdfjs } from "react-pdf";
import { GlobalDebug } from "./modules/util/removeconsole-log";

pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

class App extends React.Component {
  componentDidMount() {
    if (process.env.NODE_ENV !== "development") {
      GlobalDebug(false, true);
    }
  }
  render() {
    const helmetContext = {};
    return (
      <div>
        <HelmetProvider context={helmetContext}>
          <Helmet titleTemplate="Monnify - %s" />
          <Layout />
          {/* <Freshchat /> */}
          <Sift />
        </HelmetProvider>
      </div>
    );
  }
}

export default App;
