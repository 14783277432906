import { AUTHENTICATION_VALIDATE_PASSWORD_PROCESSING, AUTHENTICATION_VALIDATE_PASSWORD_ERROR_UPDATE } from './../actions/types';

const INIT_STATE = {
    validatePasswordError: null,
    processingValidatePassword: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case AUTHENTICATION_VALIDATE_PASSWORD_ERROR_UPDATE:
            return {
                ...state,
                validatePasswordError: action.payload
            };

        case AUTHENTICATION_VALIDATE_PASSWORD_PROCESSING:
            return { ...state, processingValidatePassword: !!action.payload };

        default:
            return state
    }
};