import React from 'react';
import { connect } from 'react-redux';
import { initEnvironmentAware } from './action/index';
import { stat } from 'fs';

export const environmentAware = (
  reloadOperations,
  mapStateToComponentEnvData
) => WrappedComponent => {
  const mapStateToProps = state => {
    const isLiveMode = state.environmentAware.isLiveMode;
    const envData =
      mapStateToComponentEnvData === undefined
        ? {}
        : mapStateToComponentEnvData(state);
    let envRefreshableData = {};

    Object.keys(envData).forEach(key => {
      const envKey = isLiveMode ? 'live' : 'test';
      envRefreshableData[key] =
        envData[key][envKey] === undefined
          ? envData[key]
          : envData[key][envKey];
    });

    return {
      isLiveMode,
      ...envRefreshableData
    };
  };
  // ...and returns another component...
  return connect(
    mapStateToProps,
    { initEnvironmentAware, ...reloadOperations }
  )(
    class extends React.Component {
      componentDidMount() {
        // ... that takes care of the subscription...
        this.props.initEnvironmentAware();
      }

      componentWillReceiveProps(previousProps) {
        const newProps = this.props.isLiveMode;
        const oldProps = previousProps.isLiveMode;

        if (newProps !== oldProps) {
          Object.keys(reloadOperations).forEach(key => {
            if (this.props[key]) {
              this.props[key]();
            }
          });
        }
      }

      render() {
        return <WrappedComponent {...this.props} ref={c => (this.home = c)} />;
      }
    }
  );
};
