import {
  SAVE_OFFLINE_MERCHANT_PRODUCTS,
  UPDATE_OFFLINE_MERCHANT_PRODUCTS_LOADING,
  SAVE_OFFLINE_MERCHANT_PRODUCT_DETAILS,
  SAVE_OFFLINE_MERCHANT_PRODUCT_TRANSACTIONS,
  UPDATE_OFFLINE_MERCHANT_PRODUCT_TRANSACTIONS_LOADING,
  SAVE_OFFLINE_MERCHANT_PRODUCT_ACCOUNT_SUMMARY,
  CLEAR_OFFLINE_MERCHANT_PRODUCT_DETAILS,
  IS_OFFLINE_MERCHANT_PRODUCT_FILTER,
  IS_OFFLINE_MERCHANT_PRODUCT_TRANSACTION_FILTER,
  GET_OFFLINE_INCOMING_AMOUNT,
  GET_MERCHANT_OFFLINE_CONFIG
} from "../actions/types";
import _ from "lodash";

const INIT_STATE = {
  productSummary: {
    test: [],
    live: [],
  },
  merchantProducts: {
    test: [],
    live: [],
  },

  merchantProductDetail: {
    data: {},
   
    transactions: [],
    loading: false,
    loadingTransactions: false,
    processingDeallocation: false,
    isFilter: false,
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    transactionSplitConfig: {},
    transactionSplitAmount: 0
  },
  pagination: {
    live: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    test: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },
  loading: false,
  isFilter: {
    test: false,
    live: false,
  },
};

export default (state = INIT_STATE, action) => {
  let merchantProductDetail;

  switch (action.type) {
    case SAVE_OFFLINE_MERCHANT_PRODUCTS:
      let merchantProducts = _.cloneDeep(state.merchantProducts);
      merchantProducts[action.payload.environment] = action.payload.data.data;

      let pagination = state.pagination;
      pagination[action.payload.environment] = action.payload.data.pagination;

      return {
        ...state,
        merchantProducts: merchantProducts,
        pagination: pagination,
        loading: !!action.payload.loading,
      };

    case SAVE_OFFLINE_MERCHANT_PRODUCT_ACCOUNT_SUMMARY:
      let productSummary = _.cloneDeep(state.productSummary);
      productSummary[action.payload.environment] = action.payload.data
      return { ...state, productSummary };

    case SAVE_OFFLINE_MERCHANT_PRODUCT_DETAILS:
      merchantProductDetail = _.cloneDeep(state.merchantProductDetail || {});
      merchantProductDetail.data = action.payload.data;
      return { ...state, merchantProductDetail };

    case UPDATE_OFFLINE_MERCHANT_PRODUCTS_LOADING:
      return { ...state, loading: !!action.payload };

    case SAVE_OFFLINE_MERCHANT_PRODUCT_TRANSACTIONS:
      merchantProductDetail = _.cloneDeep(state.merchantProductDetail || {});
      merchantProductDetail.transactions = action.payload.data.data;
      merchantProductDetail.pagination = action.payload.data.pagination;
      return { ...state, merchantProductDetail };
      
    case UPDATE_OFFLINE_MERCHANT_PRODUCT_TRANSACTIONS_LOADING:
      merchantProductDetail = _.cloneDeep(state.merchantProductDetail || {});
      merchantProductDetail.loadingTransactions = !!action.payload;
      return { ...state, merchantProductDetail };

    case CLEAR_OFFLINE_MERCHANT_PRODUCT_DETAILS:
      merchantProductDetail = _.cloneDeep(state.merchantProductDetail || {});
      merchantProductDetail.data = {}
      return {...state, merchantProductDetail}

    case IS_OFFLINE_MERCHANT_PRODUCT_FILTER: {
      let isFilter = _.cloneDeep(state.isFilter);
      isFilter[action.payload.environment] = action.payload;
      return {
        ...state,
        isFilter
      };
    }
    case IS_OFFLINE_MERCHANT_PRODUCT_TRANSACTION_FILTER: {
      merchantProductDetail = _.cloneDeep(state.merchantProductDetail);
      merchantProductDetail.isFilter = action.payload;
      return {
        ...state,
        merchantProductDetail
      };
    }
    case GET_OFFLINE_INCOMING_AMOUNT: {
      merchantProductDetail = _.cloneDeep(state.merchantProductDetail);
      merchantProductDetail.transactionSplitAmount = parseInt(action.payload, 10);
      return {
        ...state,
        merchantProductDetail
      };
    }
    case GET_MERCHANT_OFFLINE_CONFIG: {
      merchantProductDetail = _.cloneDeep(state.merchantProductDetail);
      merchantProductDetail.transactionSplitConfig = action.payload;
      return {
        ...state,
        merchantProductDetail
      };
    }
    default:
      return state;
  }
};
