import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import "./style.scss";

const mapStateToProps = ({app, routing}, props) => {
    const {layoutState} = app;
    return {
        pathname: routing.location && routing.location.pathname,
        collapsed: layoutState.menuCollapsed,
        theme: layoutState.themeLight ? "light" : "dark",
        settingsOpened: layoutState.settingsOpened
    };
};

@connect(mapStateToProps)
@withRouter
class MenuTop extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pathname: props.pathname,
            collapsed: props.collapsed,
            theme: props.theme,
            selectedKeys: "",
            openKeys: [""],
            settingsOpened: props.settingsOpened
        };
    }

    render() {
        return (
            <div className="menuTop">
                <div className="menuTop__logo">
                    <div className="menuTop__logoContainer">
                        <img src={"images/logo.svg"} alt=""/>
                    </div>
                </div>
            </div>
        );
    }
}

export {MenuTop};
