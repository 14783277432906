import React from "react";
import { Badge } from "antd";
import "./style.scss";

class MenuBadge extends React.Component {
  render() {
    const { data } = this.props;
    return data ? (
      <span className="monnify-badge">
        <Badge count={data} />
      </span>
    ) : (
      ""
    );
  }
}

export default MenuBadge;
