import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import {Icon, Breadcrumb} from "antd";

class BreadCrumb extends React.Component {

    render() {
        let { data } = this.props;

        if (data && data.length > 0) {
            return (
                <Breadcrumb separator={<Icon type="right"/>}>
                    {data.map(item => {
                        if (item.link) {
                            return <Breadcrumb.Item key={item.text}><Link to={item.link}>{item.text}</Link></Breadcrumb.Item>
                        } else {
                            return <Breadcrumb.Item key={item.text}>{item.text}</Breadcrumb.Item>;
                        }
                    })}
                </Breadcrumb>
            )
        } else {
            return "";
        }
    }
}

export default BreadCrumb
