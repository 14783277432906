import {
    GET_COUNTRIES,
    GET_COUNTRY_STATES,
    GET_STATE_LGAS,
    GET_LGA
} from "../actions/types";

const INIT_STATE = {
  countries: [],
  states: {},
  lgas: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case GET_COUNTRY_STATES:
      let countryStates = { ...state.states };
      countryStates[action.payload.countryCode] = action.payload.states;

      return {
        ...state,
        states: countryStates,
      };
    case GET_STATE_LGAS:
      let stateLgas = { ...state.lgas };
      stateLgas[action.payload.stateCode] = action.payload.lgas;

      return {
        ...state,
        lgas: stateLgas,
      };
    case GET_LGA: {
      return {
        ...state,
        verifyBusiness: {
          ...state.verifyBusiness,
          lgas: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
