import {
  UPDATE_MERCHANT_CLIENT_DETAILS_LOADING,
  SAVE_MERCHANT_CLIENT_DETAILS,
  GET_MERCHANT_CLIENT_CONTRACTS,
  UPDATE_CLIENT_VALIDATION_LOADING,
  GET_MERCHANT_NOTIFICATION,
  SAVE_PAYMENT_METHOD_STATUS,
  UPDATE_PAYMENT_METHOD_STATUS_LOADING,
  SAVE_MERCHANT_SETTLEMENT_ACCOUNT,
  UPDATE_BANK_ACCOUNTS_LOADING,
  UPDATE_CONTRACT_SETTINGS_LOADING,
  UPDATE_MERCHANT_SETTLEMENT_LOADING,
  UPDATE_BANK_ACCOUNTS_PROCESSED,
  UPDATE_PAYMENT_METHOD_PROCESSED,
  GET_SETTLEMENT_NOTIFICATION_PREFERENCE,
  GET_DISBURSEMENT_PREFERENCES,
  UPDATE_BANK_ACCOUNT_UPDATE_REQUEST_LOADING,
  SAVE_MERCHANT_SETTLEMENT_ACCOUNT_UPDATE_REQUEST
} from './../actions/types';

const INIT_STATE = {
  loadingClientDetails: false,
  merchantSettlementAccountUpdateRequest: {},
  clientDetails: {},
  clientContracts: {
    test: {},
    live: {}
  },
  loadingClientValidation: false,
  isClientValidated: false,
  clientNotificationList: {
    test: [],
    live: []
  },
  sendEmailToCustomer: {
    test: null,
    live: null
  },
  settlementAccounts: {
    test: [],
    live: []
  },
  settlementNotificationPreference: {
    test: {},
    live: {}
  },
  disbursements: {
    test: {},
    live: {}
  },
  loadingPaymentMethodStatus: false,
  bankAccountsLoading: false,
  bankUpdateAccountLoading: false,
  contractSettingsLoading: false,
  bankAccountProcessed: false,
  paymentMethodProcessed: false,
  bankAccountUpdateRequestLoading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_MERCHANT_CLIENT_DETAILS:
      let clientDetails = state.clientDetails;
      clientDetails = action.payload;

      return {
        ...state,
        clientDetails
      };

    case UPDATE_MERCHANT_CLIENT_DETAILS_LOADING:
      return { ...state, loadingClientDetails: !!action.payload };

    case GET_MERCHANT_CLIENT_CONTRACTS:
      let clientContracts = state.clientContracts;
      clientContracts[action.payload.environment] = action.payload.data;

      return { ...state, clientContracts };

    case UPDATE_CLIENT_VALIDATION_LOADING:
      return { ...state, loadingClientValidation: !!action.payload };

    case GET_MERCHANT_NOTIFICATION:
      let clientNotificationList = state.clientNotificationList;
      clientNotificationList[action.payload.environment] =
        action.payload.data.notificationMethodList;

      let sendEmailToCustomer = state.sendEmailToCustomer;
      sendEmailToCustomer[action.payload.environment] =
        action.payload.data.sendEmailToCustomer;
      return {
        ...state,
        clientNotificationList: clientNotificationList,
        sendEmailToCustomer: sendEmailToCustomer
      };

    case SAVE_PAYMENT_METHOD_STATUS:
      return { ...state, paymentMethodStatus: action.payload };
    case UPDATE_PAYMENT_METHOD_STATUS_LOADING:
      return { ...state, loadingPaymentMethodStatus: !!action.payload };
    case SAVE_MERCHANT_SETTLEMENT_ACCOUNT:
      let settlementAccounts = state.settlementAccounts;
      settlementAccounts[action.payload.environment] = action.payload.data;
      return { ...state, settlementAccounts: settlementAccounts };
    case SAVE_MERCHANT_SETTLEMENT_ACCOUNT_UPDATE_REQUEST:
      return { ...state, merchantSettlementAccountUpdateRequest: action.payload };
    case UPDATE_BANK_ACCOUNTS_LOADING:
      return { ...state, bankAccountsLoading: !!action.payload };
    case UPDATE_BANK_ACCOUNT_UPDATE_REQUEST_LOADING:
      return { ...state, bankAccountUpdateRequestLoading: !!action.payload };

    case UPDATE_CONTRACT_SETTINGS_LOADING:
      return { ...state, contractSettingsLoading: !!action.payload };
    case UPDATE_MERCHANT_SETTLEMENT_LOADING:
      return { ...state, bankUpdateAccountLoading: !!action.payload };
    case UPDATE_BANK_ACCOUNTS_PROCESSED:
      return { ...state, bankAccountProcessed: !!action.payload };
    case UPDATE_PAYMENT_METHOD_PROCESSED:
      return { ...state, paymentMethodProcessed: !!action.payload };
    case GET_SETTLEMENT_NOTIFICATION_PREFERENCE:
      let settlementNotificationPreference =
        state.settlementNotificationPreference;
      settlementNotificationPreference[action.payload.environment] =
        action.payload.data;
      return {
        ...state,
        settlementNotificationPreference
      };
    case GET_DISBURSEMENT_PREFERENCES:
      let disbursements = state.disbursements;
      disbursements[action.payload.environment] = action.payload.data;
      return {
        ...state,
        disbursements
      };
    default:
      return state;
  }
};
