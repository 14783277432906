import { SAVE_CONTRACTS_PAGE_DATA, SAVE_CONTRACTS_LIST, SAVE_CONTRACT_DETAILS, UPDATE_CONTRACTS_LOADING } from './../actions/types';

const INIT_STATE = {
    pageData: [],
    list: [],

    contractData: {},

    lastPageRequest: {},
    pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
    },

    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SAVE_CONTRACTS_PAGE_DATA:
            return {
                ...state,
                pageData: action.payload.data,
                pagination: action.payload.pagination,
                loading: !!action.payload.loading
            };

        case UPDATE_CONTRACTS_LOADING:
            return { ...state, loading: !!action.payload };

        case SAVE_CONTRACT_DETAILS:
            return { ...state, contractData: action.payload };

        case SAVE_CONTRACTS_LIST:
            return { ...state, list: action.payload };

        default:
            return state
    }
};

