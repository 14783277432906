const constant = {
  IDLE_TIMEOUT_IN_MINUTES: 15,
  ACCOUNT_TRANSFER: "ACCOUNT_TRANSFER",
  DEFAULT_SAMPLE_AMOUNT: "1000",
  ROLEZ_MFB: "50515",
  REQUEST_BEEN_PROCESS: "Request already being processed",
  UNAUTHORIZED_APPROVAL:
    "You're not permitted to access this functionality. Please reach out to support@monnify.com",
  REQUERY_INTERVAL: 5000,
  RETRY_ATTEMPT_COUNT: 2,
};

export const PAYMENT_PAGE_TYPES = {
  SINGLE: "SINGLE",
  MULTIPLE: "MULTIPLE",
  SUBSCRIPTION: "SUBSCRIPTION",
};

export const PAYMENT_PAGE_AMOUNT_TYPES = {
  FIXED: "FIXED",
  FIXED_OPTIONS: "FIXED_OPTIONS",
  DYNAMIC: "DYNAMIC",
};

export const BULK_TRANSFER_MAX_FILE_SIZE = 10;
export const BULK_TRANSFER_MAX_TRANSACTIONS = 5000;
export const BULK_TRANSFER_UPLOAD_PHASE = {
  UPLOADING: "UPLOADING",
  VALIDATING: "VALIDATING",
  COMPLETE: "COMPLETE",
  FAILED: "FAILED",
};

export const MFA_PHASES = {
  INTRO: "INTRO",
  VERIFY_MOBILE: "VERIFY_MOBILE",
  VERIFY_OTP: "VERIFY_OTP",
  SETUP_PREFERENCE: "SETUP_PREFERENCE",
  COMPLETE_CONFIRMATION: "COMPLETE_CONFIRMATION",
  UPDATE_MOBILE: "UPDATE_MOBILE",
};

export const currencyCodes = {
  NGN: "₦"
}

export const URL_LINK_REGEX =
  /^(?:(?:https?|ftp):\/\/)?(?:www\.[^\s/$.?#]+\.[^\s]*)|(?:https?|ftp):\/\/[^\s/$.?#]+\.[^\s]*$/;

export default constant;
