import { SAVE_INVOICES_LIST, UPDATE_INVOICES_LOADING } from '../actions/types';

const INIT_STATE = {
  list: [],
  loading: false,
  processing: false,
  isProcessed: false,
  isDeleting: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_INVOICES_LIST:
      return {
        ...state,
        list: action.payload.data,
        pagination: action.payload.pagination
      };

    case UPDATE_INVOICES_LOADING:
      return { ...state, loading: !!action.payload };

    // case SAVE_BANKS:
    //   return { ...state, banks: action.payload };

    // case SUB_ACCOUNTS_PROCESSING:
    //   return { ...state, processing: action.payload };

    // case SUB_ACCOUNTS_PROCESSED:
    //   return { ...state, isProcessed: action.payload };

    // case SUB_ACCOUNTS_DELETING:
    //   return { ...state, isDeleting: action.payload };
    // case SUB_ACCOUNTS_DELETED:
    //   return { ...state, isProcessed: action.payload };

    default:
      return state;
  }
};
