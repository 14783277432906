import React from "react";
import { Button } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import propTypes from "prop-types";
import MonnifyIcon from "../../../icons";
import "./style.scss";
import Optional from "../Optional";
import { connect } from "react-redux";

const mapStateToProps = ({ onboarding }) => {
  return {
    isLoggedIn: onboarding.isLoggedIn,
  };
};

@connect(mapStateToProps, {})
class NotificationBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: props.isVisible,
      closed: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isVisible, isLoggedIn } = this.props;
    if (prevProps.isVisible != isVisible && !this.state.closed) {
      this.setState({
        isVisible,
      });
    }
    if (prevProps.isLoggedIn !== isLoggedIn && !isLoggedIn) {
      this.setState({ closed: false });
    }
  }

  closeBanner = () => {
    const { onClose } = this.props;
    this.setState({ isVisible: false, closed: true });
    onClose && onClose();
  };

  render() {
    const {
      variant,
      title,
      description,
      ctaText,
      handleCtaClick,
      closeable,
      icon,
      type
    } = this.props;
    const { isVisible } = this.state;
    return (
      <Optional visible={isVisible}>
        <div className={`notification-banner ${variant} ${type}-notification`}>
          <div className="flex-align-center notification-banner-wrap">
            {icon ? icon : <MonnifyIcon type="danger-mail-icon" />}
            <div className="flex-align-center notification-content">
              <div>
                <h1>{title}</h1>
                <p>{description}</p>
              </div>
              {ctaText && handleCtaClick && (
                <div>
                  <Button className="notification-btn" onClick={handleCtaClick}>
                    {ctaText}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {closeable ? (
            <div
              className="notification-close"
              data-testid="close-banner-btn"
              onClick={this.closeBanner}
            >
              <CloseCircleOutlined />
            </div>
          ) : (
            ""
          )}
        </div>
      </Optional>
    );
  }
}

export default NotificationBanner;
NotificationBanner.propTypes = {
  variant: propTypes.oneOf(["warning", "info"]),
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  ctaText: propTypes.string.isRequired,
  handleCtaClick: propTypes.func.isRequired,
  isVisible: propTypes.bool.isRequired,
  closeable: propTypes.bool,
  icon: propTypes.elementType,
  type: propTypes.oneOf(["page", "card"]),
};

NotificationBanner.defaultProps = {
  variant: "info",
  isVisible: false,
  type: "page",
};
