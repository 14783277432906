import { showError, showMessage } from ".";
import api from "./api";
import {
  SAVE_WORKFLOW_SUMMARY,
  WORKFLOW_LOADING,
  SAVE_USER_HISTORY_WORKFLOW,
  SAVE_ALL_HISTORY_WORKFLOW,
  SAVE_ALL_PENDING_WORKFLOW,
  SAVE_USER_PENDING_WORKFLOW,
  SAVE_WORKFLOW_FILTER_PARAMS,
} from "./types";
import Util from "../utils";
import { buildEnvAwareData } from "../../modules/util/EnvironmentAware/action";

const BASE_URL = "/workflow";
const PENDING_STATUS = "PENDING";
const APPROVED_AND_REJECTED_STATUS = "APPROVED,REJECTED";

export const APPROVALSTATUS = {
  APPROVE: "APPROVE",
  DECLINE: "DECLINE",
};

export const saveWorkflowSummary = (payload) => ({
  type: SAVE_WORKFLOW_SUMMARY,
  payload,
});

export const workflowLoading = (payload) => ({
  type: WORKFLOW_LOADING,
  payload,
});

export const saveUserHistoryWorkflow = (payload) => ({
  type: SAVE_USER_HISTORY_WORKFLOW,
  payload,
});

export const saveAllHistoryWorkflow = (payload) => ({
  type: SAVE_ALL_HISTORY_WORKFLOW,
  payload,
});

export const saveAllPendingWorkflow = (payload) => ({
  type: SAVE_ALL_PENDING_WORKFLOW,
  payload,
});

export const saveUserPendingWorkflow = (payload) => ({
  type: SAVE_USER_PENDING_WORKFLOW,
  payload,
});

export const saveWorkflowFilterParams = (payload) => ({
  type: SAVE_WORKFLOW_FILTER_PARAMS,
  payload,
});

export const getWorkflowSummary = () => (dispatch) => {
  let requestURL = `${BASE_URL}/summary`;

  let successHandler = (response) => {
    dispatch(saveWorkflowSummary(response.data.responseBody));
  };

  let errorHandler = (error) => {
    showError(error);
  };

  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const getAllPendingWorkflow = (pageRequest) => (dispatch, getState) => {
  const isLiveMode = getState().environmentAware.isLiveMode;
  dispatch(
    getWorkflow(
      {
        ...pageRequest,
        requestStatus: PENDING_STATUS,
        initiatorRequest: false,
      },
      saveAllPendingWorkflow,
      isLiveMode,
    ),
  );
};

export const getAllHistoryWorkflow = (pageRequest) => (dispatch, getState) => {
  const isLiveMode = getState().environmentAware.isLiveMode;
  dispatch(
    getWorkflow(
      {
        ...pageRequest,
        requestStatus: APPROVED_AND_REJECTED_STATUS,
        initiatorRequest: false,
      },
      saveAllHistoryWorkflow,
      isLiveMode,
    ),
  );
};

export const getUserHistoryWorkflow = (pageRequest) => (dispatch, getState) => {
  const isLiveMode = getState().environmentAware.isLiveMode;
  dispatch(
    getWorkflow(
      {
        ...pageRequest,
        requestStatus: APPROVED_AND_REJECTED_STATUS,
        initiatorRequest: true,
      },
      saveUserHistoryWorkflow,
      isLiveMode,
    ),
  );
};

export const getUserPendingWorkflow = (pageRequest) => (dispatch, getState) => {
  const isLiveMode = getState().environmentAware.isLiveMode;
  dispatch(
    getWorkflow(
      {
        ...pageRequest,
        requestStatus: PENDING_STATUS,
        initiatorRequest: true,
      },
      saveUserPendingWorkflow,
      isLiveMode,
    ),
  );
};

const getWorkflow =
  (pageRequest = {}, action, isLiveMode) =>
  (dispatch) => {
    let {
      requestStatus,
      page = 1,
      initiatorRequest,
      pageSize = 10,
      searchParams = {},
    } = pageRequest;
    let requestURL = `${BASE_URL}?pageNumber=${
      page - 1
    }&initiatorRequest=${initiatorRequest}&pageSize=${pageSize}&requestStatus=${requestStatus}`;

    if (searchParams.requestType) {
      requestURL =
        requestURL + `&workflowRequestType=${searchParams.requestType}`;
    }
    if (searchParams.reference) {
      requestURL = requestURL + `&workflowReference=${searchParams.reference}`;
    }
    if (searchParams.from) {
      requestURL = requestURL + `&from=${searchParams.from}`;
    }
    if (searchParams.to) {
      requestURL = requestURL + `&to=${searchParams.to}`;
    }

    let successHandler = (response) => {
      const pageData = Util.convertPageResponseToTableData(response);
      dispatch(buildEnvAwareData(action(pageData), isLiveMode));
      dispatch(workflowLoading(false));
    };

    let errorHandler = (error) => {
      showError(error);
      dispatch(workflowLoading(false));
    };

    dispatch(workflowLoading(true));
    dispatch(api.get(requestURL, {}, successHandler, errorHandler));
  };

export const processWorkflow =
  ({ pageRequest, otp }, onFinish) =>
  (dispatch) => {
    const { reference, ...request } = pageRequest;
    let requestURL = `${BASE_URL}/process-workflow-request/${reference}`;

    const config = {
      headers: {
        "monnify-otp": otp,
      },
    };

    let successHandler = () => {
      if (request.approvalStatus === APPROVALSTATUS.APPROVE) {
        showMessage("Workflow", "Workflow Request Approved!");
      } else {
        showMessage("Workflow", "Workflow Request Rejected");
      }
      onFinish && onFinish("SUCCESS");
    };

    let errorHandler = (error) => {
      onFinish && onFinish("FAIL", error);
    };

    dispatch(
      api.put(requestURL, request, successHandler, errorHandler, false, config),
    );
  };

export const getBulkTransactionBeneficiaries =
  (pageRequest, onFinish) => (dispatch) => {
    const page = pageRequest.page ? pageRequest.page - 1 : 0;
    const pageSize = pageRequest.results ? pageRequest.results : 10;
    let requestURL = `/disbursement/bulk/workflow?batchReference=${pageRequest.batchReference}&pageNo=${page}&pageSize=${pageSize}`;

    if (pageRequest.searchParams) {
      if (pageRequest.searchParams.reference) {
        requestURL +=
          "&transactionReference=" +
          encodeURIComponent(pageRequest.searchParams.reference);
      }
      if (pageRequest.searchParams.from) {
        requestURL += "&startDate=" + pageRequest.searchParams.from;
      }

      if (pageRequest.searchParams.to) {
        requestURL += "&endDate=" + pageRequest.searchParams.to;
      }
      if (pageRequest.searchParams.accountNumber) {
        requestURL +=
          "&accountNumber=" + pageRequest.searchParams.accountNumber;
      }
      if (pageRequest.searchParams.accountName) {
        requestURL += "&accountName=" + pageRequest.searchParams.accountName;
      }
      if (pageRequest.searchParams.status) {
        requestURL += "&status=" + pageRequest.searchParams.status;
      }
    }
    let successHandler = (response) => {
      const pageData = Util.convertPageResponseToTableData(response);
      onFinish && onFinish(pageData);
    };

    let errorHandler = (error) => {
      showError(error);
      onFinish && onFinish();
    };

    dispatch(api.get(requestURL, {}, successHandler, errorHandler));
  };

export const getBulkTransactionAmount =
  (batchReference, onFinish) => (dispatch) => {
    let requestURL = `/disbursement/bulk/workflow/${batchReference}`;

    let successHandler = ({ data }) => {
      onFinish && onFinish(data.responseBody);
    };

    let errorHandler = (error) => {
      showError(error);
      onFinish && onFinish();
    };

    dispatch(api.get(requestURL, {}, successHandler, errorHandler));
  };
