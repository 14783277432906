import {
  SAVE_RESERVED_ACCOUNTS_LIST,
  UPDATE_RESERVED_ACCOUNTS_LOADING,
  UPDATE_CREATE_RESERVE_ACCOUNT_PROCESSING,
  SAVE_RESERVED_ACCOUNT_DETAILS,
  UPDATE_DEALLOCATE_RESERVED_ACCOUNT_PROCESSING,
  SAVE_RESERVED_ACCOUNT_TRANSACTIONS_LIST,
  UPDATE_RESERVED_ACCOUNT_TRANSACTIONS_LOADING,
  IS_RESERVED_ACCOUNT_FILTER
} from './../actions/types';
import _ from 'lodash'

const INIT_STATE = {
  list: {
    test: [],
    live: []
  },
  lastPageRequest: {},
  pagination: {
    live: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ['10', '20', '30', '40', '50', '100']
    },
    test: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ['10', '20', '30', '40', '50', '100']
    }
  },

  reservedAccountData: {
    details: {},
    transactions: [],
    loadingTransactions: false,
    processingDeallocation: false,
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ['10', '20', '30', '40', '50', '100']
    }
  },

  loading: false,
  processingCreateReserveAccount: false,
  isReservedAccountFilter: {
    test: false,
    live: false
  }
};

export default (state = INIT_STATE, action) => {
  let reservedAccountData;

  switch (action.type) {
    case SAVE_RESERVED_ACCOUNTS_LIST:
      let list = _.cloneDeep(state.list);
      list[action.payload.environment] = action.payload.data.data;

      let pagination = state.pagination;
      pagination[action.payload.environment] = action.payload.data.pagination;

      return {
        ...state,
        list: list,
        pagination: pagination,
        loading: !!action.payload.loading
      };

    case UPDATE_RESERVED_ACCOUNTS_LOADING:
      return { ...state, loading: !!action.payload };

    case SAVE_RESERVED_ACCOUNT_DETAILS:
      reservedAccountData = _.cloneDeep(state.reservedAccountData || {});
      reservedAccountData.details = action.payload;
      return { ...state, reservedAccountData: reservedAccountData };

    case SAVE_RESERVED_ACCOUNT_TRANSACTIONS_LIST:
      reservedAccountData = _.cloneDeep(state.reservedAccountData || {});
      reservedAccountData.transactions = action.payload.data;
      reservedAccountData.pagination = action.payload.pagination;
      return { ...state, reservedAccountData: reservedAccountData };

    case UPDATE_RESERVED_ACCOUNT_TRANSACTIONS_LOADING:
      reservedAccountData = _.cloneDeep(state.reservedAccountData || {});
      reservedAccountData.loadingTransactions = !!action.payload;
      return { ...state, reservedAccountData: reservedAccountData };

    case UPDATE_DEALLOCATE_RESERVED_ACCOUNT_PROCESSING:
      reservedAccountData = _.cloneDeep(state.reservedAccountData || {});
      reservedAccountData.processingDeallocation = !!action.payload;
      return { ...state, reservedAccountData: reservedAccountData };

    case UPDATE_CREATE_RESERVE_ACCOUNT_PROCESSING:
      return { ...state, processingCreateReserveAccount: !!action.payload };

    case IS_RESERVED_ACCOUNT_FILTER: {
      let isReservedAccountFilter = _.cloneDeep(state.isReservedAccountFilter);
      isReservedAccountFilter[action.payload.environment] = action.payload;
      return {
        ...state,
        isReservedAccountFilter: isReservedAccountFilter
      };
    }

    default:
      return state;
  }
};
