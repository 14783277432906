import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import history from "./common/config/history";
import LocaleProvider from "antd/es/locale-provider";
import enGB from "antd/lib/locale-provider/en_GB";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import backendAPIMiddleware from "./common/middlewares/backendAPIMiddleware";
import { applyMiddleware, compose, createStore } from "redux";
import reducer from "./common/reducers";
import "./index.scss";


const router = routerMiddleware(history);
const middlewares = [router, thunk, backendAPIMiddleware];
const isLogger = true;
if (isLogger && process.env.NODE_ENV === "development") {
  const { createLogger } = require("redux-logger");
  const logger = createLogger({
    collapsed: true,
    predicate: (getState, action) => action.type.search("@@") === -1,
  });
  middlewares.push(logger);
}
const store = createStore(reducer, compose(applyMiddleware(...middlewares)));

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LocaleProvider locale={enGB}>
        <App />
      </LocaleProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
