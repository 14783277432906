import {
  SAVE_SUB_ACCOUNTS_LIST,
  UPDATE_SUB_ACCOUNTS_LOADING,
  SAVE_BANKS,
  SUB_ACCOUNTS_PROCESSING,
  SUB_ACCOUNTS_PROCESSED,
  SUB_ACCOUNTS_DELETING,
  SUB_ACCOUNTS_DELETED,
} from "../actions/types";
import _ from "lodash";

const INIT_STATE = {
  list: {
    test: [],
    live: [],
  },
  banks: [],
  bankMap: {},
  subAccountsData: {
    details: {},
  },
  loading: false,
  processing: false,
  isProcessed: false,
  isDeleting: false,
  pagination: {
    test: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    live: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_SUB_ACCOUNTS_LIST:
      let list = _.cloneDeep(state.list);
      list[action.payload.environment] = action.payload.data.data;

      let pagination = _.cloneDeep(state.pagination);
      pagination[action.payload.environment] = action.payload.data.pagination;

      return {
        ...state,
        list: list,
        pagination,
      };

    case UPDATE_SUB_ACCOUNTS_LOADING:
      return { ...state, loading: !!action.payload };

    case SAVE_BANKS:
      const banks = action.payload;
      const bankMap = {};
      banks.forEach((bank) => {
        bankMap[bank.code] = bank;
      });
      return { ...state, banks: action.payload, bankMap };

    case SUB_ACCOUNTS_PROCESSING:
      return { ...state, processing: action.payload };

    case SUB_ACCOUNTS_PROCESSED:
      return { ...state, isProcessed: action.payload };

    case SUB_ACCOUNTS_DELETING:
      return { ...state, isDeleting: action.payload };
    case SUB_ACCOUNTS_DELETED:
      return { ...state, isProcessed: action.payload };

    default:
      return state;
  }
};
